import './mipymeparticipante.css';
import {dataformat2} from "../BaseDatos.js";
import React, { useEffect, useState } from "react";
import { BotonImprimir, Fechas }  from "../imprimir.tsx"
import BarrasAgrupadas from "./graficos/BarrasAgrupadas"
import BarrasAgrupadas2 from "./graficos/BarrasAgrupadas2"
import BarrasAgrupadas3 from "./graficos/BarrasAgrupadas3"
import BarrasAgrupadas4 from "./graficos/BarrasAgrupadas4"
import BarrasAgrupadas5 from "./graficos/BarrasAgrupadas5"
import BarrasAgrupadas6 from "./graficos/BarrasAgrupadas6"
import DobleTexto from "./graficos/DobleTexto"
import BarrasAgrupadas8 from "./graficos/BarrasAgrupadas8"

import grafico1 from "./graficoNara1.png"
import relleno from "./rellenoreporte.png"





function Mipymeparticipante({user,setUser}) {

  const obtenerFechaActual = () => 
  {
    var date = new Date();
    var fechaActual = date;
    var fechaA = fechaActual.toLocaleString("es-ES").substring(0,16)
    return fechaA;
  };

  /*Cambio de funciones*/
  const [segregacion, setSegregacion] = useState(0);
  const [fechaInicio, setFechaInicio] = useState("2022-01-01 00:00:00");
  const [fechaFinal, setFechaFinal] = useState("2200-01-01 00:00:00");
  const [tipo, setTipo] = useState("Participantes")
  const [arrayP, setArrayP] = useState(dataformat2[0])
  const [fechaAct, setFechaAct] = useState("00/00/0000")

  useEffect(() => {
    console.log("FUNCIONO PARTICIPANTES")
    if(typeof arrayP !== "undefined") {
    var fechaACtual = obtenerFechaActual()
    setFechaAct(fechaACtual)
    console.log("ARRAYP ES:" + arrayP.FemeninoPorcentaje);}
},[arrayP])

    return (
        <div>
        
            <div className="cuerpomreporteinicio">
                <div>
                  <div className='Anaranjado titulo'>  Perfil de Empresas Participantes del Neuquén </div>
                    <div className="BotonComenzar">
                    <BotonImprimir
                        user = {user}
                        setUser = {setUser}
                        fechaInicio = {fechaInicio}
                        fechaFinal = {fechaFinal}
                        tipo = {tipo}
                        setArrayR = {setArrayP}
                        segregacion={segregacion}
                        setSegregacion={setSegregacion}
                        />
                    </div>
                </div>

            </div>
            <div className="insertefechas">
            <Fechas
                setFechaInicio = {setFechaInicio}
                setFechaFinal = {setFechaFinal}
            />
                </div>
            
            <div className="Relleno">aaaa</div>

            <div  className="graficosparticipantesGRID">
                <div className="elemento1">
                <img className='imagenGRAFICO1'src={grafico1} alt="imagen numero"></img>
                <img className='imagenGRAFICO2'src={relleno} alt="imagen numero"></img>
                Perfil de Empresas Participantes del Neuquén
                <h4>Fecha de corte: {fechaAct}</h4>
                </div>
                    <div className="child">
                    <BarrasAgrupadas segregacion={segregacion}  arrayP={[
                        arrayP.FemeninoPorcentaje,
                        arrayP.MasculinoPorcentaje,

                        arrayP.PromPertenenciaMic,
                        arrayP.PromPertenenciaPeq,
                        arrayP.PromPertenenciaMed1,
                        arrayP.PromPertenenciaMed2,

                        arrayP.TOTALMicPorcF,
                        arrayP.TOTALPeqPorcF,
                        arrayP.TOTALMed1PorcF,
                        arrayP.TOTALMed2PorcF,

                        arrayP.TOTALMicPorcM,
                        arrayP.TOTALPeqPorcM,
                        arrayP.TOTALMed1PorcM,
                        arrayP.TOTALMed2PorcM
                        ]}/>
                    </div>
                    <div className="child">
                    <BarrasAgrupadas2 segregacion={segregacion} arrayP={[
                        arrayP.Apromedio,
                        arrayP.ApromedioMic,
                        arrayP.ApromedioPeq,
                        arrayP.ApromedioMed1,
                        arrayP.ApromedioMed2
                        ]}/>
                    </div>
                    <div className="child">

                    <BarrasAgrupadas3 segregacion={segregacion} arrayP={arrayP}/>
                    </div>
                    <div className="child">
                    <BarrasAgrupadas4 segregacion={segregacion} arrayP={arrayP}/>
                    </div>
                    <div className="child">
                    <BarrasAgrupadas5 segregacion={segregacion} arrayP={arrayP}/>
                    </div>
                    <div className="child">
                    <BarrasAgrupadas6 segregacion={segregacion} arrayP={arrayP}/>
                    </div>
                    <div className="child">
                    {/*
                        arrayP.GraCabeceraEconomico,
                        arrayP.GraCabeceraCargo
                    */}
                    <DobleTexto arrayP={arrayP}/>
                    </div>
                    <div className="childpart elementoParti">
                    <BarrasAgrupadas8 segregacion={segregacion} arrayP={arrayP}/>
                    </div>

            </div>

        </div>
      );
  }

export default Mipymeparticipante
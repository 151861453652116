import React from 'react';
import grafico from './shopbag_SINFONDO.png';



function BarrasAgrupadas({ambientalP1}) {
 return( 
  <div className= "AdopcionsquarePLAN">
  <img  alt="imagen" className='imagengraficosADOPESTRA abajo'src={grafico}></img>
  <div className='titulograficosADOP'>
    <div className='numpregraficosADOP'>P.01</div>
   <div className='colornegro'>Total Criterio Marketing</div>
  <div>{ambientalP1}
  </div>
  </div>
  </div>
);
}

export default BarrasAgrupadas
import React from 'react';
import {socialPromedios} from "../../BaseDatos.js";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import grafico from './grafico7.jpg';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  
  animation: {
         duration: 500,
  },
  maintainAspectRatio: false,
  indexAxis: 'y',
  elements: {
    bar: {
      borderWidth: 0,
    },
  },
  responsive: true,
  plugins: {
    datalabels: {
      display: true,
      color: 'white',
   },
    title: {
      display: false,
      text: 'Chart.js Horizontal Bar Chart',
    },
    legend: {
      position: 'left',
      labels: {
        boxWidth: 5,
        boxHeight: 5,
        padding: 1,
          font: {
            size: 8
        }
      }
  }
  },
};

const labels = [''];
  
  function BarrasAgrupadas7({segregacion, arrayS= socialPromedios[0]}) {

var rojo  = "rgb(245, 168, 130)"
var rojo1 = "rgb(208, 113, 88)"
var rojo2 = "rgb(186, 62, 30)"
var rojo3 = "rgb(144, 38, 23)"
var rojo4 = "rgb(113, 11, 42)"
    
 const data = {
  labels,
  datasets: [
    {
      label: 'Micro',
      data: [arrayS.PMic],
      backgroundColor: rojo,
    },
    {
      label: 'Pequeña',
      data:  [arrayS.PPeq],
      backgroundColor: rojo1,
    },
    {
      label: 'Mediana-1',
      data: [arrayS.PMed1],
      backgroundColor: rojo2,
    },
    {
      label: 'Mediana-2',
      data: [arrayS.PMed2],
      backgroundColor: rojo3,
    },
    
  
  ],
};

 const data1 = {
  labels,
  datasets: [
    {
      label: 'Nuevas',
      data:  [arrayS.PNueva],
      backgroundColor: rojo,
    },
    {
      label: 'En Consolidación',
      data: [arrayS.PConsolidacion],
      backgroundColor: rojo1,
    },
    {
      label: 'Consolidadas',
      data: [arrayS.PConsolidada],
      backgroundColor: rojo2,
    },
  ],
};

 const data2 = {
  labels,
  datasets: [
    {
      label: 'Agropecuario',
      data: [arrayS.PAgropecuario],
      backgroundColor: rojo,
    },
    {
      label: 'Industrial',
      data: [arrayS.PIndustrial],
      backgroundColor: rojo1,
    },
    {
      label: 'Servicio',
      data: [arrayS.PServicio],
      backgroundColor: rojo2,
    },
    {
      label: 'Comercio',
      data: [arrayS.PComercio],
      backgroundColor: rojo3,
    },
    {
      label: 'Construcción',
      data: [arrayS.PConstruccion],
      backgroundColor: rojo4,
    },
    
  ],
};

 const data3 = {
  labels,
  datasets: [
    {
      label: 'Hombre',
      data: [arrayS.PHombre],
      backgroundColor: rojo,
    },
    {
      label: 'Mujer',
      data: [arrayS.PMujer],
      backgroundColor: rojo1,
    },
  ],
};

 const data4 = {
  labels,
  datasets: [
    {
      label: 'PSi',
      data: [arrayS.PGraficoSiPertenece],
      backgroundColor: rojo,
    },
    {
      label: 'PNo',
      data: [arrayS.PGraficoNoPertenece],
      backgroundColor: rojo1,
    },
  ],
};


 return( 
    <div>
    {segregacion === 0 &&
    <div>
  <img alt="imagen" className='imagengraficos abajo'src={grafico}></img>
  <div className='titulograficos'>
  <div className='numpregraficos'>P.27</div>
  <div className='colornegro'>  Porcentaje de Trabajadores Mujeres</div>
  <div>{arrayS.PTotal}%
  <div>Total</div>
  </div>
  </div>
  <div className='subtitulograficos'>
     <ul className='Soc'>
      <li>Mic. = {arrayS.PMic} </li>
      <li>Peq. = {arrayS.PPeq}</li>
      <li>Med1. = {arrayS.PMed1}</li>
      <li>Med2. = {arrayS.PMed2}</li>
    </ul>
  </div>
  <div className="socialfgrafic">
    
 	<Bar data={data} plugins={[ChartDataLabels]} options={options}/>
	</div>
  </div>
  }
  {segregacion === 1 &&
    <div>
  <img alt="imagen" className='imagengraficos abajo'src={grafico}></img>
  <div className='titulograficos'>
  <div className='numpregraficos'>P.27</div>
  <div className='colornegro'>  Porcentaje de Trabajadores Mujeres</div>
  <div>{arrayS.PTotal}%
  <div>Total</div>
  </div>
  </div>
  <div className='subtitulograficos'>
     <ul className='Soc'>
      <li>Nueva = {arrayS.PNueva}</li>
      <li>En Consolidación= {arrayS.PConsolidacion}</li>
      <li>Consolidada = {arrayS.PConsolidada}</li>
    </ul>
  </div>
  <div className="socialfgrafic">
    
 	<Bar data={data1} plugins={[ChartDataLabels]} options={options}/>
	</div>
  </div>
  }
      {segregacion === 2 &&
    <div>
  <img alt="imagen" className='imagengraficos abajo'src={grafico}></img>
  <div className='titulograficos'>
  <div className='numpregraficos'>P.27</div>
  <div className='colornegro'>  Porcentaje de Trabajadores Mujeres</div>
  <div>{arrayS.PTotal}%
  <div>Total</div>
  </div>
  </div>
  <div className='subtitulograficos'>
     <ul className='Soc'>
    <li>Agr. = {arrayS.PAgropecuario}</li>
      <li>Ind. = {arrayS.PIndustrial}</li>
      <li>Ser. = {arrayS.PServicio}</li>
      <li>Com. = {arrayS.PComercio}</li>
      <li>Con. = {arrayS.PConstruccion}</li>
    </ul>
  </div>
  <div className="socialfgrafic">
    
 	<Bar data={data2} plugins={[ChartDataLabels]} options={options}/>
	</div>
  </div>
  }
  {segregacion === 3 &&
    <div>
  <img alt="imagen" className='imagengraficos abajo'src={grafico}></img>
  <div className='titulograficos'>
  <div className='numpregraficos'>P.27</div>
  <div className='colornegro'>  Porcentaje de Trabajadores Mujeres</div>
  <div>{arrayS.PTotal}%
  <div>Total</div>
  </div>
  </div>
  <div className='subtitulograficos'>
     <ul className='Soc'>
     <li>H. = {arrayS.PHombre}</li>
     <li>M. = {arrayS.PMujer} </li>
    </ul>
  </div>
  <div className="socialfgrafic">
    
 	<Bar data={data3} plugins={[ChartDataLabels]} options={options}/>
	</div>
  </div>
  }
  {segregacion === 4 &&
    <div>
  <img alt="imagen" className='imagengraficos abajo'src={grafico}></img>
  <div className='titulograficos'>
  <div className='numpregraficos'>P.27</div>
  <div className='colornegro'>  Porcentaje de Trabajadores Mujeres</div>
  <div>{arrayS.PTotal}%
  <div>Total</div>
  </div>
  </div>
  <div className='subtitulograficos'>
     <ul className='Soc'>
     <li>Sí. = {arrayS.PSi}  </li>
      <li>No. = {arrayS.PNo} </li>
    </ul>
  </div>
  <div className="socialfgrafic">
    
 	<Bar data={data4} plugins={[ChartDataLabels]} options={options}/>
	</div>
  </div>
  }
  </div>
  );
  }

  export default BarrasAgrupadas7
import './mipymeambiental.css';


import {Button} from '@mui/material';
import { Link } from "react-router-dom";
import { BotonImprimir,Fechas, Seleccion }  from "../imprimir.tsx"
import React, { useEffect, useState } from "react";
import axios from 'axios'
import PantallaCarga from "./../../pantallacarga/pantallacarga.js"

import BarrasAgrupadas from "./graficos/BarrasAgrupadas"
import BarrasAgrupadas2 from "./graficos/BarrasAgrupadas2"
import BarrasAgrupadas3 from "./graficos/BarrasAgrupadas3"
import BarrasAgrupadas4 from "./graficos/BarrasAgrupadas4"
import BarrasAgrupadas5 from "./graficos/BarrasAgrupadas5"
import BarrasAgrupadas6 from "./graficos/BarrasAgrupadas6"
import BarrasAgrupadas7 from "./graficos/BarrasAgrupadas7"
import BarrasAgrupadas8 from "./graficos/BarrasAgrupadas8"
import BarrasAgrupadas9 from "./graficos/BarrasAgrupadas9"
import BarrasAgrupadas10 from "./graficos/BarrasAgrupadas10"
import BarrasAgrupadas11 from "./graficos/BarrasAgrupadas11"
import BarrasAgrupadas12 from "./graficos/BarrasAgrupadas12"
import BarrasAgrupadas13 from "./graficos/BarrasAgrupadas13"
import BarrasAgrupadas14 from "./graficos/BarrasAgrupadas14"
import BarrasAgrupadas15 from "./graficos/BarrasAgrupadas15"
import BarrasAgrupadas16 from "./graficos/BarrasAgrupadas16"
import BarrasAgrupadas17 from "./graficos/BarrasAgrupadas17"
import BarrasAgrupadas18 from "./graficos/BarrasAgrupadas18"
import BarrasAgrupadas19 from "./graficos/BarrasAgrupadas19"
import BarrasAgrupadas20 from "./graficos/BarrasAgrupadas20"

import TextoUnico1 from "./graficos/TextoUnico1"

import grafico1 from './graficoAmbiental1.png';
import grafico2 from './graficoAmbiental2.png';


function Mipymeambiental({user, setUser}) {

    const obtenerFechaActual = () => 
    {
      var date = new Date();
      var fechaActual = date;
      var fechaA = fechaActual.toLocaleString("es-ES").substring(0,16)
      return fechaA;
    };

      /*Cambio de funciones*/
      const [segregacion, setSegregacion] = useState(0);
      const [fechaInicio, setFechaInicio] = useState("2022-01-01 00:00:00");
      const [fechaFinal, setFechaFinal] = useState("2200-01-01 00:00:00");
      const [tipo, setTipo] = useState("Ambiental")
      const [arrayA, setArrayA] = useState([])
      const [fechaAct, setFechaAct] = useState("00/00/0000")

      useEffect(() => {
            if(typeof arrayA !== "undefined") {
            var fechaACtual = obtenerFechaActual()
            setFechaAct(fechaACtual)
            console.log("HORA DE EXPORTAR:"+arrayA + "EL TIPO ES: "+ typeof arrayA)
            localStorage.setItem('DatoAmbiental', JSON.stringify(arrayA));}
        },[arrayA])

    return (
        <div>
            <div className="cuerpomreporteinicio">
                <div>
                <div className='Verdoso titulo'>Reporte  Adopción de Criterio Ambiental</div>
                    
                        <div className="BotonComenzar">
                        <BotonImprimir
                            user = {user}
                            setUser = {setUser}
                            fechaInicio = {fechaInicio}
                            fechaFinal = {fechaFinal}
                            tipo = {tipo}
                            setArrayR = {setArrayA}
                            segregacion={segregacion}
                            setSegregacion={setSegregacion}
                            />
                        </div>
                </div>

            </div>
            <div className="insertefechas">
                <Fechas
                setFechaInicio = {setFechaInicio}
                setFechaFinal = {setFechaFinal}
                />
                <Seleccion
                segregacion={segregacion}
                setSegregacion={setSegregacion}
                ></Seleccion>
            </div>
            

            <div  className="graficosambientalGRID">
                <div className="elemento1">
                <img className='imagenGRAFICO1'src={grafico1} alt="imagen numero"></img>
                <img className='imagenGRAFICO2'src={grafico2} alt="imagen logo"></img>
                Reporte de Adopción de Criterio Ambiental
                <h4>Fecha de corte: {fechaAct}</h4>
                </div>
                    <div className="child">
                    <BarrasAgrupadas segregacion={segregacion} arrayA={arrayA[0]}/>
                    </div>
                    <div className="child">
                    <BarrasAgrupadas2 segregacion={segregacion} arrayA={arrayA[1]}/>
                    </div>
                    <div className="child">
                    <BarrasAgrupadas3 segregacion={segregacion} arrayA={arrayA[2]}/>
                    </div>
                    <div className="child">
                    <BarrasAgrupadas4 segregacion={segregacion} arrayA={arrayA[3]}/>
                    </div>
                    <div className="child">
                    <BarrasAgrupadas5 segregacion={segregacion} arrayA={arrayA[4]}/>
                    </div>
                    <div className="child">
                    <BarrasAgrupadas6 segregacion={segregacion} arrayA={arrayA[5]}/>
                    </div>
                    <div className="child">
                    <BarrasAgrupadas7 segregacion={segregacion} arrayA={arrayA[6]}/>
                    </div>
                    <div className="child">
                    <BarrasAgrupadas8 segregacion={segregacion} arrayA={arrayA[7]}/>
                    </div>
                    <div className="child">
                    <BarrasAgrupadas9 segregacion={segregacion} arrayA={arrayA[8]}/>
                    </div>
            </div>

            <div  className="graficosambientalGRID">
                <div className="elemento1">
                <img className='imagenGRAFICO1'src={grafico1} alt="imagen numero"></img>
                <img className='imagenGRAFICO2'src={grafico2} alt="imagen logo"></img>
                Reporte de Adopción de Criterio Ambiental
                <h4>Fecha de corte: {fechaAct}</h4>
                </div>
                    <div className="child">
                    <BarrasAgrupadas10 segregacion={segregacion} arrayA={arrayA[9]}/>
                    </div>
                    <div className="child">
                    <BarrasAgrupadas11 segregacion={segregacion} arrayA={arrayA[10]}/>
                    </div>
                    <div className="child">
                    <BarrasAgrupadas12 segregacion={segregacion} arrayA={arrayA[11]}/>
                    </div>
                    <div className="child">
                    <BarrasAgrupadas13 segregacion={segregacion} arrayA={arrayA[12]}/>
                    </div>
                    <div className="child">
                    <BarrasAgrupadas14 segregacion={segregacion} arrayA={arrayA[13]}/>
                    </div>
                    <div className="child">
                    <BarrasAgrupadas15 segregacion={segregacion} arrayA={arrayA[14]}/>
                    </div>
                    <div className="child">
                    <BarrasAgrupadas16 segregacion={segregacion} arrayA={arrayA[15]}/>
                    </div>
                    <div className="child">
                    <BarrasAgrupadas17 segregacion={segregacion} arrayA={arrayA[16]}/>
                    </div>
                    <div className="child">
                    <BarrasAgrupadas18 segregacion={segregacion} arrayA={arrayA[17]}/>
                    </div>
             </div>

            <div  className="graficosambientalGRID">
                <div className="elemento1">
                <img className='imagenGRAFICO1'src={grafico1} alt="imagen numero"></img>
                <img className='imagenGRAFICO2'src={grafico2} alt="imagen logo"></img>
                Reporte de Adopción de Criterio Ambiental
                <h4>Fecha de corte: {fechaAct}</h4>
                </div>
                    <div className="child">
                    <TextoUnico1 segregacion={segregacion} arrayA={arrayA[19]} arrayB={arrayA[18]}/>
                    </div>
                    
                    <div className="childAmb elementoAmbiental1">
                    <BarrasAgrupadas20 segregacion={segregacion} arrayA={arrayA[18]} arrayB={arrayA[19]}/>
                    </div>

                </div>
            </div>
      );
  }


export  {Mipymeambiental}
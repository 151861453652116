import './reporteplanestrategia.css';
import axios from 'axios'
import { useEffect, useState} from 'react'
import PantallaCarga from "./../../pantallacarga/pantallacarga"
import { BotonImprimir } from '../imprimir.tsx';



import Tablareporte from "./graficos/tablareporte"
import BarrasAgrupadas from "./graficos/BarrasAgrupadas"
import BarrasAgrupadas2 from "./graficos/BarrasAgrupadas2"
import BarrasAgrupadas3 from "./graficos/BarrasAgrupadas3"

import TablareporteB from "./graficos/tablareporteB"
import BarrasAgrupadasB from "./graficos/BarrasAgrupadasB"
import BarrasAgrupadas2B from "./graficos/BarrasAgrupadas2B"
import BarrasAgrupadas3B from "./graficos/BarrasAgrupadas3B"

import TablareporteC from "./graficos/tablareporteC"
import BarrasAgrupadasC from "./graficos/BarrasAgrupadasC"
import BarrasAgrupadas2C from "./graficos/BarrasAgrupadas2C"
import BarrasAgrupadas3C from "./graficos/BarrasAgrupadas3C"

import grafico1 from "./planaccion1.png"
import grafico2 from "./planaccion2.png"
import grafico3 from "./planaccion3.png"
import relleno1 from "./graficoAmbiental2.png"
import relleno2 from "./graficosocial2.png"
import relleno3 from "./graficoGobernanza2.png"



/* imagenes para el radar que no pude meter
const SUB1 = 'cuadroanimado/SUB1.png';
const SUB2 = 'cuadroanimado/SUB2.png';
const SUB3 = 'cuadroanimado/SUB3.jpg';
*/


function ReporteplanAccion({informe, user, setUser}) {
    const [estrategias, setEstrategias] = useState();
    const [estrategiasNO, setEstrategiasNO] = useState();
    const [estrategiasDIF, setEstrategiasDIF] = useState();
    const [cargado, setCargado] = useState(true)
    const base = "https://bid.adeneu.com.ar/autodiagnostico/index.php";

    const [ambientalP1,setAmbientalP1] = useState();
    const [ambientalP2,setAmbientalP2] = useState();
    const [ambientalP3,setAmbientalP3] = useState();

    const [socialP1,setSocialP1] = useState();
    const [socialP2,setSocialP2] = useState();
    const [socialP3,setSocialP3] = useState();

    const [gobernanzaP1, setGobernanzaP1] = useState();
    const [gobernanzaP2, setGobernanzaP2] = useState();
    const [gobernanzaP3, setGobernanzaP3] = useState();

    const [fechaM] = useState(informe.fecha_modificacion);

    const [fechaActual, setFechaActual] = useState();
   

  useEffect(() => {

    var date = new Date();
      var fechaActual = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate() + " " + date.toLocaleTimeString();
      var fechaActualAux = fechaActual+""
      var fechaActualAux = fechaActualAux.substring(0, fechaActualAux.length - 3);
      setFechaActual(fechaActualAux)

    const headers = {
      "Accept":"application/json",
    }
    //axios.get("https://bid.adeneu.com.ar/autodiagnostico/index.php?m=listarResultado&NumInforme=339",{headers}).then((response)=>{
    axios.get(base+"?m=listarResultado&NumInforme="+informe.NumInforme,{headers}).then((response)=>{

      const Est = response.data.data.grupoA
      const Contador = response.data.data.grupoB
      const EstNO = response.data.data.grupoC

      var AmbientalP2 = Contador["Ambiental Mitigación al cambio climático"]
      var AmbientalP3 = Contador["Ambiental Adaptación al cambio climático"]
      var AmbientalP1 = AmbientalP2 + AmbientalP3

      setAmbientalP1(AmbientalP1)
      setAmbientalP2(AmbientalP2)
      setAmbientalP3(AmbientalP3)

      var SocialP2 = Contador["Social Enfoque de género"]
      var SocialP3 = Contador["Social Poblaciones diversas"]
      var SocialP1 = SocialP2 + SocialP3

      setSocialP1(SocialP1)
      setSocialP2(SocialP2)
      setSocialP3(SocialP3)


      var GobernanzaP2 = Contador["Gobernanza Transformación digital"]
      var GobernanzaP3 = Contador["Gobernanza Cultura organizacional"]
      var GobernanzaP1 = GobernanzaP2 + GobernanzaP3

      setGobernanzaP1(GobernanzaP1)
      setGobernanzaP2(GobernanzaP2)
      setGobernanzaP3(GobernanzaP3)



      setEstrategias(Est)
     
      setCargado(false)

      const rptas = Est.map(x => (x.NumRespuesta)); //GRUPO"A" - MARCADO:1
      const rptasNO = EstNO.map(x => (x.NumRespuesta)); //GRUPO"C" - MARCADO:0
      let difference = rptasNO.filter(x => !rptas.includes(x)); // CONJUNTOS (A - B)
  
      setEstrategiasNO(EstNO)
      setEstrategiasDIF(difference)



    }).catch((error)=>{
      console.error(error)
    })
  }, []);


    return (
        <div> 
            {
            cargado
            ?
            <PantallaCarga/>
            :
            <div className="cuerpomreporteinicio">
                <div>
                    <div className='Anaranjado tituloREPORTE'>Reporte de Planes de acción </div>

                    <p>Fecha creación plan (AA/MM/DD hh:mm): {informe.fecha_creacion} <br></br>
                    Fecha impresión plan (AA/MM/DD hh:mm): {fechaActual+""} <br></br>
                    Número de Informe: {informe.NumInforme} <br></br>
                    Razón social de la Empresa: {informe.RazonSocial}
                    </p>
                    
                    <div className="BotonComenzar">
                    {/*
                    <Button onClick={imprimir}  variant="contained" color="primary" sx={{ fontWeight: 'bold' }} >
                        Descargar PDF
                    </Button>
                    */}

                    <BotonImprimir
                    user = {user}
                    setUser = {setUser} 
                    />

                    </div>
                </div>
            </div>
            }
            

            {
            cargado
            ?
            <PantallaCarga/>
            :
            <div>

                <div  className="graficosadopcionASGCEL graVERDE">
                <div className="elemento1">
                <img className='imagenGRAFICO1'src={grafico1} alt="imagen numero"></img>
                <img className='imagenGRAFICO2'src={grafico1} alt="imagen numero"></img>
                Reporte de Planes de Acción – Marketing
                <h4>Razón Social: {informe.RazonSocial} / CUIT: {informe.CUIT} </h4>
                </div>
                    <div className="elementofusion2 chiExtra">
                    <BarrasAgrupadas
                        ambientalP1 = {ambientalP1}
                        />
                    </div>
                    <div className="chiExtra">
                    <BarrasAgrupadas2
                        ambientalP2 = {ambientalP2}
                        />
                    </div>
                    <div className="chiExtra">
                    <BarrasAgrupadas3
                        ambientalP3 = {ambientalP3}
                        />
                    </div>
                    <div className="FusionEstrategias chiExtra">
                    <Tablareporte
                        estrategias={estrategias}
                        estrategiasNO={estrategiasNO}
                        estrategiasDIF={estrategiasDIF}
                        fechaM = {fechaM}
                        fechaC = {informe.fecha_creacion}
                        
                        ambientalP1 = {ambientalP1}
                        ambientalP2 = {ambientalP2}
                        ambientalP3 = {ambientalP3}
                        setAmbientalP1 = {setAmbientalP1}
                        setAmbientalP2 = {setAmbientalP2}
                        setAmbientalP3 = {setAmbientalP3}

                        />
                    </div>
                      </div>

                <div  className="graficosadopcionASGCEL graROSA">
                <div className="elemento1">
                <img className='imagenGRAFICO1'src={grafico2} alt="imagen numero"></img>
                    <img className='imagenGRAFICO2'src={grafico2} alt="imagen numero"></img>
                Reporte de Planes de Acción – Marketing
                <h4>Razón Social: {informe.RazonSocial} / CUIT: {informe.CUIT} </h4>
                </div>
                    <div className="elementofusion2 chiExtra">
                    <BarrasAgrupadasB
                        socialP1 = {socialP1}
                        />
                    </div>
                    <div className="chiExtra">
                    <BarrasAgrupadas2B
                        ambientalP2 = {ambientalP2}
                        />
                    </div>
                    <div className="chiExtra">
                    <BarrasAgrupadas3B
                        ambientalP3 = {ambientalP3}
                        />
                    </div>
                    <div className="FusionEstrategias chiExtra">
                    <TablareporteB
                        estrategias={estrategias}
                        estrategiasNO={estrategiasNO}
                        estrategiasDIF={estrategiasDIF}
                        fechaM = {fechaM}
                        fechaC = {informe.fecha_creacion}

                        socialP1 = {socialP1}
                        socialP2 = {socialP2}
                        socialP3 = {socialP3}
                        setSocialP1 = {setSocialP1}
                        setSocialP2 = {setSocialP2}
                        setSocialP3 = {setSocialP3}
                        />
                    </div>
                      </div>

                      <div  className="graficosadopcionASGCEL graAZUL">
                <div className="elemento1">
                <img className='imagenGRAFICO1'src={grafico3} alt="imagen numero"></img>
                    <img className='imagenGRAFICO2'src={grafico3} alt="imagen numero"></img>
                Reporte de Planes de Acción – Marketing
                <h4>Razón Social: {informe.RazonSocial} / CUIT: {informe.CUIT} </h4>
                </div>
                    <div className="elementofusion2 chiExtra">
                    <BarrasAgrupadasC
                        ambientalP1 = {ambientalP1}
                        />
                    </div>
                    <div className="chiExtra">
                    <BarrasAgrupadas2C
                        ambientalP2 = {ambientalP2}
                        />
                    </div>
                    <div className="chiExtra">
                    <BarrasAgrupadas3C
                        ambientalP3 = {ambientalP3}
                        />
                    </div>
                    <div className="FusionEstrategias chiExtra">
                    <TablareporteC
                        estrategias={estrategias}
                        estrategiasNO={estrategiasNO}
                        estrategiasDIF={estrategiasDIF}
                        fechaM = {fechaM}
                        fechaC = {informe.fecha_creacion}

                        gobernanzaP1 = {gobernanzaP1}
                        gobernanzaP2 = {gobernanzaP2}
                        gobernanzaP3 = {gobernanzaP3}
                        setGobernanzaP1 = {setGobernanzaP1}
                        setGobernanzaP2 = {setGobernanzaP2}
                        setGobernanzaP3 = {setGobernanzaP3}
                        />
                    </div>
                      </div>


                <div  className="graficosadopcionGRID graVERDE modoreporte">
                    <div className="elemento1">
                        <img className='imagenGRAFICO1'src={grafico1} alt="imagen numero"></img>
                        <img className='imagenGRAFICO2'src={grafico1} alt="imagen numero"></img>
                        Reporte de Planes de Acción – Marketing
                        <h4>Razón Social: {informe.RazonSocial} / CUIT: {informe.CUIT} </h4>
                    </div>
                        <div className="chiPLAN">
                        <BarrasAgrupadas
                        ambientalP1 = {ambientalP1}
                        />
                        </div>
                        <div className="chiPLAN">
                        <BarrasAgrupadas2
                        ambientalP2 = {ambientalP2}
                        />
                        </div>
                        <div className="chiPLAN">
                        <BarrasAgrupadas3
                        ambientalP3 = {ambientalP3}
                        />
                        </div>
                        <div className="elementofusion4">
                        <Tablareporte
                        estrategias={estrategias}
                        estrategiasNO={estrategiasNO}
                        estrategiasDIF={estrategiasDIF}
                        fechaM = {fechaM}
                        fechaC = {informe.fecha_creacion}
                        
                        ambientalP1 = {ambientalP1}
                        ambientalP2 = {ambientalP2}
                        ambientalP3 = {ambientalP3}
                        setAmbientalP1 = {setAmbientalP1}
                        setAmbientalP2 = {setAmbientalP2}
                        setAmbientalP3 = {setAmbientalP3}

                        />
                        </div>
                </div>

                <div  className="graficosadopcionGRID graROSA modoreporte">
                    <div className="elemento1">
                    <img className='imagenGRAFICO1'src={grafico2} alt="imagen numero"></img>
                    <img className='imagenGRAFICO2'src={grafico2} alt="imagen numero"></img>
                    Reporte de Planes de Acción – Integración
                    <h4>Razón Social: {informe.RazonSocial} / CUIT: {informe.CUIT}</h4>
                    </div>
                    <div className="chiPLAN">
                        <BarrasAgrupadasB
                        socialP1 = {socialP1}
                        />
                        </div>
                        <div className="chiPLAN">
                        <BarrasAgrupadas2B
                        socialP2 = {socialP2}
                        />
                        </div>
                        <div className="chiPLAN">
                        <BarrasAgrupadas3B
                        socialP3 = {socialP3}
                        />
                        </div>
                        <div className="elementofusion4">
                        <TablareporteB
                        estrategias={estrategias}
                        estrategiasNO={estrategiasNO}
                        estrategiasDIF={estrategiasDIF}
                        fechaM = {fechaM}
                        fechaC = {informe.fecha_creacion}

                        socialP1 = {socialP1}
                        socialP2 = {socialP2}
                        socialP3 = {socialP3}
                        setSocialP1 = {setSocialP1}
                        setSocialP2 = {setSocialP2}
                        setSocialP3 = {setSocialP3}
                        />
                        </div>
                </div>

                <div  className="graficosadopcionGRID graAZUL modoreporte">
                    <div className="elemento1">
                    <img className='imagenGRAFICO1'src={grafico3} alt="imagen numero"></img>
                    <img className='imagenGRAFICO2'src={grafico3} alt="imagen numero"></img>
                    Reporte de Planes de Acción – Seguridad
                    <h4>Razón Social: {informe.RazonSocial} / CUIT: {informe.CUIT}</h4>
                    </div>
                    <div className="chiPLAN">
                        <BarrasAgrupadasC
                        gobernanzaP1 = {gobernanzaP1}
                        />
                        </div>
                        <div className="chiPLAN">
                        <BarrasAgrupadas2C
                        gobernanzaP2 = {gobernanzaP2}
                        />
                        </div>
                        <div className="chiPLAN">
                        <BarrasAgrupadas3C
                        gobernanzaP3 = {gobernanzaP3}
                        />
                        </div>
                        <div className="elementofusion4">
                        <TablareporteC
                        estrategias={estrategias}
                        estrategiasNO={estrategiasNO}
                        estrategiasDIF={estrategiasDIF}
                        fechaM = {fechaM}
                        fechaC = {informe.fecha_creacion}

                        gobernanzaP1 = {gobernanzaP1}
                        gobernanzaP2 = {gobernanzaP2}
                        gobernanzaP3 = {gobernanzaP3}
                        setGobernanzaP1 = {setGobernanzaP1}
                        setGobernanzaP2 = {setGobernanzaP2}
                        setGobernanzaP3 = {setGobernanzaP3}
                        />
                        </div>
                </div>
            </div>
            }
        </div>
      );
  }

export default ReporteplanAccion
import React from 'react';
import grafico from './Reporte2.jpg';


  
  function BarrasAgrupadas2({P01}) {
   return( 
    <div className= "Adopcionsquare">
    <img  alt="imagen" className='imagengraficosADOP'src={grafico}></img>
    <div className='titulograficosADOP'>
    <div className='numpregraficosADOP'>P.02</div>
    <div className='colornegro'>Clasificación</div>

    {(P01 > 80) &&
    <div>Muy Alto
    <div>Nivel 5</div>
    </div>
    }
    
    {(P01 < 81 && P01 > 60) &&
    <div>Alto
    <div>Nivel 4</div>
    </div>
    }

    {(P01 < 61 && P01 > 40) &&
    <div>Medio
    <div>Nivel 3</div>
    </div>
    }

    {(P01 < 41 && P01 > 20) &&
    <div>Bajo
    <div>Nivel 2</div>
    </div>
    }

    {(P01 < 21 && P01 > 0) &&
    <div>Muy Bajo
    <div>Nivel 1</div>
    </div>
    }

   

    </div>
    </div>
  );
  }

  export default BarrasAgrupadas2
import React from 'react';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import grafico from './Participantes6.png';
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);


export const options = {
  animation: {
         duration: 500,
  },
  maintainAspectRatio: false,
    plugins: {
      datalabels: {
        display: true,
        color: 'white',
        anchor: 'center',
        align: 'start',
        offset: -5,
        formatter: function(value, index, values) {
          if(value >0 ){
            value = value.toString();
            value = value.split(/(?=(?:...)*$)/);
            value = value.join(',');
            return value+'%';
        }else{
            value = "";
            return value;
        }
        },
        font: {
          size: 10,
        }
     },
      title: {
        display: false,
        text: 'Normas o reglamentos',
      },
      legend: {
        position: 'bottom',
        labels: {
          boxWidth: 5,
          boxHeight: 5,
          padding: 1,
          font: {
            size: 8
        }
        }
    }
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
        ticks: {
          font: {
            size: 8,
          }
        },
      },
      y: {
      stacked: true,
      display: false
    },
  },
};


function sumarDesconocido(PGraficoMic, PGraficoPeq, PGraficoMed1, PGraficoMed2){

  var Tamaño = []

for (let i = 0; i < PGraficoMic.length; i++) {

var sumaTamaño = 100 - (PGraficoMic[i] + PGraficoPeq[i] + PGraficoMed1[i] + PGraficoMed2[i])

if (sumaTamaño !== 100) {
if (sumaTamaño >= 0) {
  Tamaño.push(sumaTamaño)
} else {
  Tamaño.push(sumaTamaño*-1)
}
} else {
Tamaño.push(0)
}


}

var indefinidos = []
indefinidos.push(Tamaño)
return indefinidos

}
 
  function BarrasAgrupadas6({segregacion, arrayP}) {

var naranja  = "rgb(249, 156, 139)"
var naranja1 = "rgb(236, 121, 73)"
var naranja2 = "rgb(205, 103, 21)"
var naranja3 = "rgb(182, 113, 18)"
    

    var EconomicoCabecera = arrayP[4]; // Servicios 3 EconomicoCabecera[3] DEBAJO DEL TITULO

    var prom =arrayP.GraCabeceraEconomico;
    const labels = [['Agrope', prom[0]+'%'],['Comercio', prom[1]+'%'],['Industrial', prom[2]+'%'],['Servicio', prom[3]+'%']]
    
    var indefinidos = sumarDesconocido(arrayP.GraMicEconomico, arrayP.GraPeqEconomico, arrayP.GraMed1Economico, arrayP.GraMed2Economico)
  
  var colorIndefinido = "rgb(118, 118, 118)"

     const data = {
      labels,
      datasets: [
        {
          label: 'Micro',
          data: arrayP.GraMicEconomico,
          backgroundColor: naranja,
        },
        {
          label: 'Pequeña',
          data:  arrayP.GraPeqEconomico,
          backgroundColor: naranja1,
        },
        {
          label: 'Mediana-1',
          data:  arrayP.GraMed1Economico,
          backgroundColor: naranja2,
        },
        {
          label: 'Mediana-2',
          data:  arrayP.GraMed2Economico,
          backgroundColor: naranja3,
        },
        
    /*  {
        label: 'No_precisa',
        data: indefinidos[0],
        backgroundColor: colorIndefinido,
      }, */
      ],
    };
    

   return( 
    <div>
    {segregacion === 0 &&

    <div>
    <img  alt="imagen" className='imagengraficos abajo'src={grafico}></img>
    <div className='titulograficos'>
    <div className='numpregraficos'>P.06</div>
    <div className='colornegro'>Sector Económico</div>
    <div>{arrayP.GraCabeceraEconomico[3]}%
    <div>Servicios</div>
    </div>
    </div>
    <div className='subtitulograficos'>
    <ul className='Par'>
      <li>Mic. = {arrayP.SectorCabeceraMic}%</li>
      <li>Peq. = {arrayP.SectorCabeceraPeq}%</li>
      <li>Med1. = {arrayP.SectorCabeceraMed1}%</li>
      <li>Med2.={arrayP.SectorCabeceraMed2}%</li>
    </ul>
  </div>
    <div className="socialfgrafic">
     <Bar data={data} plugins={[ChartDataLabels]} options={options}/>
    </div>
    </div>
     }
     </div> 
  );
  }

  export default BarrasAgrupadas6
import React, { useState, useEffect } from "react";
import Typography from '@mui/material/Typography';
import { Link, useNavigate } from "react-router-dom";
import { FormControl, InputLabel, Container, FormHelperText, Input, Button, Grid, Menu, MenuItem} from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Dayjs } from 'dayjs';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Stack from '@mui/material/Stack';
import detectZoom from 'detect-zoom';
import axios from 'axios'
import PantallaCarga from "./../pantallacarga/pantallacarga"


function BotonImprimir({user, setUser, fechaInicio , fechaFinal, tipo="nulo", setArrayR, segregacion=100, setSegregacion, setArrayExtra, setArrayExtra2,}) {

    const [noocultar, setNoocultar] = useState(false);
    const [cargado, setCargado] = useState(false);

    var tipoanterior = user.NumTipoUsuario

    const imprimir = () => {

      if (detectZoom.device() *100 < 149 && tipo !== "nulo") {
        window.alert('Su Zoom es '+detectZoom.device()*100+'% '+ 'Recomendamos Zoom 150% o superior para garantizar la nitidez de la imagen')
        console.log('El zoom: '+ detectZoom.device()*100+'%')
      } else {
        console.log('El zoom: '+ detectZoom.device()*100+'%')
      }   
      
    
       /*ACTUALIZA*/
       if (segregacion !== 100) {
        setSegregacion(100)
      }

        setNoocultar(!noocultar);
        
        setUser({
            IDperfil: user.IDperfil,
            CUIT: user.CUIT,
            Nombre: user.Nombre,
            NumTipoUsuario: 10,
            Correo: user.Correo,
            CorreoVerificado: user.CorreoVerificado
          });

        console.log("APAGA USUARIO ES AHORA:"+user.NumTipoUsuario)
        setTimeout(Segrega, 0);
        setTimeout(reporte, 600);
        setTimeout(prender, 1100);
      };

      const Segrega = () => {
        /*ACTUALIZA*/
        if (segregacion !== 100) {
          let Segre=100
          Segre = segregacion
          setSegregacion(Segre)
        }
      };

      const reporte = () => {
        window.print();
      };

      const prender = () => {

        setUser({
            IDperfil: user.IDperfil,
            CUIT: user.CUIT,
            Nombre: user.Nombre,
            NumTipoUsuario: tipoanterior,
            Correo: user.Correo,
            CorreoVerificado: user.CorreoVerificado
          });
        
        
        console.log("PRENDE USUARIO ES AHORA:"+user.NumTipoUsuario)
        setNoocultar(false);
      };
    
      const TraerReporte = () => {

        const base = "https://bid.adeneu.com.ar/autodiagnostico/index.php";

        const headers = {
          "Accept":"application/json"
        }
        // EL ME DAN ERROR  
        //  51 dan error
        setCargado(true)

        if (tipo === "Ambiental") {

          axios.post(base+"?m=procedureReporte",
          [
            [fechaInicio,fechaFinal,1,1], 
            [fechaInicio,fechaFinal,2,5],
            [fechaInicio,fechaFinal,3,8],
            [fechaInicio,fechaFinal,6,29],
            [fechaInicio,fechaFinal,7,31],
            [fechaInicio,fechaFinal,8,36],
            [fechaInicio,fechaFinal,9,40],
            [fechaInicio,fechaFinal,10,42],
            [fechaInicio,fechaFinal,11,44],
            [fechaInicio,fechaFinal,12,47],
            [fechaInicio,fechaFinal,13,49],
            [fechaInicio,fechaFinal,14,51],
            [fechaInicio,fechaFinal,15,53],
            [fechaInicio,fechaFinal,16,56],
            [fechaInicio,fechaFinal,17,62],
            [fechaInicio,fechaFinal,18,65],
            [fechaInicio,fechaFinal,19,71],
            [fechaInicio,fechaFinal,20,74],
            [fechaInicio,fechaFinal,4,14],
            [fechaInicio,fechaFinal,5,21]
          ] 
        ,{headers}).then((response)=>{
          var respuesta = response.data.data;
          setArrayR(respuesta)
          setCargado(false)
          }).catch((error)=>{
            console.error(error)
          })

        } else if (tipo === "Social") {
          /*27,28,36,37 NO ESTOY USANDO PERO
          PONGO PARA NO TENER QUE REORDENAR */
          axios.post(base+"?m=procedureReporte",
          [
            [fechaInicio,fechaFinal,21,78], 
            [fechaInicio,fechaFinal,22,81],
            [fechaInicio,fechaFinal,23,85],
            [fechaInicio,fechaFinal,24,89],
            [fechaInicio,fechaFinal,25,91],
            [fechaInicio,fechaFinal,26,96],
            [fechaInicio,fechaFinal,27,102],
            [fechaInicio,fechaFinal,28,199],
            [fechaInicio,fechaFinal,29,107], //8
            [fechaInicio,fechaFinal,30,111],
            [fechaInicio,fechaFinal,31,114],
            [fechaInicio,fechaFinal,32,118],
            [fechaInicio,fechaFinal,33,122],
            [fechaInicio,fechaFinal,34,124],
            [fechaInicio,fechaFinal,35,126],
            [fechaInicio,fechaFinal,36,133],
            [fechaInicio,fechaFinal,37,205],
            [fechaInicio,fechaFinal,38,138]
          ] 
        ,{headers}).then((response)=>{
          var respuesta = response.data.data;
          setArrayR(respuesta)
         

            /*2do y tercer AXIOS POST */
            axios.post(base+"?m=procedureReporteSocial",
             [
              [fechaInicio,fechaFinal,98],
              [fechaInicio,fechaFinal,129]
             ]
            ,{headers}).then((response)=>{
            var respuesta2 = response.data.data;
            setArrayExtra(respuesta2)
              
              /*tercer AXIOS POST */
              axios.post(base+"?m=procedureReporteDiferencia",
              [
                [fechaInicio,fechaFinal,28],
                [fechaInicio,fechaFinal,37]
              ]
              ,{headers}).then((response)=>{

                var respuesta3 = response.data.data;
                setArrayExtra2(respuesta3)
                setCargado(false)

              }).catch((error)=>{
                console.error(error)
              })

              
            }).catch((error)=>{
              console.error(error)
            })

          }).catch((error)=>{
            console.error(error)
          })

        } else if (tipo === "Gobernanza") {

          axios.post(base+"?m=procedureReporte",
          [
            [fechaInicio,fechaFinal,39,140], 
            [fechaInicio,fechaFinal,40,142],
            [fechaInicio,fechaFinal,41,145],
            [fechaInicio,fechaFinal,42,148],
            [fechaInicio,fechaFinal,43,150],
            [fechaInicio,fechaFinal,44,152],
            [fechaInicio,fechaFinal,45,154],
            [fechaInicio,fechaFinal,46,159],
            [fechaInicio,fechaFinal,47,161],
            [fechaInicio,fechaFinal,48,163],
            [fechaInicio,fechaFinal,49,165],
            [fechaInicio,fechaFinal,50,167],
            [fechaInicio,fechaFinal,51,169],
            [fechaInicio,fechaFinal,52,177],
            [fechaInicio,fechaFinal,53,179],
            [fechaInicio,fechaFinal,54,181],
            [fechaInicio,fechaFinal,55,183],
            [fechaInicio,fechaFinal,56,186],
            [fechaInicio,fechaFinal,57,189],
            [fechaInicio,fechaFinal,58,192],
            [fechaInicio,fechaFinal,59,194],
            [fechaInicio,fechaFinal,60,196]
          ] 
        ,{headers}).then((response)=>{
          var respuesta = response.data.data;
          setArrayR(respuesta)
          setCargado(false)
          }).catch((error)=>{
            console.error(error)
          })

        } else if (tipo === "Adopción") {
          axios.post(base+"?m=procedureReporteAutodiagnostico",
          [
            [fechaInicio,fechaFinal]
          ] 
        ,{headers}).then((response)=>{
          var respuesta = response.data.data[0];
          console.log("Autodiagnosotico envio")
          setArrayR(respuesta)
          setCargado(false)
          }).catch((error)=>{
            console.error(error)
          })

        } else if (tipo === "Participantes") {
          axios.post(base+"?m=procedureReporteParticipante",
          [
            [fechaInicio,fechaFinal]
          ] 
          ,{headers}).then((response)=>{
            var respuesta = response.data.data[0];
            console.log("Participantes envio")
            setArrayR(respuesta)
            setCargado(false)
            }).catch((error)=>{
              console.error(error)
            })
        }
      };
    

    return (
      <div>
        {
        cargado
        ?
        <div>
        <PantallaCarga/>
        </div>
        :
        <div className={`${noocultar ? "ocultarPregunta" : ""}`}>
        {user.NumTipoUsuario !== 1 &&
        <Button onClick={TraerReporte}  variant="contained" color="primary" sx={{ fontWeight: 'bold', mt: 2 }} >
         Traer Reporte
        </Button>
        }

        <Button onClick={imprimir}  variant="contained" color="primary" sx={{ fontWeight: 'bold', mt: 2 }} >
         Imprimir PDF
        </Button>       
        </div>
        }
      </div>

        
    );
  }

  function InformesAdmin({user, setUser, fechaInicio , fechaFinal}){
    const [noocultar, setNoocultar] = useState(false);
    const [cargado, setCargado] = useState(false);

    const urlBaseBTN2 = "https://talleres.aria12.com/autodiagnostico/index.php?m=reporteCsvPuntosRango&fechaInicio=" + fechaInicio.substr(0,10) +"%20"+fechaInicio.substr(11,20) + "&fechaFin=" + fechaFinal.substr(0,10) +"%20"+fechaFinal.substr(11,21);


    return (
      <div>
        {
        cargado
        ?
        <div>
        <PantallaCarga/>
        </div>
        :
        <div className={`${noocultar ? "ocultarPregunta" : ""}`}>

          
    
        <a href={urlBaseBTN2} >
        <Button variant="contained" color="primary" sx={{ fontWeight: 'bold', mt: 2 }} >
        1.- Reporte de BD completo por fechas normalizada
        </Button>
        </a>

        <a href="https://talleres.aria12.com/autodiagnostico/index.php?m=reporteCsv&NumInforme=">
        <Button  variant="contained" color="primary" sx={{ fontWeight: 'bold', mt: 2 }} >
        2.- Reporte de BD completo por fechas datos crudos
        </Button>   
        </a>
        

        </div>
        }
      </div>

        
    );
  }


  function Fechas({setFechaInicio, setFechaFinal}) {

    const [valueI, setValueI] = React.useState<Dayjs | null>(null);
    const [valueF, setValueF] = React.useState<Dayjs | null>(null);


  
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Grid container justifyContent="flex-start">
        <Grid item md={6} sx={{ mt: 6 }} >
       
      <DatePicker
        label="Ingrese la Fecha Inicial"
        value={valueI}
        onChange={(newValue) => {
          var fechaSeleccionada = newValue?.format()
          var fechaModificada = fechaSeleccionada?.substr(0,10)
          var fechaHecha = fechaModificada+" 00:00:00"
          console.log("Fecha INICIO: "+fechaHecha)
          setFechaInicio(fechaHecha);
          setValueI(newValue)
        }}
        renderInput={(params) => (
          <TextField {...params} helperText={params?.inputProps?.placeholder} />
        )}
      />
      </Grid>
      <Grid item md={6} sx={{ mt: 6 }} >
      <DatePicker
        label="Ingrese la Fecha Final"
        value={valueF}
        onChange={(newValue) => {
          var fechaSeleccionada = newValue?.format()
          var fechaModificada = fechaSeleccionada?.substr(0,10)
          var fechaHecha = fechaModificada+" 00:00:00"
          console.log("Fecha FIN: "+fechaHecha)
          setFechaFinal(fechaHecha);
          setValueF(newValue)
        }}
        renderInput={(params) => (
          <TextField {...params} helperText={params?.inputProps?.placeholder} />
        )}
      />
      </Grid>
      </Grid>
    </LocalizationProvider>
    );
  }



  function Seleccion({segregacion, setSegregacion}) {
    /*Tiene que ser largo para que encaje en el select*/
    const [filtroSeleccione, setFiltro] = React.useState('FiltroTa');

    const handleChange = (event: SelectChangeEvent) => {
      setFiltro(event.target.value as string);
      var filtro2 = (event.target.value as string)

      /*No puedo poner filtro === porque hay un retraso */
      if (filtro2 === "FiltroTa"){
        setSegregacion(segregacion=0);
      }
      if (filtro2 === "FiltroAn"){
        setSegregacion(segregacion=1);
      }
      if (filtro2 === "FiltroSe"){
        setSegregacion(segregacion=2);
      }
      if (filtro2 === "FiltroGe"){
        setSegregacion(segregacion=3);
      }
      if (filtro2 === "FiltroPe"){
        setSegregacion(segregacion=4);
      }
    }
  
    return (
      <div>
       <Grid container>
          <Grid item md={12} sx={{ mt: 6 }} >
          <FormControl fullWidth >
          <InputLabel id="demo-simple-select-label">Seleccione filtro</InputLabel>
          <Select 
            value={filtroSeleccione}
            label="filtroSeleccione"
            onChange={handleChange}>
            <MenuItem value={"FiltroTa"}>Tamaño de Empresa</MenuItem>
            <MenuItem value={"FiltroAn"}>Años de operación</MenuItem>
            <MenuItem value={"FiltroSe"}>Sector economico</MenuItem>
            <MenuItem value={"FiltroGe"}>Género de propiedad</MenuItem>
            <MenuItem value={"FiltroPe"}>Pertenencia al Programa</MenuItem>
          </Select>
          </FormControl>
          </Grid>
        </Grid>
      </div>
    );
  }


export {BotonImprimir, Fechas, Seleccion, InformesAdmin}; 
import './autopreguntas.css';
import PantallaCarga from "./../../pantallacarga/pantallacarga"
import PreguntaObj from './preg/pregObj';
import HorizontalLabelPositionBelowStepper from './pasohorizontal/paso.js'
import React, { useState } from "react";
import {useNavigate } from "react-router-dom";
import { useEffect} from 'react'
import axios from 'axios'
import { LazyLoadImage } from "react-lazy-load-image-component";

import Amb from './ropa.png';
import Soc from './ComputerPerson.png';
import Gob from './Security.png';
import PregIMG from './PreguntaOpc.png';


function Autodiagnostico({resultado, setResultado, user, informe, setInforme}) {
  /*PARA VIAJAR AL OTRO LUGAR QUE SE NECESITA LUEGO DE ENVIAR EL POST*/
  const navigate  = useNavigate(); 

  /*CAMBIO DE PREGUNTAS ARREGLAR PASANDOLO A UN MODULO*/
  const [preguntas, setPreguntas] = useState(0); //0 ambiental // 1 social //2 gobernanza
  

//ALMACENA LAS PREGUNTAS
  const [cabeceraAmbiental, setCabeceraAmbiental] = useState([])
  const [cabeceraSocial, setCabeceraSocial] = useState([])
  const [cabeceraGobernanza, setCabeceraGobernanza] = useState([])
// NO APLICA AUX
  const [noAmb, setNoAmb] = useState([])
  const [noSoc, setNoSoc] = useState([])
  const [noGob, setNoGob] = useState([])
//CARGAR GIF
  const [cargado, setCargado] = useState(true)

//ALMACENA LOS RESULTADOS
  const [respuestaSeleccionadaA, setRespuestaSeleccionadaA] = useState([])
  const [respuestaSeleccionadaS, setRespuestaSeleccionadaS] = useState([])
  const [respuestaSeleccionadaG, setRespuestaSeleccionadaG] = useState([])

  const [norespuestaSeleccionadaA, setNoRespuestaSeleccionadaA] = useState([])
  const [norespuestaSeleccionadaS, setNoRespuestaSeleccionadaS] = useState([])
  const [norespuestaSeleccionadaG, setNoRespuestaSeleccionadaG] = useState([])


  
  //BASE API
  //https://talleres.aria12.com/autodiagnostico/index.php
  //https://talleres.aria12.com/autodiagnostico/index.php
  const base = "https://bid.adeneu.com.ar/autodiagnostico/index.php";

    useEffect(() => {
      /*PARA RECARGAR TODO*/
      const StoreA = JSON.parse(localStorage.getItem('Ambiental'));
      const StoreS = JSON.parse(localStorage.getItem('Social'));
      const StoreG = JSON.parse(localStorage.getItem('Gobernanza'));

      const noStoreA = JSON.parse(localStorage.getItem('noAmbiental'));
      console.log("LOS NO: ")
      console.log(noStoreA)
      const noStoreS = JSON.parse(localStorage.getItem('noSocial'));
      console.log("LOS NO: ")
      console.log(noStoreS)
      const noStoreG = JSON.parse(localStorage.getItem('noGobernanza'));
      console.log("LOS NO: ")
      console.log(noStoreG)

       if (StoreA) {
        setRespuestaSeleccionadaA(StoreA);
       }
       if (StoreS) {
        setRespuestaSeleccionadaS(StoreS);
       } 
       if (StoreG) {
        setRespuestaSeleccionadaG(StoreG);
       } 

       if (noStoreA) {
        setNoRespuestaSeleccionadaA(noStoreA);
       }
       if (noStoreS) {
        setNoRespuestaSeleccionadaS(noStoreS);
       } 
       if (noStoreG) {
        setNoRespuestaSeleccionadaG(noStoreG);
       } 



      const headers = {
        "Accept":"application/json",
      }
    
      // AQUI SE LLAMA A LAS PREGUNTAS
      axios.get(base+"?m=listar&Dimension=Ambiental&per_page=30",{headers}).then((response)=>{
        const Amb = response.data.data
        //ALMACENO LAS PREGUNTAS EN CABECERA
        setCabeceraAmbiental(Amb)
        setCargado(false)
        //ENCUENTRO QUE RESPUESTAS ENTRAN EN QUE CUESTIONARIO
        var AmbNo = []
        var auxAmbNo = []
        Amb.map(function(p,i) {

          var resp = p.respuestas;
          //ENCUENTRO RESPUESTAS
            resp.map(function(p) {
              auxAmbNo.push(p.NumRespuesta)  
              return "a"
            })
          //INSERTO TODO [] EN EL 
          AmbNo.push(auxAmbNo)
          auxAmbNo = []   
          return "a"
        });
        setNoAmb(AmbNo)

      }).catch((error)=>{
        console.error(error)
      })

      axios.get(base+"?m=listar&Dimension=Social&per_page=30",{headers}).then((response)=>{    
        const Soc = response.data.data
        setCabeceraSocial(Soc)
        //ENCUENTRO QUE RESPUESTAS ENTRAN EN QUE CUESTIONARIO
        var SocNo = []
        var auxSocNo = []
        Soc.map(function(p,i) {

          var resp = p.respuestas;
          //ENCUENTRO RESPUESTAS
            resp.map(function(p) {
              auxSocNo.push(p.NumRespuesta)  
              return "a"
            })
          //INSERTO TODO [] EN EL 
          SocNo.push(auxSocNo)
          auxSocNo = []   
          return "a"
        });
        setNoSoc(SocNo)

      }).catch((error)=>{
        console.error(error)
      })

      axios.get(base+"?m=listar&Dimension=Gobernanza&per_page=30",{headers}).then((response)=>{    
        const Gob = response.data.data
        setCabeceraGobernanza(Gob)
        //ENCUENTRO QUE RESPUESTAS ENTRAN EN QUE CUESTIONARIO
        var GobNo = []
        var auxGobNo = []
        Gob.map(function(p,i) {

          var resp = p.respuestas;
          //ENCUENTRO RESPUESTAS
            resp.map(function(p) {
              auxGobNo.push(p.NumRespuesta)  
              return "a"
            })
          //INSERTO TODO [] EN EL 
          GobNo.push(auxGobNo)
          auxGobNo = []   
          return "a"
        });
        setNoGob(GobNo)

      }).catch((error)=>{
        console.error(error)
      })
    }, []);

  const handleSubmitAmbiental  = (event) => 
  {
    /*contador de respuesta, el FOR debe ser exacto o sale error*/
    var extraInput =  0///AQUI PUSE MENOS UNO deberia ser 0

    var total  = cabeceraAmbiental.map(function(p,i) {
      var resp = cabeceraAmbiental[i].respuestas;
        var a = resp.map(function(p,i) {  
        /*contador de cada respuesta*/
        i++;
        /*DETECTA LOS INPUT*/
        //if (p.especifica === "1" || p.especifica === "2"){
        if (p.especifica.toString() === "1" || p.especifica.toString() === "2"){
        extraInput++ // DETECTA POR ALGUN MOTIVO UNO MÁS DE LO QUE DEBERIA
        }
        /*Se resetea y se agrega a un array A*/
        return p.NumRespuesta;
        });
      return a.length;
    });

    /*sumas para hallar las rpta + "prefiero no responder"*/
    var extra = total.length;
    var lodash = require('lodash');
    var sum = lodash.sum(total) + extra + extraInput;
    console.log("DEBERIA DAR UN TOTAL DE X VUELTAS: "+sum +" el extra fue: "+extraInput)
    var arrayAux = []
    
    /*PONER QUE DETECTE EL FLAG DE NO*/
    var aplica = true;
    var onlynumber;
    var auxnoNumber = []
    var contNOAmb = [] 

    for(var i=0;i<sum;i++)
    {

      //DETERMINAR SI NO APLICA ESTA CON CHECK
      var ide=event.target[i].id;
      if(ide.includes('nulo')){
        
        if(document.getElementById(ide).checked){
          contNOAmb.push(ide);
          aplica = false;
          onlynumber = ide.replace(/[^0-9]+/g, ""); // esto retorna el Noide sin letras
          var onlynumberINT = parseInt(onlynumber,10)
          auxnoNumber = noAmb[onlynumberINT-1]
        }
      }


      if(!(ide.includes('nulo'))){
        if(document.getElementById(ide).checked){
          var arrayInput = [];
          var inputAux = null
            /*AGREGO EL INPUT SI EXISTE, seguro sale null porque al precionar submit se vacia*/
            if(document.getElementById(ide+"INPUT")){

               inputAux = event.target[i+1].value.toLowerCase(); //PONGO TODO EN MINUSCULA
               //SI ESCRIBE Y BORRA DEVUELVE "" EN VEZ DED NULL
               if (inputAux === "") {
                inputAux = null
               }
            }
          arrayInput.push(ide)
          arrayInput.push(inputAux)
          /*FLAG PARA NO APLICA  */

          if(aplica) {
            arrayInput.push(1)
          }else{
            console.log("VEAMOS EX: "+ide+" ESTA EN :"+ JSON.stringify(auxnoNumber))
            if(auxnoNumber.includes(parseInt(ide,10))){
            arrayInput.push(0)
            } else {
              arrayInput.push(1)
              aplica = true;
            }
          }
          
          console.log(arrayInput)
          arrayAux.push(arrayInput)
        }
      }
    }
     //SACO LOS INDICES
     var respIND = []
     var indicesComp =  arrayAux.map(function(p,i) {
      var respIND = arrayAux[i][0];
      return respIND
    });
    console.log(indicesComp)
    var contadorPreg = 0
    var auxnoRespondidas = 1
    var noRespondidas = ""
    for(var e=0;e<noAmb.length;e++){
    var auxComparacion = noAmb[e]
    var flagCUMPLE = false
    var nohacenada =  indicesComp.map(function(p,i) {

      if(auxComparacion.includes( parseInt(p,10) )){
        //console.log("EL ARRAY: "+auxComparacion+ " incluye a: "+p+ " PREGUNTA CONFIRMADA")
        flagCUMPLE = true
      }
      return "a"
    });

    if(flagCUMPLE) {
      contadorPreg++
    } else {
      noRespondidas = noRespondidas + auxnoRespondidas + ", "
    }
    auxnoRespondidas++
    }


    if(contadorPreg < noAmb.length){
      alert("Respondistes solo ("+contadorPreg+") de un total de ("+ noAmb.length+") preguntas. Falta el/los número: "+noRespondidas)
      event.preventDefault();
    } else {
    console.log(arrayAux);
    localStorage.setItem('Ambiental', JSON.stringify(arrayAux));
    localStorage.setItem('noAmbiental', JSON.stringify(contNOAmb));
    setRespuestaSeleccionadaA(arrayAux);
    preguntaSGTE();
    event.preventDefault();
    }
 

    
  };

  const handleSubmitSocial  = (event) => 
  { 
     /*contador de respuesta, el FOR debe ser exacto o sale error*/
     var extraInput = 0 //TAMBIEN LO CAMBIE A -2
     var total  = cabeceraSocial.map(function(p,i) {
      var resp = cabeceraSocial[i].respuestas;
        var a = resp.map(function(p,i) {  
        /*contador de cada respuesta*/
        i++;

        if (p.especifica.toString() === "1" || p.especifica.toString() === "2"){ // POR AQUI HAY UN PROBLEMA
          extraInput++
          }
        /*Se resetea y se agrega a un array A*/
        return p.NumRespuesta;
        });
      return a.length;
    });
    /*sumas para hallar las rpta + "prefiero no responder"*/
    var extra = total.length;
    var lodash = require('lodash');
    var sum = lodash.sum(total) + extra + extraInput;
    var arrayAux = []

    /*PONER QUE DETECTE EL FLAG DE NO*/
    var aplica = true;
    var onlynumber;
    var auxnoNumber = []
    var contNOSoc = []  

    for(var i=0;i<sum;i++)
    {
      var ide=event.target[i].id;
          if(ide.includes('nulo')){
            
            if(document.getElementById(ide).checked){
              contNOSoc.push(ide);
              aplica = false;
              onlynumber = ide.replace(/[^0-9]+/g, ""); // esto retorna el Noide sin letras
              var onlynumberINT = parseInt(onlynumber,10)
              auxnoNumber = noSoc[(onlynumberINT-1)-noAmb.length]
            }
          }

      if(!(ide.includes('nulo'))){
        if(document.getElementById(ide).checked){
          var arrayInput = [];
          var inputAux = null
            /*AGREGO EL INPUT SI EXISTE, seguro sale null porque al precionar submit se vacia*/
            if(document.getElementById(ide+"INPUT")){
              
              inputAux = event.target[i+1].value.toLowerCase(); //PONGO TODO EN MINUSCULA
              //SI ESCRIBE Y BORRA DEVUELVE "" EN VEZ DED NULL
              if (inputAux === "") {
               inputAux = null
              }
            }
          arrayInput.push(ide)
          arrayInput.push(inputAux)
          /*FLAG PARA NO APLICA */
          if(aplica) {
            arrayInput.push(1)

          }else{
            console.log("VEAMOS EX: "+ide+" ESTA EN :"+ JSON.stringify(auxnoNumber))
            if(auxnoNumber.includes(parseInt(ide,10))){
            arrayInput.push(0)
            } else {
              arrayInput.push(1)
              aplica = true;
            }

          }
          console.log(arrayInput)
          arrayAux.push(arrayInput)
        }
      }
    }

    //SACO LOS INDICES
    var respIND = []
    var indicesComp =  arrayAux.map(function(p,i) {
     var respIND = arrayAux[i][0];
     return respIND
   });
   console.log(indicesComp)
   var contadorPreg = 0
   var auxnoRespondidas = 21
    var noRespondidas = ""
   for(var e=0;e<noSoc.length;e++){
   var auxComparacion = noSoc[e]
   var flagCUMPLE = false
   var nohacenada =  indicesComp.map(function(p,i) {

     if(auxComparacion.includes( parseInt(p,10) )){
       //console.log("EL ARRAY: "+auxComparacion+ " incluye a: "+p+ " PREGUNTA CONFIRMADA")
       flagCUMPLE = true
     }
     return "a"
   });
   if(flagCUMPLE) {
     contadorPreg++
    } else {
      noRespondidas = noRespondidas + auxnoRespondidas + ", "
    }
    auxnoRespondidas++
   }
   console.log("LOGRAMOS UN TOTAL DE CONTEO: "+ contadorPreg+"DEBEMOS LLEGAR A: "+ noSoc.length)

   if(contadorPreg < noSoc.length){
     alert("Respondistes solo ("+contadorPreg+") de un total de ("+ noSoc.length+") preguntas. Falta el/los número: "+noRespondidas)
     event.preventDefault();
   } else {
    console.log(arrayAux);
    localStorage.setItem('Social', JSON.stringify(arrayAux));
    localStorage.setItem('noSocial', JSON.stringify(contNOSoc));
    setRespuestaSeleccionadaS(arrayAux);
    preguntaSGTE();
    event.preventDefault();
  }
   
    

  };

  const handleSubmitGobernanza  = (event) => 
  {

     /*contador de respuesta, el FOR debe ser exacto o sale error*/
     var extraInput = 0
     var total  = cabeceraGobernanza.map(function(p,i) {
      var resp = cabeceraGobernanza[i].respuestas;
        var a = resp.map(function(p,i) {  
        /*contador de cada respuesta*/
        i++;
         /*DETECTA LOS INPUT*/
         if (p.especifica.toString() === "1" || p.especifica.toString() === "2"){
          extraInput++
          }
        /*Se resetea y se agrega a un array A*/
        return p.NumRespuesta;
        });
      return a.length;
    });
    /*sumas para hallar las rpta + "prefiero no responder"*/
    var extra = total.length;
    var lodash = require('lodash');
    var sum = lodash.sum(total) + extra + extraInput;
    var arrayAux = []

    /*PONER QUE DETECTE EL FLAG DE NO*/
    var aplica = true;
    var onlynumber;
    var auxnoNumber = []
    var contNOGob = []  

    for(var i=0;i<sum;i++)
    {
      var ide=event.target[i].id;
          if(ide.includes('nulo')){
            
            if(document.getElementById(ide).checked){
              contNOGob.push(ide);
              aplica = false;
              onlynumber = ide.replace(/[^0-9]+/g, ""); // esto retorna el Noide sin letras
              var onlynumberINT = parseInt(onlynumber,10)
              auxnoNumber = noGob[((onlynumberINT-1)-noAmb.length)-noSoc.length]
            }
          }
      

      if(!(ide.includes('nulo'))){
        if(document.getElementById(ide).checked){
          var arrayInput = [];
          var inputAux = null
            /*AGREGO EL INPUT SI EXISTE, seguro sale null porque al precionar submit se vacia*/
            if(document.getElementById(ide+"INPUT")){
             
              inputAux = event.target[i+1].value.toLowerCase(); //PONGO TODO EN MINUSCULA
              //SI ESCRIBE Y BORRA DEVUELVE "" EN VEZ DED NULL
              if (inputAux === "") {
               inputAux = null
              }
            }
          arrayInput.push(ide)
          arrayInput.push(inputAux)
          /*FLAG PARA NO APLICA */
          if(aplica) {
            arrayInput.push(1)
          }else{
            console.log("VEAMOS EX: "+ide+" ESTA EN :"+ JSON.stringify(auxnoNumber))
            if(auxnoNumber.includes(parseInt(ide,10))){
            arrayInput.push(0)
            } else {
              arrayInput.push(1)
              aplica = true;
            }
          }
          
          console.log(arrayInput)
          arrayAux.push(arrayInput)
        }
      }
    }

    //SACO LOS INDICES
    var respIND = []
    var indicesComp =  arrayAux.map(function(p,i) {
     var respIND = arrayAux[i][0];
     return respIND
   });
   console.log(indicesComp)
   var contadorPreg = 0
   var auxnoRespondidas = 39
    var noRespondidas = ""
   for(var e=0;e<noGob.length;e++){
   var auxComparacion = noGob[e]
   var flagCUMPLE = false
   var nohacenada =  indicesComp.map(function(p,i) {

     if(auxComparacion.includes( parseInt(p,10) )){
       //console.log("EL ARRAY: "+auxComparacion+ " incluye a: "+p+ " PREGUNTA CONFIRMADA")
       flagCUMPLE = true
     }
     return "a"
   });
   if(flagCUMPLE) {
     contadorPreg++
    } else {
      noRespondidas = noRespondidas + auxnoRespondidas + ", "
    }
    auxnoRespondidas++
   }
   console.log("LOGRAMOS UN TOTAL DE CONTEO: "+ contadorPreg+"DEBEMOS LLEGAR A: "+ noGob.length)

   if(contadorPreg < noGob.length){
     alert("Respondistes solo ("+contadorPreg+") de un total de ("+ noGob.length+") preguntas. Falta el/los número: "+noRespondidas)
     event.preventDefault();
   } else {
    console.log(arrayAux);
    localStorage.setItem('Gobernanza', JSON.stringify(arrayAux));
    localStorage.setItem('noGobernanza', JSON.stringify(contNOGob));
    setRespuestaSeleccionadaG(arrayAux);
    preguntaSGTE();
    event.preventDefault();
  }

    
  };

  const sgteEtapa = () =>{
  /*Conseguir respuestas NOTA: USAR arrayAux si el GOBERNANZA NO APARECE*/
  var arraycompleto = respuestaSeleccionadaA.concat(respuestaSeleccionadaS, respuestaSeleccionadaG);
  setResultado({
    RespuestaSeleccionada: arraycompleto,
  })
  console.log("TODAS LAS RPTA: "+ arraycompleto);
  /*MOVER A INFORME*/
  var fechaACtual = obtenerFechaActual()
    setInforme({
      CUIT: user.CUIT,
      fecha_creacion: fechaACtual,
      fecha_modificacion: fechaACtual,
      RazonSocial: null,
    })
    localStorage.removeItem("Ambiental")
    localStorage.removeItem("Social")
    localStorage.removeItem("Gobernanza")

    localStorage.removeItem("noAmbiental")
    localStorage.removeItem("noSocial")
    localStorage.removeItem("noGobernanza")


    navigate('/FinalAutodiagnostico');
  }



  const obtenerFechaActual = () => 
  {
   // FECHA FORMATO CORRECTO PARA ENVIAR: "2020-01-10T06:26:51Z"
    var date = new Date();
  //var fechaActual = date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear() + " " + date.toLocaleTimeString();
    var fechaActual = date;
    return fechaActual;
  };

  
  const preguntaAnterior = () => 
  {
    setPreguntas(preguntas-1)
    window.scrollTo({
      top: 0, 
      //behavior: 'smooth'
    });

  };

  const preguntaSGTE = () => 
  {
    setPreguntas(preguntas+1)
    window.scrollTo({
      top: 0, 
      //behavior: 'smooth'
    });
  };


  return (
    <div>
      {/*https://codepen.io/washaweb/pen/KVRxRW*/}
      
      <div className="apretar">
        <HorizontalLabelPositionBelowStepper
        preguntas={preguntas}
        />
      </div>
      
      <div className="container__cards">
      {preguntas === 0 &&
      <LazyLoadImage className='imagenpreguntaASG'src={PregIMG} alt="logopregunta"></LazyLoadImage>
      }

    

      {preguntas === 1 &&
      <LazyLoadImage className='imagenpreguntaASG'src={Amb} alt="logopregunta"></LazyLoadImage>
      }

      {preguntas === 2 &&
       <LazyLoadImage className='imagenpreguntaASG'src={Soc} alt="logopregunta2"></LazyLoadImage>
      }

      {preguntas === 3 &&
       <LazyLoadImage className='imagenpreguntaASG'src={Gob} alt="logopregunta3"></LazyLoadImage>
      }

      {preguntas === 4 &&

       <h2 className='h2Preg4'>Gracias por su tiempo, confirme si desea enviar sus respuestas</h2>

      }


      </div>

      {preguntas === 0 &&
      <h2 className='h2Preg4'>Tendra que responder todas las preguntas. Si considera que alguna pregunta no es relevante
      para usted, puede marcar "No aplica" pára no recibir una estrategia, pero de todos modos tendra que responder la pregunta</h2>
      }

    {
      cargado
      ?
      <div>
      <PantallaCarga/>
      </div>
      :
      <div>

          {preguntas === 0 &&
    
        <div className="formulario">
        <div className="cajainput2">
        <input type="submit" value="Comenzar Cuestionario" onClick={preguntaSGTE} ></input>   
        </div>
        </div>
          
        }

        

         {preguntas === 1 &&
          <form id="ambientalFORM" onSubmit={handleSubmitAmbiental}>
          { 
        cabeceraAmbiental.map((p,i) =>(

        <PreguntaObj
         objeto={cabeceraAmbiental[i].respuestas}
         titulo={p.NumCuestionario + ") "+p.EnunciadoPregunta}
         mul={p.Multiple}
         nombre={"Nom"+p.NumCuestionario}
         respuestaSeleccionada = {respuestaSeleccionadaA}
         norespuestaSeleccionada = {norespuestaSeleccionadaA}
        />


        ))
      }
        <div className="formulario">
        <div className="cajainput">
        <input type="button" value="Regresar a la explicación" onClick={preguntaAnterior}></input>   
        <input type="submit" value="Guardar y Proseguir" href="#iniciopreguntas" ></input>   
        </div>
        </div>
          </form>
        }

        {preguntas === 2 &&
          <form id="socialFORM" onSubmit={handleSubmitSocial}>
          {
            cabeceraSocial.map((p,i) =>(
              
                <PreguntaObj
                objeto={cabeceraSocial[i].respuestas}
                titulo={p.NumCuestionario + ") "+p.EnunciadoPregunta}
                mul={p.Multiple}
                nombre={"Nom"+p.NumCuestionario}
                respuestaSeleccionada = {respuestaSeleccionadaS}
                norespuestaSeleccionada = {norespuestaSeleccionadaS}
                />
              
            ))
          }
        <div className="formulario">
        <div className="cajainput">
        <input type="button" value="Regresar a Marketing" onClick={preguntaAnterior}></input>   
        <input type="submit" value="Guardar y Proseguir"></input>
        </div>
        </div>
          </form>
        }

        {preguntas === 3 &&
          <form id="gobernanzaFORM"  onSubmit={handleSubmitGobernanza}>
          {
            cabeceraGobernanza.map((p,i) =>(
             
               <PreguntaObj
                objeto={cabeceraGobernanza[i].respuestas}
                titulo={p.NumCuestionario + ") "+p.EnunciadoPregunta}
                mul={p.Multiple}
                nombre={"Nom"+p.NumCuestionario}
                respuestaSeleccionada = {respuestaSeleccionadaG}
                norespuestaSeleccionada = {norespuestaSeleccionadaG}
                />
              
            ))
          }
        <div className="formulario">
        <div className="cajainput">
        <input type ="button" value="Regresar a Integración" onClick={preguntaAnterior}></input>
        <input type="submit" value="Guardar y Proseguir" ></input>

        </div>
        </div>
          </form>
        }

        {preguntas === 4 &&
        
        
        <div className="formulario">
        <div className="cajainput">
        <input type ="button" value="Regresar a Seguridad" onClick={preguntaAnterior}></input>
        <input type ="button" value="Culminar cuestionario" onClick={sgteEtapa}></input>


        </div>
        </div>

        }

      </div>
    }
    </div>
    );
  }

export default Autodiagnostico
import React from 'react';
import grafico from './Pgobernanza.png';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
export const options = {
  
  animation: {
         duration: 500,
  },
  maintainAspectRatio: false,
  indexAxis: 'y',
  elements: {
    bar: {
      borderWidth: 0,
    },
  },
  responsive: true,
  plugins: {
    datalabels: {
      display: true,
      color: 'white',
   },
    title: {
      display: false,
      text: 'Chart.js Horizontal Bar Chart',
    },
    legend: {
      position: 'left',
      labels: {
        boxWidth: 5,
        boxHeight: 5,
        padding: 1,
          font: {
            size: 8
        }
      }
  }
  },
};

const labels = [''];

  
  function BarrasHorizontales3({segregacion, arrayG}) {

    var naranja  = "rgb(249, 156, 139)"
    var naranja1 = "rgb(236, 121, 73)"
    var naranja2 = "rgb(205, 103, 21)"
    var naranja3 = "rgb(182, 113, 18)"
    var naranja4 = "rgb(151, 109, 6)"
    
    const data0 = {
      labels,
      datasets: [
        {
          label: 'MedT2',
          data: [arrayG[0]],
          borderColor: naranja3,
          backgroundColor: naranja3,
        },
        {
          label: 'MedT1',
          data: [arrayG[1]],
          borderColor: naranja2,
          backgroundColor: naranja2,
        },
        {
          label: 'Pequeña',
          data: [arrayG[2]],
          borderColor: naranja1,
          backgroundColor: naranja1,
        },
        {
          label: 'Micro',
          data: [arrayG[3]],
          borderColor: naranja,
          backgroundColor: naranja,
        },
      
      ],
    };
    
     const data1 = {
      labels,
      datasets: [
        {
          label: 'Nuevas',
          data: [arrayG[4]],
          borderColor: naranja2,
          backgroundColor: naranja2,
        },
        {
          label: 'En Consolidación',
          data: [arrayG[5]],
          borderColor: naranja1,
          backgroundColor: naranja1,
        },
        {
          label: 'Consolidadas',
          data: [arrayG[6]],
          borderColor: naranja,
          backgroundColor: naranja,
        },
      
      ],
    };
    
     const data2 = {
      labels,
      datasets: [
        {
          label: 'Agropecuario',
          data: [arrayG[7]],
          borderColor: naranja4,
          backgroundColor: naranja4,
        },
        {
          label: 'Industria',
          data: [arrayG[8]],
          borderColor: naranja3,
          backgroundColor: naranja3,
        },
        {
          label: 'Servicio',
          data: [arrayG[9]],
          borderColor: naranja2,
          backgroundColor: naranja2,
        },
        {
          label: 'Comercio',
          data: [arrayG[10]],
          borderColor: naranja1,
          backgroundColor: naranja1,
        },
        {
          label:['Construc','ción'],
          data: [arrayG[11]],
          borderColor: naranja,
          backgroundColor: naranja,
        },
      
      ],
    };
    
     const data3 = {
      labels,
      datasets: [
        {
          label: 'Hombre',
          data: [arrayG[12]],
          borderColor: naranja1,
          backgroundColor: naranja1,
        },
        {
          label: 'Mujer',
          data: [arrayG[13]],
          borderColor: naranja,
          backgroundColor: naranja,
        },
      
      ],
    };
    
     const data4 = {
      labels,
      datasets: [
        {
          label: 'No',
          data: [arrayG[14]],
          borderColor: naranja1,
          backgroundColor: naranja1,
        },
        {
          label: 'Sí',
          data: [arrayG[15]],
          borderColor: naranja,
          backgroundColor: naranja,
        },
      
      ],
    };

   return( 
    <div>
    {segregacion === 0 &&
    <div>
  <img  alt="imagen" className='imagengraficos'src={grafico}></img>
  <div className='titulograficos'>
  <div className='numpregraficos'>P.08</div>
  <div className='colornegro'>Subíndice de Gobernanza</div>
  <div>{arrayG[16]}
  <div>Total Mipyme</div>
  </div>
  </div>
  <div className='subtitulograficos'>
     <ul className='Adop'>
     <li>Mic. = {arrayG[3]}</li>
      <li>Peq. = {arrayG[2]}</li>
      <li>Med. = {arrayG[1]}</li>
      <li>Med2. = {arrayG[0]}</li>
    </ul>
  </div>
  <div className="socialfgrafic">
 	<Bar data={data0} plugins={[ChartDataLabels]} options={options}/>
	</div>
  </div>
  }
      {segregacion === 1 &&
    <div>
  <img  alt="imagen" className='imagengraficos'src={grafico}></img>
  <div className='titulograficos'>
  <div className='numpregraficos'>P.08</div>
  <div className='colornegro'>Subíndice de Gobernanza</div>
  <div>{arrayG[16]}
  <div>Total Mipyme</div>
  </div>
  </div>
  <div className='subtitulograficos'>
     <ul className='Adop'>
     <li>Nueva = {arrayG[4]}</li>
      <li>En Consolidación= {arrayG[5]}</li>
      <li>Consolidada = {arrayG[6]}</li>
    </ul>
  </div>
  <div className="socialfgrafic">
 	<Bar data={data1} plugins={[ChartDataLabels]} options={options}/>
	</div>
  </div>
  }
        {segregacion === 2 &&
    <div>
  <img  alt="imagen" className='imagengraficos'src={grafico}></img>
  <div className='titulograficos'>
  <div className='numpregraficos'>P.08</div>
  <div className='colornegro'>Subíndice de Gobernanza</div>
  <div>{arrayG[16]}
  <div>Total Mipyme</div>
  </div>
  </div>
  <div className='subtitulograficos'>
     <ul className='Adop'>
     <li>Agr. = {arrayG[7]}</li>
      <li>Ind. = {arrayG[8]}</li>
      <li>Ser. = {arrayG[9]}</li>
      <li>Com. = {arrayG[10]}</li>
      <li>Cons. = {arrayG[11]}</li>
    </ul>
  </div>
  <div className="socialfgrafic">
 	<Bar data={data2} plugins={[ChartDataLabels]} options={options}/>
	</div>
  </div>
  }
      {segregacion === 3 &&
    <div>
  <img  alt="imagen" className='imagengraficos'src={grafico}></img>
  <div className='titulograficos'>
  <div className='numpregraficos'>P.08</div>
  <div className='colornegro'>Subíndice de Gobernanza</div>
  <div>{arrayG[16]}
  <div>Total Mipyme</div>
  </div>
  </div>
  <div className='subtitulograficos'>
     <ul className='Adop'>
     <li>H. = {arrayG[12]}</li>
      <li>M. = {arrayG[13]}</li>
    </ul>
  </div>
  <div className="socialfgrafic">
 	<Bar data={data3} plugins={[ChartDataLabels]} options={options}/>
	</div>
  </div>
  }
      {segregacion === 4 &&
    <div>
  <img  alt="imagen" className='imagengraficos'src={grafico}></img>
  <div className='titulograficos'>
  <div className='numpregraficos'>P.08</div>
  <div className='colornegro'>Subíndice de Gobernanza</div>
  <div>{arrayG[16]}
  <div>Total Mipyme</div>
  </div>
  </div>
  <div className='subtitulograficos'>
     <ul className='Adop'>
     <li>Sí. = {arrayG[15]}</li>
    <li>No. = {arrayG[14]}</li>
    </ul>
  </div>
  <div className="socialfgrafic">
 	<Bar data={data4} plugins={[ChartDataLabels]} options={options}/>
	</div>
  </div>
  }
  </div>
  );
  }

  export default BarrasHorizontales3
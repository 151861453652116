import React from 'react';
import grafico from './Adopcion1.png';




function BarrasAgrupadas({arrayA}) {
 return( 
  <div className= "Adopcionsquare">
  <img alt="imagen" className='imagengraficosADOP'src={grafico}></img>
  <div className='titulograficosADOP'>
    <div className='numpregraficosADOP'>P.01</div>
   <div className='colornegro'>IAC-ASG</div>
  <div>{arrayA}
  <div>Neuquén</div>
  </div>
  </div>
  </div>
);
}

export default BarrasAgrupadas
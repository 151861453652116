import {Recuperacion}  from "../registro.tsx"
import { Link, useNavigate } from "react-router-dom";

function RecuperacionContra() {
    return (
        <div className="container__finalauto">

            <div className="presentacionfinalauto">
            <h1>Recuperacion de contraseña</h1>
            <h2>Ingrese su correo electrónico</h2>
            </div>
            <div className="Botonesfinalauto">

            <Recuperacion
            >
            </Recuperacion>

            </div>
        </div>
      );
  }

export default RecuperacionContra
import './pantallacarga.css';
import Carga from './cargar.gif';



function PantallaCarga() {
 return( 
  <div className= "pantallaCarga">
  <img  alt="loading..." src={Carga}></img>
  </div>
);
}

export default PantallaCarga
import {RedireccionLogin}  from "./registro.tsx"

function RedireccionLogeo({user}) {
    return (
    <div>
        {(user.CorreoVerificado === "0" || user.CorreoVerificado === 0) &&
    
        <div className="container__finalauto">
            <div className="presentacionfinalauto">
            <h1>Para realizar el proceso de Autodiagnóstico requerimos que cree una cuenta</h1>
            <h2>Utilice su correo y contraseña</h2>
            </div>

            <div className="Botonesfinalauto">

            <RedireccionLogin/>

            </div>

        </div>
        }

        {!(user.CorreoVerificado === "0" || user.CorreoVerificado === 0) &&
    
    <div className="container__finalauto">
        <div className="presentacionfinalauto">
        <h1>Bienvenido, ingrese a "Autodiagnóstico" para comenzar el proceso</h1>
        <h2>O bien puede ingresar a "Resulstado" para ver sus informes </h2>
        </div>

    </div>
    }

    </div>
      );
  }

export default RedireccionLogeo
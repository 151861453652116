import React from 'react';
import grafico from './microship.png';



  
  function BarrasAgrupadas2B({socialP2}) {
   return( 
    <div className= "AdopcionsquarePLAN">
    <img  alt="imagen" className='imagengraficosADOPESTRA abajo'src={grafico}></img>
    <div className='titulograficosADOP'>
    <div className='numpregraficosADOP'>P.02</div>
    <div className='colornegro'>Integración de Hardware</div>
    <div>{socialP2}
    </div>
    </div>
    </div>
  );
  }

  export default BarrasAgrupadas2B
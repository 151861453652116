import { useEffect, useState} from 'react'
import { Link } from "react-router-dom";
import {Button, Box} from '@mui/material';
import axios from 'axios'
import PantallaCarga from "./../../pantallacarga/pantallacarga"


function FinalAutodiag({resultado, setResultado, informe, setInforme}) {

    const [cargado, setCargado] = useState(true)
    const [numinforme, setNuminforme] = useState("inserte CUIT")
    const [fecha, setFecha] = useState("")
 
    var RespuestasAux = resultado.RespuestaSeleccionada;
    
    const headers = {
        "Accept":"application/json",
      }

    //PRUEBAS NOMAS
    const base = "https://bid.adeneu.com.ar/autodiagnostico/index.php";

    useEffect(() => {
      
      
      var NumInforme;
      var RazonSocial;
      var fechaC;
      var fechaM;
        // POST de INFORME
       // FECHA FORMATO CORRECTO PARA ENVIAR: "2020-01-10T06:26:51Z"
       
        axios.post(base+"?m=registrarInforme", {
          "CUIT": informe.CUIT.toString(),
          "fecha_creacion": informe.fecha_creacion,
          "fecha_modificacion": informe.fecha_modificacion,
      }, {headers}).then((response)=>{
        //ESTOY AGARRANDO EL IDINFORM QUE ME DEVUELVA EL POST
        const date = new Date("2022-10-11T01:32:25.948Z")
        NumInforme = response.data.data.id
        RazonSocial = response.data.data.RazonSocial
        fechaC = informe.fecha_creacion.toLocaleString("es-ES").substring(0,16)
        fechaM = informe.fecha_modificacion.toLocaleString("es-ES").substring(0,16)



        console.log("NUM INFORME: "+NumInforme+"RazonSocial: "+RazonSocial +" FechaC: " +fechaC + "FechaM "+fechaM)
        //INFORME ACTUALIZACION
        //informe.NumInforme = NumInforme
        //informe.RazonSocial = RazonSocial

        setInforme({
          NumInforme: NumInforme,
          CUIT: informe.CUIT.toString(),
          fecha_creacion: fechaC,
          fecha_modificacion: fechaM,
          RazonSocial: RazonSocial
        })
        


        console.log("El informe creado: "+ NumInforme+", la razon social: "+response.data.data.RazonSocial);
        //ALMACENO LAS PREGUNTAS EN CABECERA
        setResultado({
            NumInforme: NumInforme,
            RespuestaSeleccionada: RespuestasAux,
          })
        
        setNuminforme(NumInforme)
        var fechaCreacio =  JSON.stringify(informe.fecha_creacion).substring(1,11)
        setFecha(fechaCreacio);
        console.log("Informe: " + JSON.stringify(informe));
        console.log("Resultado: " + JSON.stringify(resultado));

        
         // POST RESPUESTAS
         console.log("ENVIARE: NumINforme "+ NumInforme + " Y respAux "+ RespuestasAux)
         axios.post(base+"?m=registrarRespuestas", {
           "NumInforme": NumInforme.toString(),
           "Respuestas": RespuestasAux,
         }, {headers}).then((response)=>{

             setCargado(false);
         }).catch((error)=>{
         console.error(error)
         })


      }).catch((error)=>{
        console.error(error)
        })

    }, []);


        





    return (
        <div className="container__finalauto">
          

          {
      cargado
      ?
      <div>
      <PantallaCarga/>
      </div>
      :
            <div>

            <div className="presentacionfinalauto">
            <h1>Reportes de Autodiagnóstico ASG</h1>
            <h2>Numero de Informe: {numinforme} Empresa: {informe.RazonSocial} Fecha: {fecha}</h2>
            </div>

            <div className="Botonesfinalauto">


            <Box m={2} pt={3}>
            <Button component={Link} to="/Resultado" variant="contained" color="primary" sx={{ fontWeight: 'bold' }} >
            Ir a la tabla de resultados
            </Button>
            </Box>
            </div>

            </div>
          }
        </div>
      );
  }

export default FinalAutodiag
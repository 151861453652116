import {Button} from '@mui/material';
import './reportetodos.css';
import { Link, useNavigate } from "react-router-dom";
import {Fechas, BotonImprimir, InformesAdmin}  from "../imprimir.tsx"
import React, { useState } from "react";
import axios from 'axios'
import PantallaCarga from "./../../pantallacarga/pantallacarga.js"
import {TablaTodosCuerpo} from "./tabla/tablareportes.js"


function MipymeTodos({user,setUser}) {
    const [fechaInicio, setFechaInicio] = useState("2022-01-01 00:00:00");
    const [fechaFinal, setFechaFinal] = useState("2200-01-01 00:00:00");
    const [cargado, setCargado] = useState(false)
    const navigate  = useNavigate() 

    const traerDatos = () => {
        setCargado(true)

        //ENVIAR A LA PAGINA

        setCargado(false)
        //ESTO ES BIEN HECHO
        /*
        const headers = {
        "Accept":"application/json",
        }
        const base = "https://bid.adeneu.com.ar/autodiagnostico/index.php";
            axios.get(base+"?m=reporteCsv&NumInforme="+"305",{headers}).then((response)=>{
            window.alert("EXCEL LLEGO")
            setCargado(false)
        
        }).catch((error)=>{
                console.error(error)
                setCargado(false)
                })
        */
    }



    return (
        <div>
            
        
            <div className="cuerpomreporteinicio">
                <div>
                   <div className='Azulado titulo'> Puntuación de Reportes</div>
                    {/*
                     <div className="BotonComenzar">
                    <BotonImprimir
                    user = {user}
                    setUser = {setUser}
                    />
                    </div>
                     */

                    }
                    
                    <div className='BotonComenzar'>
                        <InformesAdmin
                        user = {user}
                        setUser = {setUser}
                        fechaInicio = {fechaInicio}
                        fechaFinal = {fechaFinal}
                        ></InformesAdmin>
                    </div>

                </div>

            </div>



            <div className="insertefechas">
            <Fechas
             setFechaInicio = {setFechaInicio}
             setFechaFinal = {setFechaFinal}
             />
            </div>

            <div className="Relleno">aaaa</div>

            {
            cargado
            ?
            <div>
            <PantallaCarga/>
            </div>
            :
            <div className='contenedortablaTODO'>
                {/*
                <table className="tablareporteLista">
                    <thead>
                        <tr>
                            <th  className='center'>Reportes del CUIT:</th>
                        </tr>
                    </thead>
                    <tbody>               
                        <TablaTodosCuerpo
                        ></TablaTodosCuerpo>
                    </tbody>
                </table>
            */}
               
            
            </div>

            }

        </div>
      );
  }

export default MipymeTodos
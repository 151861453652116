import {RedireccionCorreo}  from "./registro.tsx"

function RedireccionCorr() {
    return (
        <div className="container__finalauto">
            <div className="presentacionfinalauto">
            <h1>Necesita verificar su correo</h1>
            <h2>Utilice su correo y contraseña</h2>
            </div>

            <div className="Botonesfinalauto">

            <RedireccionCorreo/>

            </div>

        </div>
      );
  }

export default RedireccionCorr
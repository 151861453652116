import './inicioAutodiagnostico.css';
import {RegistroModal, RegistradoModal} from "./inicioAutodiagnostico.tsx"


function InicioAutodiag({user, setUser, token}) {

    const StoreA = JSON.parse(localStorage.getItem('Ambiental'));
    const StoreS = JSON.parse(localStorage.getItem('Social'));
    const StoreG = JSON.parse(localStorage.getItem('Gobernanza'));

    const noStoreA = JSON.parse(localStorage.getItem('noAmbiental'));
    const noStoreS = JSON.parse(localStorage.getItem('noSocial'));
    const noStoreG = JSON.parse(localStorage.getItem('noGobernanza'));

    
   
    return (
        <div className="container__finalauto">
            <div className="presentacionfinalauto">
            <h1>Inicio del proceso de autodiagnóstico</h1>
            <h2>Los resultados de la encuesta le proporcionaran diversas estrategias a su empresa</h2>
            </div>

            {(user.CUIT === null) &&

            <div className="Botonesfinalauto">
            <RegistroModal
            user = {user}
            setUser = {setUser}
            token = {token}
            ></RegistroModal>
            </div>
            }

            {(user.CUIT !== null) &&
            <div>
            <div className="Botonesfinalauto">
            <RegistradoModal
            user = {user}
            StoreA = {StoreA}
            StoreS = {StoreS}
            StoreG = {StoreG}
            noStoreA = {noStoreA}
            noStoreS = {noStoreS}
            noStoreG = {noStoreG}
            ></RegistradoModal>
            </div>
            </div>
            }



        </div>
      );
  }

export default InicioAutodiag
import './inicioAutodiagnostico.css';
import {VolverAutodiagnostico} from "./inicioAutodiagnostico.tsx"


import paso from './PASO1.jpg';
import paso2 from './PASO2.jpg';
import paso3 from './PASO3.jpg';
import paso4 from './PASO4.jpg';


function InicioAutodiagPRE() {

   
    return (
        <div className="container__finalautoANCHO">
            <div className="presentacionfinalautoANCHO">
            <h1>Se le indicara como responder adecuadamente</h1>
            
            <h2>1) Ingrese los datos de la empresa</h2>
            <p>Se buscara el RUC que ha ingresado en el registro. Si ya se encuentra registrado en el sistema,
                se le expondra los datos relacionados. Por el lado contrario, de no encontrarse registrado tendra que registrarlo usted mismo. Su cuenta sera enlazada al RUC de la empresa cuando
                haga clic en el boton inferior del formulario</p>
           
            
            <p>Si el RUC que ingreso con anterioridad es erroneo, puede cambiarlo 
                realizando click en el boton check correspondiente, puede realizar este proceso varias 
                veces hasta que relacione su cuenta a un RUC.
            </p>


            <p>Cuando quieras repetir el cuestionario, ya no sera necesario realizar este paso
            </p>

            <h2>2) Iniciar el cuestionario</h2>
            
            <p>Las preguntas se dividen en 3 criterios, responda todas las preguntas para poder acceder al siguiente criterio.
            </p>

           

            <p>Cuando acceda al siguiente criterio, se guardaran sus respuestas, puede continuar el formulario más tarde. Si abandona el formulario
                dejando un criterio incompleto, las respuestas actuales no guardaran. Puede retroceder a un criterio anterior y modificar sus respuestas, una vez avance, se guardaran los cambios.
            </p>

            <h2>3) Revisar sus resultados</h2>

            <p>Terminando de responder el cuestionario, el programa realizara los calculos necesarios. Sus resultados seran una serie de recomendaciones en las cuales
                nosotros Cosmos S.A.C podemos ofrecerle nuestors servicios
            </p>

            <img  src={paso4} alt="logo empresa"></img>
            
            </div>





            <div className="BotonesfinalautoDELGADO">
            <h2>Inicie el proceso</h2>
            <VolverAutodiagnostico
            ></VolverAutodiagnostico>
            </div>
            


        </div>
      );
  }

export default InicioAutodiagPRE
import './cuerpoindex.css';
import { Link, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useQueryParams} from "../../queryparams.ts";
import axios from 'axios'


import ImgAsg from "./ASGimagen.png"
import monitor from "./monitor.png"
import whatsap from "./whatsap1.png"

// const ASG = 'cuadroanimado/ASGimagen.png';

function Cuerpoindex({user, setUser}) {
    const navigate  = useNavigate(); 
    const queries = useQueryParams();
    const [correoVeri, setCorreoVeri] = useState(false);
    

    useEffect(() => {
        /*TOKEN DE CORREO */
        const token2 = queries.tk
        if(typeof token2 === "string"){
            const spliteado = token2.toString().split(".");
            console.log("TOKEN SPLITEADO: "+spliteado)
            // POR AHORA NOMAS VERIFICO QUE EL TOKEN SE DIIVIDA EN 3 EXACTO
                if(spliteado.length === 3){
                    var usertemporal = {
                        IDperfil: user.IDperfil,
                        CUIT: user.CUIT,
                        Nombre: user.NOmbre,
                        NumTipoUsuario: user.NumTipoUsuario,
                        preCUIT: user.preCUIT,
                        Correo: user.Correo,
                        CorreoVerificado: 1
                    }
                    setCorreoVeri(true)
                    localStorage.setItem('userLocal', JSON.stringify(usertemporal));
                    setUser(usertemporal)

                }
        }
        /*TOKEN DE CAMBIO CONTRASEÑA */
        const tokenContra = queries.token
        if(typeof tokenContra === "string"){
            var TContra = tokenContra.toString() //Ha veces lo uso para evitar errores
            console.log ("SE ENVIO EL TOKEN")
            localStorage.setItem('tokenContraCambio', (TContra));
            navigate('/ContraCambiada');
        }

    // Se ejecuta 2 veces, en la 2da recien consigue los queries
    }, [queries]);


    return (
      <div>
        <div className="CuerpoIndex">
            { correoVeri
            ?
            <div><p>USUARIO REGISTRADO CON EXITO</p></div>
            :
            <div>
            Disponible Cuestionario TI para desarrollar el sector tecnologico de su empresa
            <p>Le ofrecemos estrategias y soluciones tecnologicas</p>
            <p>
                Comienze a promocionare adecuadamente usando herramientas tecnologicas (Marketing), tambien integrelas al
                desarrollo de sus procesos (Integración) y finalmente proteja su información (Seguridad)
            
            </p>
            </div>
            }
           
            
            <div className="BotonComenzar">
            <Link to='/InicioAutodiagnosticoPre'><LazyLoadImage className='imagenASG' src={ImgAsg} alt="logo empresa"></LazyLoadImage></Link>
            </div>

            {/* IMAGENES */}

            <a href="https://wa.me/51989255677"><div> <img class="whatsap" src={whatsap}></img></div></a>
        

        
        </div>

  <div class="piecontenedor">
  <div class="trozoPie">
      <div>
        <h3>Siguenos en</h3>

        <div class="correo"> pmcosmo@aria12.com</div>
      <div class="correo"> RUC N° 20557512421</div>
      <div class="telefono">+51 989 255 677</div>
      <div class="empresa">Pueblo Libre</div>
      <div >Av.Bolivar 1095</div>

      </div>
  </div>
  </div>

</div>

      );
  }

export default Cuerpoindex



            {/* 
        <a href="https://wa.me/51989255677"><div> <img class="whatsap" src={whatsap_pie}></img></div></a>
        <div class="piecontenedor">
          
         
         <div class="trozoPie">
        <h3>P.M Cosmo S.A.C</h3>
       
          <div><a {...href('/')}>Servicios</a></div>
          <div><a {...href('/nosotros')}>Nosotros</a></div>
          <div><a {...href('/contacto')}>Contacto</a></div> 
       
        </div>

         <div class="trozoPie">
         <h3>Contactenos</h3>
        
           <div class="correo"> gfigueroa@aria12.com</div>
           <div class="correo">  ventas@aria12.com</div>
           <div class="telefono">+51 989 255 677</div>
           <div class="empresa">Pueblo Libre</div>
           <div >Av.Bolivar 1095</div>
        
         </div>

         <div class="trozoPie">
         <h3>Siguenos en</h3>
       
           <div> <img class="imgPIE" src={imageFacebook}></img></div>
           <div> <img class="imgPIE" src={imageInstagram}></img></div>
           <div> <img class="imgPIE" src={imageGithub}></img></div>
           <div> <img class="imgPIE" src={imageLinkedlin}></img></div>
           <div> <a href="https://wa.me/51989255677"><img class="imgPIE" src={whatsap}></img></a></div>
         </div>

       </div>


       :host {
  display: block;
}

.whatsap{
  transition: all .5s ease;
  z-index: 100;
  position: fixed;
  left: 5%;
  top: 92%;
  width: 60px;
  height: 60px;
  box-shadow: 0px, 1px, 10px, rgba(0, 0, 0, 0.2);
}

.whatsap:hover{
  box-shadow: 15px, 15px, 20px rgba(0, 0, 0, 0.8);
  transform: translateY(-9%);
}

.piecontenedor{
  padding-top: 20px;
  padding-bottom: 40px;
  color: white;
  font-size: 20px;
  line-height: 24px;
  background-color: #142468;
  padding-right: 5%;
  padding-left: 5%;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  flex-wrap: wrap;
}

.trozoPie{
  width: 200px;
  height: 100%;
  padding-bottom: 20px;
}

.trozoPie .sinPunto{
  list-style-type: none;
}

.trozoPie  div.correo {
  background: url('../../assets/icon_mail.png') no-repeat;
  background-position: left;
  background-size: contain;
  padding-left: 30px;
  }
  
.trozoPie  div.empresa {
  background: url('../../assets/icon_enterprise.png') no-repeat;
  background-position: 0px left;
  background-size: contain;
  padding-left: 30px;
  }
  
.trozoPie  div.telefono {
  background: url('../../assets/icon_cellphone.png') no-repeat;
  background-position: 0px left;
  background-size: contain;
  padding-left: 30px;
  }

.trozoPie a{
  text-decoration:none;
  list-style-type: none;
  color: rgb(158, 158, 158);
}

.imgPIE{
  padding-left: 10px;
  height: 25px;
  width: 25px;
}



@media only screen and (max-width: 770px){
  .trozoPie {
    width: 100%;
  }
  .whatsap{
  display: none;
  }
}

            */}
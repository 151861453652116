import React, { useState } from "react";
import grafico from './Reporte3.jpg';


  
  function BarrasAgrupadas3({P03}) {

    const Mes = P03?.substring(5,7);
    const Ano = P03?.substring(0,4);

    const [MesNom, setMesNom] = useState(
      () =>{
        switch  (Mes){
          case '01':
            return " Ene"
  
          case '02':
            return " Feb"
            
          case '03':
            return " Mar"
          
          case '04':
            return " Abr"
         
          case '05':
            return " May"
        
          case '06':
            return " Jun"
           
          case '07':
            return " Jul"
         
          case '08':
            return " Ago"
         
          case '09':
            return " Sep"
          
          case '10':
            return " Oct"
        
          case '11':
            return " Nov"
          
          case '12':
            return " Dic"
             
        }
      }
    );

    

   return( 
    <div className= "Adopcionsquare">
    <img  alt="imagen" className='imagengraficosADOP'src={grafico}></img>
    <div className='titulograficosADOP'>
    <div className='numpregraficosADOP'>P.03</div>
    <div className='colornegro'>Fecha de Reporte</div>
    <div>{Mes} {MesNom}
    <div>Año {Ano}</div>
    </div>
    </div>
    </div>
  );
  }

  export default BarrasAgrupadas3
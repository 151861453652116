import React from 'react';
import {socialDiferencias} from "../../BaseDatos.js";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import grafico from './grafico8.jpg';




ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);


export const options = {
  animation: {
         duration: 500,
  },
  maintainAspectRatio: false,
    plugins: {
      datalabels: {
        display: true,
        color: 'white',
        anchor: 'center',
        align: 'start',
        offset: -5,
        formatter: function(value, index, values) {
          if(value >0 ){
            value = value.toString();
            value = value.split(/(?=(?:...)*$)/);
            value = value.join(',');
            return value+'%';
        }else{
            value = "";
            return value;
        }
        },
        font: {
          size: 10,
        }
     },
      title: {
        display: false,
        text: 'Diferencia salarial entre hombres/mujeres',
      },
      legend: {
        position: 'bottom',
        labels: {
          boxWidth: 5,
          boxHeight: 5,
          padding: 1,
          font: {
            size: 8
        }
        }
    }
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
        ticks: {
          font: {
            size: 8,
          }
        },
      },
      y: {
        stacked: true,
        display: false
      },
    },
  };
  




  
  function BarrasAgrupadas8({segregacion, arrayS =socialDiferencias[0]}) {

    const labels = [['Sí','existe','diferencias', arrayS.PSiexiste +'%'], ['No','existe','diferencias', arrayS.PNoexiste +'%']];

var rojo  = "rgb(245, 168, 130)"
var rojo1 = "rgb(208, 113, 88)"
var rojo2 = "rgb(186, 62, 30)"
var rojo3 = "rgb(144, 38, 23)"
var rojo4 = "rgb(113, 11, 42)"
    
   const data = {
    labels,
    datasets: [
      {
        label: 'Micro',
        data: [arrayS.PSI_Mic, arrayS.PNO_Mic],
        backgroundColor: rojo,
      },
      {
        label: 'Pequeña',
        data:  [arrayS.PSI_Peq, arrayS.PNO_Peq],
        backgroundColor: rojo1,
      },
      {
        label: 'Mediana-1',
        data:[arrayS.PSI_Med1, arrayS.PNO_Med1],
        backgroundColor: rojo2,
      },
      {
        label: 'Mediana-2',
        data: [arrayS.PSI_Med2, arrayS.PNO_Med2],
        backgroundColor: rojo3,
      },
    ],
  };

   const data1 = {
    labels,
    datasets: [
      {
        label: 'Nuevas',
        data: [arrayS.PSI_Nueva, arrayS.PNO_Nueva],
        backgroundColor: rojo,
      },
      {
        label: 'En Consolidación',
        data: [arrayS.PSI_Consolidacion, arrayS.PNO_Consolidacion],
        backgroundColor: rojo1,
      },
      {
        label: 'Consolidadas',
        data: [arrayS.PSI_Consolidada, arrayS.PNO_Consolidada],
        backgroundColor: rojo2,
      },
    ],
  };

   const data2 = {
    labels,
    datasets: [
      {
        label: 'Agropecuario',
        data: [arrayS.PSI_Agropecuario, arrayS.PNO_Agropecuario],
        backgroundColor: rojo,
      },
      {
        label: 'Industrial',
        data: [arrayS.PSI_Industria, arrayS.PNO_Industria],
        backgroundColor: rojo1,
      },
      {
        label: 'Servicio',
        data: [arrayS.PSI_Servicios, arrayS.PNO_Servicios],
        backgroundColor: rojo2,
      },
      {
        label: 'Comercio',
        data:[arrayS.PSI_Comercio, arrayS.PNO_Comercio],
        backgroundColor: rojo3,
      },
      {
        label: 'Construcción',
        data: [arrayS.PSI_Construcción, arrayS.PNO_Construcción],
        backgroundColor: rojo4,
      },
    ],
  };

   const data3 = {
    labels,
    datasets: [
      {
        label: 'Hombre',
        data:  [arrayS.PSI_HOMBRE, arrayS.PNO_HOMBRE],
        backgroundColor: rojo,
      },
      {
        label: 'Mujer',
        data:  [arrayS.PSI_MUJER, arrayS.PNO_MUJER],
        backgroundColor: rojo1,
      },
    ],
  };

   const data4 = {
    labels,
    datasets: [
      {
        label: 'Sí',
        data: [arrayS.PSI_SI, arrayS.PNO_SI],
        backgroundColor: rojo,
      },
      {
        label: 'No',
        data: [arrayS.PSI_NO, arrayS.PNO_NO],
        backgroundColor: rojo1,
      },
  
    ],
  };

 return( 
    <div>
    {segregacion === 0 &&
    <div>
    <img alt="imagen" className='imagengraficos abajo'src={grafico}></img>
    <div className='titulograficos'>
    <div className='numpregraficos'>P.28</div>
    <div className='colornegro'>  Diferencia Salarial entre Hombres/Mujeres</div>
    <div>{arrayS.PNoexiste}%
    <div>No existe diferencia</div>
    </div>
    
    </div>
    <div className='subtitulograficos'>
     <ul className='Soc'>
      <li>Mic. = {arrayS.PNO_Mic}% </li>
      <li>Peq. = {arrayS.PNO_Peq}%</li>
      <li>Med1. = {arrayS.PNO_Med1}%</li>
      <li>Med2. = {arrayS.PNO_Med2}%</li>
    </ul>
  </div>
    <div className="socialfgrafic">
      
     <Bar data={data} plugins={[ChartDataLabels]} options={options}/>
    </div>
    </div>
    }
    {segregacion === 1 &&
    <div>
    <img alt="imagen" className='imagengraficos abajo'src={grafico}></img>
    <div className='titulograficos'>
    <div className='numpregraficos'>P.28</div>
    <div className='colornegro'>  Diferencia Salarial entre Hombres/Mujeres</div>
    <div>{arrayS.PNoexiste}%
    <div>No existe diferencia</div>
    </div>
    
    </div>
    <div className='subtitulograficos'>
     <ul className='Soc'>
      <li>Nueva = {arrayS.PNO_Nueva}%</li>
      <li>En Consolidación= {arrayS.PNO_Consolidacion}%</li>
      <li>Consolidada = {arrayS.PNO_Consolidada}%</li>
    </ul>
  </div>
    <div className="socialfgrafic">
      
     <Bar data={data1} plugins={[ChartDataLabels]} options={options}/>
    </div>
    </div>
    }
        {segregacion === 2 &&
    <div>
    <img alt="imagen" className='imagengraficos abajo'src={grafico}></img>
    <div className='titulograficos'>
    <div className='numpregraficos'>P.28</div>
    <div className='colornegro'>  Diferencia Salarial entre Hombres/Mujeres</div>
    <div>{arrayS.PNoexiste}%
    <div>No existe diferencia</div>
    </div>
    
    </div>
    <div className='subtitulograficos'>
     <ul className='Soc'>
    <li>Agr. = {arrayS.PNO_Agropecuario}%</li>
      <li>Ind. = {arrayS.PNO_Industria}%</li>
      <li>Ser. = {arrayS.PNO_Servicios}%</li>
      <li>Com. = {arrayS.PNO_Comercio}%</li>
      <li>Con. = {arrayS.PNO_Construcción}%</li>
    </ul>
  </div>
    <div className="socialfgrafic">
      
     <Bar data={data2} plugins={[ChartDataLabels]} options={options}/>
    </div>
    </div>
    }
    {segregacion === 3 &&
    <div>
    <img alt="imagen" className='imagengraficos abajo'src={grafico}></img>
    <div className='titulograficos'>
    <div className='numpregraficos'>P.28</div>
    <div className='colornegro'>  Diferencia Salarial entre Hombres/Mujeres</div>
    <div>{arrayS.PNoexiste}%
    <div>No existe diferencia</div>
    </div>
    
    </div>
    <div className='subtitulograficos'>
     <ul className='Soc'>
     <li>H. = {arrayS.PNO_HOMBRE}%</li>
     <li>M. = {arrayS.PNO_MUJER}% </li>
    </ul>
  </div>
    <div className="socialfgrafic">
      
     <Bar data={data3} plugins={[ChartDataLabels]} options={options}/>
    </div>
    </div>
    }
    {segregacion === 4 &&
    <div>
    <img alt="imagen" className='imagengraficos'src={grafico}></img>
    <div className='titulograficos'>
    <div className='numpregraficos'>P.28</div>
    <div className='colornegro'>  Diferencia Salarial entre Hombres/Mujeres</div>
    <div>{arrayS.PNoexiste}%
    <div>No existe diferencia</div>
    </div>
    
    </div>
    <div className='subtitulograficos'>
     <ul className='Soc'>
     <li>Sí. = {arrayS.PNO_SI}%  </li>
      <li>No. = {arrayS.PNO_NO}% </li>
    </ul>
  </div>
    <div className="socialfgrafic">
      
     <Bar data={data4} plugins={[ChartDataLabels]} options={options}/>
    </div>
    </div>
    }
    </div>
  );
  }

  export default BarrasAgrupadas8
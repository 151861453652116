import React from 'react';
import grafico from './Reporte4.jpg';


  
  function BarrasAgrupadas4({P04, P03}) {
   return( 
    <div className= "Adopcionsquare">
    <img  alt="imagen" className='imagengraficosADOP'src={grafico}></img>
    <div className='titulograficosADOP'>
    <div className='numpregraficosADOP'>P.04</div>
    <div className='colornegro'>N° de Reportes</div>
   <div>{P04}
    <div>Desde el año {P03?.substring(0,4)}</div>
    </div>
    </div>
    </div>
  );
  }

  export default BarrasAgrupadas4
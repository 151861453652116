import {Navigate, Outlet} from 'react-router-dom'

export const ProtectedRoute = ({user, redirectTo="/autodiagnostico"}) => {
    
    if ( !(user.NumTipoUsuario === 2 || user.NumTipoUsuario === 3 || user.NumTipoUsuario === 10) ){
        return <Navigate to ={redirectTo}/>
    }

    return <Outlet/>
}

export const ProtectedNoLogeo = ({user, redirectTo="/RedirrecionLogeo"}) => {
    
    if (user.NumTipoUsuario === 0){
        return <Navigate to ={redirectTo}/>
    } else if (user.CorreoVerificado === "0" || user.CorreoVerificado === 0){
        return <Navigate to ="RedirrecionCorreo"/>
    }

    return <Outlet/>
}
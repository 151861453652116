import React from 'react';

import grafico from './Adopcion2.png';

  
  function BarrasAgrupadas2({arrayA}) {
   return( 
    <div className= "Adopcionsquare">
    <img alt="imagen" className='imagengraficosADOP abajo'src={grafico}></img>
    <div className='titulograficosADOP'>
    <div className='numpregraficosADOP'>P.02</div>
    <div className='colornegro'>IAC-ASG</div>
    <div>{arrayA}
    <div>Micro</div>
    </div>
    </div>
    </div>
  );
  }

  export default BarrasAgrupadas2
import React, { useState, useEffect } from "react";
import Typography from '@mui/material/Typography';
import { Link, useNavigate } from "react-router-dom";
import { FormControl, InputLabel, Container, FormHelperText, Input, Button, Grid, Menu, MenuItem} from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import axios from 'axios'
import PantallaCarga from "./../pantallacarga/pantallacarga"

import key from "./key.png"
import cuenta from "./exam.png"

/*
const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
*/


/* esto modificar para que funcione en la cabecera */
function NombreUsuario(){
  
  const [nom, setNom] = React.useState('');
  const handleChange = (event: SelectChangeEvent) => {
    setNom(event.target.value as string);}

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return(
    <div>

      <Grid container>
        <FormControl fullWidth>

      <Button
      variant="contained"
        sx={{ fontWeight: 'bold' }} 
        id="demo-positioned-button"
        aria-controls={open ? 'demo-positioned-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick} >
      </Button>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={handleClose}>Perfil</MenuItem>
        <MenuItem onClick={handleClose}>Salir</MenuItem>
      </Menu>
        </FormControl>
      </Grid>

    </div>
  );
}
 
function LoginModal({user, setUser, setToken}) {
  const navigate  = useNavigate(); /**/
  const [correo, setCorreo] = useState("");
  const [password, setPassword] = useState("");
  const [cargado, setCargado] = useState(false)
  



  const Logeo = () => {
    setCargado(true);
    const headers = {
      "Accept":"application/json",
    }
        const base = "https://bid.adeneu.com.ar/autodiagnostico/index.php";
        axios.post(base+"?m=login", {
        "Correo": correo,
        "Contraseña": password
      }, {headers}).then((response)=>{
        //REVISAR MENSAJE
        console.log("El USUARIO RECEPCIONADO: "+ JSON.stringify(response.data.data.IDperfil));
        console.log("El USUARIO CORREO VERIFI: "+ JSON.stringify(response.data.data.CorreoVerificado));
        if (response.data.data.IDperfil != null){
                //ESTOY AGARRANDO EL IDINFORM QUE ME DEVUELVA EL POST
                var NumPerfil = response.data.data.IDperfil;
                var CUITPerfil = response.data.data.CUIT;
                var NomPerfil = response.data.data.Nombre;
                var NumTPerfil =  parseInt(response.data.data.NumTipoUsuario, 10);
                var CorPerfil =  response.data.data.Correo;
                var VerPerfil = response.data.data.CorreoVerificado;
                var PrePerfil = response.data.data.preCUIT;
                var Token =  response.data.data.token;
                console.log("El TOKEN: "+ Token);
                console.log("El preCUIT:"+ PrePerfil)
                setToken(Token);

                //ALMACENO LAS PREGUNTAS EN CABECERA
                var usertemporal = {
                  IDperfil: NumPerfil,
                  CUIT: CUITPerfil,
                  Nombre: NomPerfil,
                  NumTipoUsuario: NumTPerfil,
                  preCUIT: PrePerfil,
                  Correo: CorPerfil,
                  CorreoVerificado: VerPerfil
                }

                //localStorage.setItem('userLocal', JSON.stringify(usertemporal));
                //localStorage.setItem('tokenLocal', (Token));

                setUser({
                  IDperfil: NumPerfil,
                  CUIT: CUITPerfil,
                  Nombre: NomPerfil,
                  NumTipoUsuario: NumTPerfil,
                  preCUIT: PrePerfil,
                  Correo: CorPerfil,
                  CorreoVerificado: VerPerfil
                  })
                                
                  /*REVISEMOS SI SE GUARDO BIEN */
                  localStorage.setItem('userLocal', JSON.stringify(usertemporal));
                  localStorage.setItem('tokenLocal', (Token));
                
                  navigate('/autodiagnostico');
                  setCargado(false);



        } else {
          window.alert("CREDENCIALES INCORRECTAS");
          setCargado(false);
        }

      }).catch((error)=>{
        console.error(error)
        console.log(error.response)
        setCargado(false);
        })
 
  }

    return (
      <div>
        {/*PONER BUTTON EN VEZ DE A*/}
        <form>
            <Container maxWidth="md">
          <Typography id="modal-modal-title" variant="h4" component="h2">
              Acceso <img className='imagenreporte'src={key} alt="imagen"></img>
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 3 }}>
              Ingrese a su cuenta: 
            </Typography>

    {
    cargado
    ?
    <div>
    <PantallaCarga/>
    </div>
    :

        <Grid container>
        <Grid item md={6}>
          <FormControl disabled={cargado} sx={{ mt: 6 }}>
            <InputLabel  htmlFor="correo">Correo electrónico</InputLabel>
            <Input id="correo" name="correo" value={correo} onChange={(e)=>setCorreo(e.target.value)} aria-describedby="my-helper-text" />
          </FormControl>
        </Grid>

        <Grid item md={6}>
        <FormControl disabled={cargado} sx={{ mt: 6 }}>
          <InputLabel  htmlFor="password">Contraseña</InputLabel>
          <Input id="password" type="password" name="password" value={password} onChange={(e)=>setPassword(e.target.value)} aria-describedby="my-helper-text" />
        </FormControl>
        </Grid>
      
      
       
        <Button disabled={cargado}  onClick={Logeo} variant="contained" color="primary" sx={{ mt: 12 }}>
        Ingresar
        </Button>
        

        </Grid>
    }
        </Container>
        </form>
      </div>
    );
  }

  function Recuperacion() {

  const navigate  = useNavigate(); /**/
  const [correo, setCorreo] = useState("");
  const [cargado, setCargado] = useState(false)
  const [cargado2, setCargado2] = useState(false)
  
  const Logeo = () => {
    setCargado(true);
     const headers = {
      "Accept":"application/json",
    }
        const base = "https://bid.adeneu.com.ar/autodiagnostico/index.php";
        axios.post(base+"?m=enviarCorreo&correo="+correo, {
      }, {headers}).then((response)=>{
        setCargado(false);
        setCargado2(true);

      }).catch((error)=>{
        console.error(error)
        console.log(error.response)
        setCargado(false);
        })
  }

    return (
      <div>
        {/*PONER BUTTON EN VEZ DE A*/}
        <form>
          <Container maxWidth="md">
          <Typography id="modal-modal-title" variant="h4" component="h2">
              Recuperación por Correo Electronico
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 3 }}>
              Se le enviara un link al correo:
            </Typography>

    {
    cargado
    ?
    <div>
    <PantallaCarga/>
    </div>
    :
    <Grid container>
        {
        cargado2
        ?
        <div>
         <Typography id="modal-modal-title" variant="h6" component="h2">
          Mensaje enviado al Correo: {correo}
          </Typography>
        </div>
        :
        <div>
           <Grid item md={12}>
          <FormControl disabled={cargado} sx={{ mt: 6 }}>
            <InputLabel  htmlFor="correo">Correo electrónico</InputLabel>
            <Input id="correo" name="correo" value={correo} onChange={(e)=>setCorreo(e.target.value)} aria-describedby="my-helper-text" />
          </FormControl>
        </Grid>
       
        <Button disabled={cargado}  onClick={Logeo} variant="contained" color="primary" sx={{ mt: 12 }}>
        Enviar
        </Button>
        </div>
 
      }
        

        

        </Grid>
    }
        </Container>
        </form>
      </div>
    );
  
  }



function RegistroModal({perfilR, setPerfilR, setUser, setToken}) {

  const navigate  = useNavigate();
  const [cargado, setCargado] = useState(false)

  const [doc, setDoc] = useState("");
  const [nombre, setNombre] = useState("");
  const [apellido, setApellido] = useState("");
  const [correo, setCorreo] = useState("");
  const [telefono, setTelefono] = useState("");
  const [preCUIT, setPreCUIT] = useState("");
  const [contra, setContra] = useState("");
  const [contra2, setContra2] = useState("");

  const [pais, setPais] = React.useState('Peru');
  const handleChange = (event: SelectChangeEvent) => {
  setPais(event.target.value as string);
  ve.Pais = "valido"
  }

  const [cargo, setCargo] = useState("Analista");
  const handleChangeCargo = (event: SelectChangeEvent) => {
  setCargo(event.target.value as string);
  ve.CargoMipyme = "valido"
  }


  const [ve, setVe] = useState({
    "Nombre": nombre,
    "Apellido": apellido,
    "DocumentoIdentidad": doc,
    "CargoMipyme": cargo,
    "NumTelefonico": telefono,
    "Correo": correo,
    "Pais": pais,
    "preCUIT": preCUIT,
    "Contraseña": contra
  });

  

  const registro = () => {
      if (contra === contra2) {
        console.log("EL_VE:" +JSON.stringify(ve))
        if (!(
          ve.Nombre === "valido" &&
          ve.Apellido === "valido" &&
          ve.DocumentoIdentidad === "valido" &&
          ve.CargoMipyme !== "" &&
          ve.NumTelefonico === "valido" &&
          ve.Correo === "valido" &&
          ve.Pais !== "" &&
          ve.preCUIT === "valido" &&
          ve.Contraseña === "valido"
        )
        ){
          window.alert("Algunos campos estan mal validados");
        } else {
          setCargado(true)
          setPerfilR ({
            "Nombre": nombre+" "+apellido,
            "DocumentoIdentidad": doc,
            "CargoMipyme": cargo,
            "NumTelefonico": telefono,
            "Correo": correo,
            "preCUIT": preCUIT,
            "CorreoVerificado": 0,
            "Contraseña": contra
            //pais: pais
          })
          // VAMOS A HACE QUE EJECUTE OTRA COSA EXTRA
          VerCorreo()
          
        }
      } else { 
        window.alert("las contraseñas no son iguales");
      }
    }

    //AQUI ESTA EL POST PARA REGISTRAR AL USUARIO Y DENTRO EL DE TOKEN
    const VerCorreo = () => {
      const headers = {
        "Accept":"application/json",
      }
      const base = "https://bid.adeneu.com.ar/autodiagnostico/index.php";
        console.log("PASO VERIFICACION, INICIANDO FASE 2");


        axios.post(base+"?m=registrarUsuario", {

          "Nombre": nombre+" "+apellido,
          "DocumentoIdentidad": doc,
          "CargoMipyme": cargo,
          "NumTelefonico": telefono,
          "Correo": correo,
          "preCUIT": preCUIT,
          "Contraseña": contra
  
        }, {headers}).then((response)=>{

        if(Object.entries(response.data.data).length === 0) {
          console.log("ESPERA ESTO NO DEBERIA IMPRIMIRSE");
          window.alert(response.data.message);
          setCargado(false)
            } else {
              console.log("DATOS RECIBIDOS "+ JSON.stringify(response.data.data));
              //ESTOY AGARRANDO EL IDINFORM QUE ME DEVUELVA EL POST
              var NumPerfil = response.data.data.IDperfil;
              var NomPerfil = response.data.data.Nombre;
              var NumTPerfil = response.data.data.NumTipoUsuario;
              var CorPerfil =  response.data.data.Correo;
              console.log("El USUARIO creado: "+ NumPerfil);
              //ALMACENO LAS PREGUNTAS EN CABECERA
      
              var usertemporal = {
                IDperfil: NumPerfil,
                CUIT: null,
                Nombre: NomPerfil,
                NumTipoUsuario: NumTPerfil,
                Correo: CorPerfil,
                preCUIT: perfilR.preCUIT,
                CorreoVerificado: 0
              }
      
              setUser({
                IDperfil: NumPerfil,
                CUIT: null,
                Nombre: NomPerfil,
                NumTipoUsuario: NumTPerfil,
                Correo: CorPerfil,
                preCUIT: perfilR.preCUIT,
                CorreoVerificado: 0
                })
      
                //LOGEO TOKEN
                axios.post(base+"?m=login", {
                  "Correo": correo,
                  "Contraseña": contra
                }, {headers}).then((response)=>{
                  //REVISAR MENSAJE
                  console.log("El USUARIO RECEPCIONADO: "+ JSON.stringify(response.data.data.IDperfil));
                  console.log("Correo verificiado: "+ response.data.data.CorreoVerificado)
                  if (response.data.data.IDperfil != null){
                    
                          var Token =  response.data.data.token;
                          console.log("El TOKEN DE REGISTRO: "+ Token);
                          setToken(Token)
                          setCargado(false);


                          localStorage.setItem('userLocal', JSON.stringify(usertemporal));
                          localStorage.setItem('tokenLocal', (Token));
                          navigate('/RedireccionLogeoPost');
      
                  } else {
                    window.alert("PROBLEMAS: "+response.data.message);
                    setCargado(false)
                  }
          
                }).catch((error)=>{
                  console.error(error)
                  console.log(error.response)
                  setCargado(false);
                  })
              } //final else de REGISTRO

        }).catch((error)=>{
          console.error(error)
          setCargado(false)
          })
        
    }
   
    
  
    return (
      <div>

      {
      cargado
      ?
      <div>
      <Typography id="modal-modal-title" variant="h4" component="h2">
              Por favor espere
      </Typography>
      <PantallaCarga/>
      </div>
      :
      <Typography id="modal-modal-title" variant="h4" component="h2">
              Registro <img className='imagenreporte'src={cuenta} alt="imagen"></img>
      </Typography>
      }
        <Container maxWidth="md">
          
            <Typography id="modal-modal-description" sx={{ mt: 3 }}>
              cree una cuenta:
            </Typography>



        <Grid container>
          <Grid item md={6}>
            <FormControl disabled={cargado} sx={{ mt: 6 }}>
              <InputLabel   htmlFor="Nombre">Nombres</InputLabel>
              <Input id="Nombre" onChange={(e)=>{setNombre(e.target.value)
              setDoc(e.target.value);
    
              if(/^[a-zA-ZÀ-ÿ\s]{3,24}$/.test(e.target.value) ) {
              ve.Nombre = "valido"
              } else {
              ve.Nombre = "Invalido"
              }
              
              }} aria-describedby="my-helper-text" />
              {ve.Nombre === "" && <FormHelperText id="my-helper-text">Obligatorio</FormHelperText>}
              {ve.Nombre !== "" && <FormHelperText id="my-helper-text">{ve.Nombre}</FormHelperText>}
              
            </FormControl>
          </Grid>
          <Grid item md={6}>
            <FormControl disabled={cargado} sx={{ mt: 6 }}>
              <InputLabel  htmlFor="Apellido">Apellidos</InputLabel>
              <Input id="Apellido" onChange={(e)=>{
                setApellido(e.target.value);
   
                if(/^[a-zA-ZÀ-ÿ\s]{3,24}$/.test(e.target.value) ) {
                ve.Apellido = "valido"
                } else {
                ve.Apellido = "Invalido"
                }
              }} aria-describedby="my-helper-text" />
              {ve.Apellido === "" && <FormHelperText id="my-helper-text">Obligatorio</FormHelperText>}
              {ve.Apellido !== "" && <FormHelperText id="my-helper-text">{ve.Apellido}</FormHelperText>}
            </FormControl>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item md={6}>
            <FormControl disabled={cargado} sx={{ mt: 6 }}>
              <InputLabel  htmlFor="Correo">Correo</InputLabel>
              <Input id="Correo" onChange={(e)=>{
                setCorreo(e.target.value)
  
                if(/\S+@\S+\.\S+/.test(e.target.value) ) {
                ve.Correo = "valido"
                } else {
                ve.Correo = "Invalido"
                }

              }} aria-describedby="my-helper-text" />
              {ve.Correo === "" && <FormHelperText id="my-helper-text">Obligatorio</FormHelperText>}
              {ve.Correo !== "" && <FormHelperText id="my-helper-text">{ve.Correo}</FormHelperText>}
            </FormControl>
          </Grid>
          <Grid item md={6}>
            <FormControl disabled={cargado} sx={{ mt: 6 }}>
              <InputLabel  htmlFor="telefono">Teléfono</InputLabel>
              <Input  type="number" id="telefono" onChange={(e)=>{
                setTelefono(e.target.value)
       
                if(/^[0-9]{9,10}$/.test(e.target.value) ) {
                ve.NumTelefonico = "valido"
                } else {
                ve.NumTelefonico = "Invalido"
                }

              
              }} aria-describedby="my-helper-text" />
              {ve.NumTelefonico === "" && <FormHelperText id="my-helper-text">Obligatorio</FormHelperText>}
              {ve.NumTelefonico !== "" && <FormHelperText id="my-helper-text">{ve.NumTelefonico}</FormHelperText>}

              
            </FormControl>
          </Grid>
        </Grid>

        <Grid container>

          <Grid item md={6}>
            <FormControl disabled={cargado} sx={{ mt: 6 }}>
              <InputLabel  htmlFor="Cargo">RUC empresa</InputLabel>
              <Input type="number" id="CUIT"  onChange={(e)=>{
                setPreCUIT(e.target.value)

                if(/^[0-9]{11,11}$/.test(e.target.value) ) {
               ve.preCUIT = "valido"
               } else {
               ve.preCUIT = "Invalido"
               }
               
              }} aria-describedby="my-helper-text" />
              {ve.preCUIT === "" && <FormHelperText id="my-helper-text">Obligatorio</FormHelperText>}
              {ve.preCUIT !== "" && <FormHelperText id="my-helper-text">{ve.preCUIT}</FormHelperText>}
              
            </FormControl>
          </Grid>

          

          <Grid item md={6}>
            <FormControl disabled={cargado} sx={{ mt: 6 }}>
              <InputLabel  htmlFor="Doc">Doc. de Identidad</InputLabel>
              <Input id="Doc" type="number" onChange={(e)=>{
                setDoc(e.target.value);

                if(/^[0-9]{8,8}$/.test(e.target.value) ) {
                ve.DocumentoIdentidad = "valido"
                } else {
                ve.DocumentoIdentidad = "Invalido"
                }

                }} aria-describedby="my-helper-text" />
              {ve.DocumentoIdentidad === "" && <FormHelperText id="my-helper-text">Obligatorio</FormHelperText>}
              {ve.DocumentoIdentidad !== "" && <FormHelperText id="my-helper-text">{ve.DocumentoIdentidad}</FormHelperText>}
             
            </FormControl>
          </Grid>
          </Grid>

        <Grid container>
          <Grid item md={6}>
            <FormControl disabled={cargado} sx={{ mt: 6 }}>
              <InputLabel  htmlFor="Contra">Cree Contraseña</InputLabel>
              <Input id="Contra" type="password" onChange={(e)=>{
                setContra(e.target.value)
         
                if( /^[a-z0-9_-]{6,18}$/.test(e.target.value) ) {
                ve.Contraseña = "valido"
                } else {
                ve.Contraseña = "Invalido"
                }

                }} aria-describedby="my-helper-text" />
              {ve.Contraseña === "" && <FormHelperText id="my-helper-text">Obligatorio</FormHelperText>}
              {ve.Contraseña !== "" && <FormHelperText id="my-helper-text">{ve.Contraseña}</FormHelperText>}
            </FormControl>
          </Grid>
          

          <Grid item md={6}>
            <FormControl disabled={cargado} sx={{ mt: 6 }}>
              <InputLabel  htmlFor="Contra2">Repita Contraseña</InputLabel>
              <Input id="Contra2" type="password" onChange={(e)=>setContra2(e.target.value)} aria-describedby="my-helper-text" />
              <FormHelperText id="my-helper-text">Obligatorio</FormHelperText>
            </FormControl>
          </Grid>
          </Grid>

          

          <Grid container>
            <Grid item md={6}>
            <FormControl disabled={cargado} sx={{ mt: 6 }} >
            <InputLabel id="demo-simple-select-label">País</InputLabel>
            <Select
              value={pais}
              label="pais"
              onChange={handleChange}>
              <MenuItem value={"Peru"}>Perú</MenuItem>

            </Select>
            </FormControl>
            </Grid>
          </Grid>

          <Grid container>
          <Grid item md={6} >
          <FormControl disabled={cargado} sx={{ mt: 6 }}>
          <InputLabel  id="demo-simple-select-label">Cargo Empresa</InputLabel>
          <Select
            value={cargo}
            label="Cargo Mipyme"
            onChange={handleChangeCargo}>
            <MenuItem value={"Propietario"}>Propietario</MenuItem>
            <MenuItem value={"Consultor"}>Consultor</MenuItem>
            <MenuItem value={"Gerente"}>Gerente</MenuItem>
            <MenuItem value={"Director"}>Director</MenuItem>
            <MenuItem value={"Socio"}>Socio</MenuItem>
            <MenuItem value={"Ingeniero"}>Ingeniero</MenuItem>
            <MenuItem value={"Técnico"}>Técnico</MenuItem>
            <MenuItem value={"Contador"}>Contador</MenuItem>
            <MenuItem value={"Analista"}>Analista</MenuItem>
          </Select>
          </FormControl>
          </Grid>
          
          </Grid>

          <Button disabled={cargado}  variant="contained" color="primary"  onClick={registro} sx={{ mt: 8 }}>
          Ingresar
          </Button>
        
        </Container>
      </div>
    );
  }
  
    function VerificarCorreo({perfilR, setUser, setToken}) {
      return (
        <div>
         
      
          <form>
            <Container maxWidth="md">
            <Typography id="modal-modal-title" variant="h4" component="h2">
            El e-mail fue enviado a su correo. En caso no llegue el mail puede hacer click aquí para reenviar correo.
            </Typography>
          <Grid container>
          <Link to="/CorreoEnvio">
          <Button variant="contained" color="primary" sx={{ mt: 2 }}>
          Reenviar Correo
          </Button>
          </Link>        
          </Grid>
          </Container>
          </form>
        </div>
      );
    }

    function RedireccionLogin() {
      return (
        <div>
          <form>
              <Container maxWidth="md">
            <Typography id="modal-modal-title" variant="h4" component="h2">
            Iniciar proceso 
            </Typography>
          
          <Grid container>
          
          <Link to="/Loginusuario">
          <Button variant="contained" color="primary" sx={{ mt: 2 }}>
          Ingreso
          </Button>
          </Link>
          
  
          </Grid>
          </Container>
          </form>
        </div>
      );
    }

    function RedireccionCorreo() {
      return (
        <div>
          <form>
              <Container maxWidth="md">
            <Typography id="modal-modal-title" variant="h4" component="h2">
            Verificar correo 
            </Typography>
          
          <Grid container>
          
          <Link to="/CorreoEnvio">
          <Button variant="contained" color="primary" sx={{ mt: 2 }}>
          Enviar e-mail
          </Button>
          </Link>
          
  
          </Grid>
          </Container>
          </form>
        </div>
      );
    }


  export {LoginModal, RegistroModal, NombreUsuario, RedireccionLogin, RedireccionCorreo, VerificarCorreo, Recuperacion}; 

import { Radar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from 'chart.js';


ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);




function GraficoRombo({P05A,P05S,P05G}) {

   const options ={
  
    responsive: true,
    animation: {
         duration: 500,
  },
  maintainAspectRatio: false,
    plugins: {
      datalabels: {
        display: true,
        color: "#000",
        font: {
          size: 15
        },
     },
      title: {
        display: false,
        text: 'GRAFICO ROMBO',
      },
      legend: {
        display: false,
        position: 'left',
        labels: {
          boxWidth: 5,
          boxHeight: 5,
          padding: 1,
            font: {
              size: 8
          }
        }
    }
    },
    
    scales:{
      r: { 
        /* REMOVER TEXTO DE LOS EXTREMOS
        pointLabels: {
          display: false 
      }, */
      ticks: {
        display: false // Hides the labels in the middel (numbers)
    },
              angleLines: {
                  display: true,
                  color: "black"
              },
              suggestedMin: 0,
              suggestedMax: 100
          }
      }
  };
  
   const data = {
    labels: [['Sub Indice', 'Ambiental','100'], ['100 Subíndice', 'Social'], ['Sub Indice','Gobernanza 100']],
    datasets: [
      {
        label: 'Mi Mype',
        data: [P05A, P05S, P05G],
        backgroundColor: 'rgba(255, 99, 82, 0.2)',
        borderColor: 'rgba(255, 99, 20, 22)',
        borderWidth: 5,
      },
    ],
  };

 return( 
 	<div className="socialfgraficADOP">
 	<Radar data={data} plugins={[ChartDataLabels]} options={options}/>
	</div>
);
}

export default GraficoRombo
import React from 'react';
import {dataformat} from "../../BaseDatos.js";
import grafico19 from './Ambiental19.png';
import grafico20 from './Ambiental20.png';




/*EL CSS SE ENCUENTRA EN MIPYMEPARTICIPA*/

  
  function TextoUnico1({segregacion, arrayB =dataformat[0], arrayA =dataformat[0]}) {
   return( 
    <div>
  {segregacion === 0 &&
      <div>
      <img alt="imagen" className='imagengraficosPARTICIPA'src={grafico19}></img>
      <div className='titulograficosPARTICIPA'>
        <div className='numpregraficosPARTICIPA'>P.04</div>
        <div className='colornegro'>Energias Renovables para Otros fines</div>
        <div>{arrayB.Noporcentaje}%
          <div>Energía térmica</div>
        </div>
      </div>
      <div className='subtitulograficosPARTICIPA'>
         <ul className='Amb'>
         <li>Mic. = {arrayB.MicPorcentaje}% </li>
      <li>Peq. = {arrayB.PeqPorcentaje}%</li>
      <li>Med1. = {arrayB.Med1Porcentaje}%</li>
      <li>Med2. = {arrayB.Med2Porcentaje}%</li>
        </ul>
      <img className='imagengraficosPARTICIPA'src={grafico20}></img>
      <div className='titulograficosPARTICIPA'>
        <div className='numpregraficosPARTICIPA'>P.05</div>
        <div className='colornegro'>Sistema de Eficiencia Enérgetica</div>
        <div>{arrayA.Noporcentaje}%
          <div>Ahorro de Agua</div>
        </div>
      </div>

      <div className='subtitulograficosPARTICIPA'>
        <ul className='Amb'>
        <li>Mic. = {arrayA.MicPorcentaje}% </li>
        <li>Peq. = {arrayA.PeqPorcentaje}%</li>
        <li>Med1. = {arrayA.Med1Porcentaje}%</li>
        <li>Med2. = {arrayA.Med2Porcentaje}%</li>
        </ul>
      </div>
      </div>
      </div>
  }
  {segregacion === 1 &&
      <div>
      <img alt="imagen" className='imagengraficosPARTICIPA'src={grafico19}></img>
      <div className='titulograficosPARTICIPA'>
        <div className='numpregraficosPARTICIPA'>P.04</div>
        <div className='colornegro'>Energias Renovables para Otros fines</div>
        <div>{arrayB.Noporcentaje}%
          <div>Energía térmica</div>
        </div>
      </div>
      <div className='subtitulograficosPARTICIPA'>
         <ul className='Amb'>
         <li>Nueva = {arrayB.NuevaPorcentaje}%</li>
      <li>En Consolidación= {arrayB.ConsolidacionPorcentaje}%</li>
      <li>Consolidada = {arrayB.ConsolidadasPorcentaje}%</li>
        <li className='colorblanco'>.</li>
        </ul>
      <img className='imagengraficosPARTICIPA'src={grafico20}></img>
      <div className='titulograficosPARTICIPA'>
        <div className='numpregraficosPARTICIPA'>P.05</div>
        <div className='colornegro'>Sistema de Eficiencia Enérgetica</div>
        <div>{arrayA.Noporcentaje}%
          <div>Ahorro de Agua</div>
        </div>
      </div>

      <div className='subtitulograficosPARTICIPA'>
        <ul className='Amb'>
        <li>Nueva = {arrayA.NuevaPorcentaje}%</li>
      <li>En Consolidación= {arrayA.ConsolidacionPorcentaje}%</li>
      <li>Consolidada = {arrayA.ConsolidadasPorcentaje}%</li>
      
        </ul>
      </div>
      </div>
      </div>
  }
  {segregacion === 2 &&
      <div>
      <img alt="imagen" className='imagengraficosPARTICIPA'src={grafico19}></img>
      <div className='titulograficosPARTICIPA'>
        <div className='numpregraficosPARTICIPA'>P.04</div>
        <div className='colornegro'>Energias Renovables para Otros fines</div>
        <div>{arrayB.Noporcentaje}%
          <div>Energía térmica</div>
        </div>
      </div>
      <div className='subtitulograficosPARTICIPA'>
         <ul className='Amb'>
      <li>Agr. = {arrayB.AgropecuarioPorcentaje}%</li>
      <li>Ind. = {arrayB.IndustriaPorcentaje}%</li>
      <li>Ser. = {arrayB.ServiciosPorcentaje}%</li>
      <li>Com. = {arrayB.ComercioPorcentaje}%</li>
      <li>Con. = {arrayB.ConstrucciónPorcentaje}%</li>
      <li>.</li>
        </ul>
      <img className='imagengraficosPARTICIPA'src={grafico20}></img>
      <div className='titulograficosPARTICIPA'>
        <div className='numpregraficosPARTICIPA'>P.05</div>
        <div className='colornegro'>Sistema de Eficiencia Enérgetica</div>
        <div>{arrayA.Noporcentaje}%
        <div>Ahorro de Agua</div>
        </div>
      </div>

      <div className='subtitulograficosPARTICIPA'>
        <ul className='Amb'>
        <li>Agr. = {arrayA.AgropecuarioPorcentaje}%</li>
      <li>Ind. = {arrayA.IndustriaPorcentaje}%</li>
      <li>Ser. = {arrayA.ServiciosPorcentaje}%</li>
      <li>Com. = {arrayA.ComercioPorcentaje}%</li>
      <li>Con. = {arrayA.ConstrucciónPorcentaje}%</li>
      
        </ul>
      </div>
      </div>
      </div>
  }
  {segregacion === 3 &&
      <div>
      <img alt="imagen" className='imagengraficosPARTICIPA'src={grafico19}></img>
      <div className='titulograficosPARTICIPA'>
        <div className='numpregraficosPARTICIPA'>P.04</div>
        <div className='colornegro'>Energias Renovables para Otros fines</div>
        <div>{arrayB.Noporcentaje}%
          <div>Energía térmica</div>
        </div>
      </div>
      <div className='subtitulograficosPARTICIPA'>
         <ul className='Amb'>
         <li>H. = {arrayB.MasculinoPorcentaje}%</li>
      <li>M. = {arrayB.FemeninoPorcentaje}% </li>
        </ul>
      <img className='imagengraficosPARTICIPA'src={grafico20}></img>
      <div className='titulograficosPARTICIPA'>
        <div className='numpregraficosPARTICIPA'>P.05</div>
        <div className='colornegro'>Sistema de Eficiencia Enérgetica</div>
        <div>{arrayA.Noporcentaje}%
        <div>Ahorro de Agua</div>
        </div>
      </div>

      <div className='subtitulograficosPARTICIPA'>
        <ul className='Amb'>
        <li>H. = {arrayA.MasculinoPorcentaje}%</li>
      <li>M. = {arrayA.FemeninoPorcentaje}% </li>
        </ul>
      </div>
      </div>
      </div>
  }
  {segregacion === 4 &&
      <div>
      <img alt="imagen" className='imagengraficosPARTICIPA'src={grafico19}></img>
      <div className='titulograficosPARTICIPA'>
        <div className='numpregraficosPARTICIPA'>P.04</div>
        <div className='colornegro'>Energias Renovables para Otros fines</div>
        <div>{arrayB.Noporcentaje}%
          <div>Energía térmica</div>
        </div>
      </div>
      <div className='subtitulograficosPARTICIPA'>
         <ul className='Amb'>
         <li>Sí. = {arrayB.PertenecePorcentaje}%  </li>
      <li>No. = {arrayB.NoPertenecePorcentaje}% </li>
        </ul>
      <img className='imagengraficosPARTICIPA'src={grafico20}></img>
      <div className='titulograficosPARTICIPA'>
        <div className='numpregraficosPARTICIPA'>P.05</div>
        <div className='colornegro'>Sistema de Eficiencia Enérgetica</div>
        <div>{arrayA.Noporcentaje}%
        <div>Ahorro de Agua</div>
        </div>
      </div>

      <div className='subtitulograficosPARTICIPA'>
        <ul className='Amb'>
        <li>Sí. = {arrayA.PertenecePorcentaje}%  </li>
      <li>No. = {arrayA.NoPertenecePorcentaje}% </li>
        </ul>
      </div>
      </div>
      </div>
  }


    </div>

  );
  }

  export default TextoUnico1
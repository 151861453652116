import './reporteautodiagnostico.css';
import {Button} from '@mui/material';
import { useEffect} from 'react'
import axios from 'axios'
import React, { useState } from "react";
import { BotonImprimir } from '../imprimir.tsx';
import PantallaCarga from "./../../pantallacarga/pantallacarga"

import Tablareporte from "./graficos/tablareporte"
import BarrasAgrupadas from "./graficos/BarrasAgrupadas"
import BarrasAgrupadas2 from "./graficos/BarrasAgrupadas2"
import BarrasAgrupadas3 from "./graficos/BarrasAgrupadas3"
import BarrasAgrupadas4 from "./graficos/BarrasAgrupadas4"
import GraficoRombo from "./graficos/graficos"



import grafico1 from "./graficoNara2.png"
import relleno from "./rellenoreporte.png"


/* imagenes para el radar que no pude meter
const SUB1 = 'cuadroanimado/SUB1.png';
const SUB2 = 'cuadroanimado/SUB2.png';
const SUB3 = 'cuadroanimado/SUB3.jpg';
*/

const imprimir = () => {
    window.print();
  };
//function Reporteautodiagnostico({NunInforme=1}) {
function Reporteautodiagnostico({informe, user, setUser}) {

  const obtenerFechaActual = () => 
  {
   // FECHA FORMATO CORRECTO PARA ENVIAR: "2020-01-10T06:26:51Z"
    var date = new Date();
  //var fechaActual = date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear() + " " + date.toLocaleTimeString();
    var fechaActual = date;
    return fechaActual;
  };


    const headers = {
        "Content-Type":"application/json"
      }
    //       "Accept":"application/json",
    const [fechaActual, setFechaActual] = useState();
    const [Cuit, setCuit] = useState();
    const [P01, setP01] = useState();
    const [P03, setP03] = useState();
    const [P04, setP04] = useState();
    const [P05, setP05] = useState([]);
    const [P06, setP06] = useState([]);
    const [cargado, setCargado] = useState(true)
    const [noInforme, setNoInforme] = useState(true)
    const base = "https://bid.adeneu.com.ar/autodiagnostico/index.php";

    useEffect(() => {

      var date = new Date();
      var fechaActual = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate() + " " + date.toLocaleTimeString();
      var fechaActualAux = fechaActual+""
      var fechaActualAux = fechaActualAux.substring(0, fechaActualAux.length - 3);
      setFechaActual(fechaActualAux)

      axios.get(base+"?m=listarInforme&NumInforme="+informe.NumInforme,{headers}).then((response)=>{

        if (response.data.data.length !=0) {
          const resp = response.data.data
          //ALMACENO LOS DATOS
          setCuit(resp[0].CUIT);
          setP01(resp[0].PuntajeTotalASG);
          setP03(resp[0].fecha_creacion);
          setP04(resp[0].CantidadInforme); /*Me falta este dato aun, necesito el total de reportes*/
          setP05([resp[0].PuntajeA,
                  resp[0].PuntajeS,
                  resp[0].PuntajeG]);
          
          setP06([resp[0].Suma1Planificacion,
                  resp[0].Suma2Mitigacion,
                  resp[0].Suma3Adaptacion,
                  resp[0].Suma4Estrategico,
                  resp[0].Suma5Participacion,
                  resp[0].Suma6Enfoque,
                  resp[0].Suma7Participantes,
                  resp[0].Suma8Madurez,
                  resp[0].Suma9Especialistas,
                  resp[0].Suma10Calidad,
                  resp[0].Suma11Relacion]);
          
          console.log(resp)
          setCargado(false)  
          setNoInforme(false)
        } else {
          console.log("NO EXISTE EL INFORME")
          setCargado(false)  
        }
       
      }).catch((error)=>{
        console.error(error)
      })
    }, []);


    return (
        <div>
            {
            cargado
            ?
            <PantallaCarga/>
            :
            <div className="cuerpomreporteinicio">
              
            
            {(noInforme === true) &&
              <div>
                No existe el informe
              </div>
            }

            {(noInforme === false) &&
              <div>
               <div>
                    <div className='Anaranjado tituloREPORTE'>Resultados de Autodiagnóstico</div>
                    <p>Fecha creación informe (AA/MM/DD hh:mm): {informe.fecha_creacion} <br></br>
                    Fecha impresión informe (AA/MM/DD hh:mm): {fechaActual+""} <br></br>
                    Número de Informe: {informe.NumInforme} <br></br>
                    Razón social de la Empresa: {informe.RazonSocial}
                    </p>
                    <div className="BotonComenzar">
                    {/*<Button onClick={imprimir}  variant="contained" color="primary" sx={{ fontWeight: 'bold' }} >
                        Descargar PDF
                    </Button>
                    */}
                    <BotonImprimir
                    user = {user}
                    setUser = {setUser}
                    />
                    </div>
                </div>
              </div>
            }

                
            </div>

            }
            
            <div className="Relleno">
              a
            </div>

            {
            cargado || noInforme
            ?
            <PantallaCarga/>
            :
            <div>

              <div  className="graficosadopcionASGCEL">
                <div className="elemento1 NARANJA">
                <img className='imagenGRAFICO1'src={grafico1} alt="imagen numero"></img>
                <img className='imagenGRAFICO2'src={relleno} alt="imagen numero"></img>
                Reporte 
                <h4>Razón Social:{informe.RazonSocial} / CUIT:{Cuit}</h4>
                </div>
                <div className="elementofusion">
                    <GraficoRombo 
                    P05A={P05[0]}
                    P05S={P05[1]}
                    P05G={P05[2]}
                    />
                    </div>
                    <div className="chiADO">
                    <BarrasAgrupadas P01={P01}/>
                    </div>
                    <div className="chiADO">
                    <BarrasAgrupadas2 P01={P01}/>
                    </div>
                    <div className="chiADO">
                    <BarrasAgrupadas3 P03={P03}/>
                    </div>
                    <div className="chiADO">
                    <BarrasAgrupadas4
                    P04={P04}
                    P03={P03}
                    />
                    </div>
                    <div className="elementofusion2">
                    <Tablareporte P06={P06}/>
                    </div>

            </div>


             
            <div  className="graficosadopcionGRID modoreporteASG">
                <div className="elemento1 NARANJA">
                <img className='imagenGRAFICO1'src={grafico1} alt="imagen numero"></img>
                <img className='imagenGRAFICO2'src={relleno} alt="imagen numero"></img>
                Reporte 
                <h4>Razón Social:{informe.RazonSocial} / CUIT:{Cuit}</h4>
                </div>
                    <div className="chiADO">
                    <BarrasAgrupadas P01={P01}/>
                    </div>
                    <div className="chiADO elementofusion">
                    <GraficoRombo 
                    P05A={P05[0]}
                    P05S={P05[1]}
                    P05G={P05[2]}
                    />
                    </div>
                    <div className="chiADO">
                    <BarrasAgrupadas2 P01={P01}/>
                    </div>
                    <div className="chiADO">
                    <BarrasAgrupadas3 P03={P03}/>
                    </div>
                    <div className="chiADO">
                    <BarrasAgrupadas4
                    P04={P04}
                    P03={P03}
                    />
                    </div>
                    <div className="elementofusion3">
                    <Tablareporte P06={P06}/>
                    </div>
            </div>

            </div>
            }
            </div>
      );
  }

export default Reporteautodiagnostico
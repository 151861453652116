import './perfil.css';
import React, { useState } from "react";
import PreSocial from './grafico4.png';



function Perfilusuario({user}) {

    const [correoVeri, setCorreoVeri] = useState( () =>{
       if(user.CorreoVerificado === 0){
        return "NO verificado"
       } else {
        return "Verificado"
       }
    }
    )

    return (
        <div className="container__finalauto">
            
            <div className="presentacionfinalPerfil">
            
            <h1>{user.Nombre}</h1>
            </div>

  

            {(user.CUIT === null) &&
            <div className="CuerpoPerfil">
            Aun no esta ligado a {user.preCUIT}, ingrese al autodiagnóstico para comenzar el proceso.
            </div>
            }
            {(user.CUIT !== null) &&
            <div className="CuerpoPerfil">
            CUIT ligiado: {user.CUIT}
            </div>
            }
            <div className="CuerpoPerfil">
            Correo {correoVeri+": " +user.Correo} 
            </div>
                 
            
            <div className="presentacionfinalPerfil2">
            <h1></h1>
            </div>

            
        </div>
      );
  }

export default Perfilusuario
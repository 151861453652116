import './mipymesocial.css';
import './../estandargraficoreporte.css';

import {Button} from '@mui/material';
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { BotonImprimir, Fechas, Seleccion }  from "../imprimir.tsx"
import axios from 'axios'
import PantallaCarga from "./../../pantallacarga/pantallacarga.js"

import BarrasAgrupadas from "./graficos/BarrasAgrupadas"
import BarrasAgrupadas2 from "./graficos/BarrasAgrupadas2"
import BarrasAgrupadas3 from "./graficos/BarrasAgrupadas3"
import BarrasAgrupadas4 from "./graficos/BarrasAgrupadas4"
import BarrasAgrupadas5 from "./graficos/BarrasAgrupadas5"
import BarrasAgrupadas6 from "./graficos/BarrasAgrupadas6"
import BarrasAgrupadas7 from "./graficos/BarrasAgrupadas7"
import BarrasAgrupadas8 from "./graficos/BarrasAgrupadas8"
import BarrasAgrupadas9 from "./graficos/BarrasAgrupadas9"
import BarrasAgrupadas10 from "./graficos/BarrasAgrupadas10"
import BarrasAgrupadas11 from "./graficos/BarrasAgrupadas11"
import BarrasAgrupadas12 from "./graficos/BarrasAgrupadas12"
import BarrasAgrupadas13 from "./graficos/BarrasAgrupadas13"
import BarrasAgrupadas14 from "./graficos/BarrasAgrupadas14"
import BarrasAgrupadas15 from "./graficos/BarrasAgrupadas15"
import BarrasAgrupadas16 from "./graficos/BarrasAgrupadas16"
import BarrasAgrupadas17 from "./graficos/BarrasAgrupadas17"
import BarrasAgrupadas18 from "./graficos/BarrasAgrupadas18"

import grafico1 from "./graficosocial1.png"
import grafico2 from "./graficosocial2.png"



function Mipymesocial({user,setUser}) {

    const obtenerFechaActual = () => 
    {
      var date = new Date();
      var fechaActual = date;
      var fechaA = fechaActual.toLocaleString("es-ES").substring(0,16)
      return fechaA;
    };

      /*Cambio de funciones
      OK 7 y 8 + 16 y 17
      */
      const [segregacion, setSegregacion] = useState(0);
      const [fechaInicio, setFechaInicio] = useState("2022-01-01 00:00:00");
      const [fechaFinal, setFechaFinal] = useState("2200-01-01 00:00:00");
      const [tipo, setTipo] = useState("Social")
      const [arrayS, setArrayS] = useState([])
      /*2do y tercer axios */
      const [arrayExtra, setArrayExtra] = useState([])
      const [arrayExtra2, setArrayExtra2] = useState([])

      const [fechaAct, setFechaAct] = useState("00/00/0000")
      
      useEffect(() => {
        if(typeof arrayS !== "undefined") {
        var fechaACtual = obtenerFechaActual()
        setFechaAct(fechaACtual)
        console.log("HORA DE EXPORTAR:"+arrayS + "EL TIPO SOCIAl ES: "+ typeof arrayS)
        localStorage.setItem('DatoSocial', JSON.stringify(arrayS));}
    },[arrayS])

    return (
        <div>
        
            <div className="cuerpomreporteinicio">
                <div>
                    <div className='Rosado titulo'>Reporte Adopción de Criterio Social</div>
                    
                    <div className="BotonComenzar">
                    <BotonImprimir
                    user = {user}
                    setUser = {setUser}
                    fechaInicio = {fechaInicio}
                    fechaFinal = {fechaFinal}
                    tipo = {tipo}
                    setArrayR = {setArrayS}
                    segregacion={segregacion}
                    setSegregacion={setSegregacion}
                    setArrayExtra = {setArrayExtra}
                    setArrayExtra2 = {setArrayExtra2}
                    />
                    </div>
                </div>

            </div>
            <div className="insertefechas">
                <Fechas
                setFechaInicio = {setFechaInicio}
                setFechaFinal = {setFechaFinal}
                />
                <Seleccion
                segregacion={segregacion}
                setSegregacion={setSegregacion}
                ></Seleccion>
                </div>
            
            <div className="Relleno">aaaa</div>

            <div  className="graficossocialesGRID">
                <div className="elemento1">
                <img className='imagenGRAFICO1'src={grafico1} alt="imagen numero"></img>
                <img className='imagenGRAFICO2'src={grafico2} alt="imagen logo"></img>
                Reporte de Adopción de Criterio Social
                <h4>Fecha de corte: {fechaAct}</h4>
                </div>
                    <div className="child">
                    <BarrasAgrupadas segregacion={segregacion} arrayS={arrayS[0]}/>
                    </div>
                    <div className="child">
                    <BarrasAgrupadas2 segregacion={segregacion} arrayS={arrayS[1]}/>
                    </div>
                    <div className="child">
                    <BarrasAgrupadas3 segregacion={segregacion} arrayS={arrayS[2]}/>
                    </div>
                    <div className="child">
                    <BarrasAgrupadas4 segregacion={segregacion} arrayS={arrayS[3]}/>
                    </div>
                    <div className="child">
                    <BarrasAgrupadas5 segregacion={segregacion} arrayS={arrayS[4]}/>
                    </div>
                    <div className="child">
                    <BarrasAgrupadas6 segregacion={segregacion} arrayS={arrayS[5]}/>
                    </div>

                    <div className="child">
                    <BarrasAgrupadas7 segregacion={segregacion} arrayS={arrayExtra[0]}/>
                    </div>
                    <div className="child">
                    <BarrasAgrupadas8 segregacion={segregacion} arrayS={arrayExtra2[0]}/>
                    </div>

                    <div className="child">
                    <BarrasAgrupadas9 segregacion={segregacion} arrayS={arrayS[8]}/>
                    </div>

            </div>

            <div  className="graficossocialesGRID">
                <div className="elemento1">
                <img className='imagenGRAFICO1'src={grafico1} alt="imagen numero"></img>
                <img className='imagenGRAFICO2'src={grafico2} alt="imagen logo"></img>
                Reporte de Adopción de Criterio Social
                <h4>Fecha de corte: {fechaAct}</h4>
                </div>
                    <div className="child">
                    <BarrasAgrupadas10 segregacion={segregacion} arrayS={arrayS[9]}/>
                    </div>
                    <div className="child">
                    <BarrasAgrupadas11 segregacion={segregacion} arrayS={arrayS[10]}/>
                    </div>
                    <div className="child">
                    <BarrasAgrupadas12 segregacion={segregacion} arrayS={arrayS[11]}/>
                    </div>
                    <div className="child">
                    <BarrasAgrupadas13 segregacion={segregacion} arrayS={arrayS[12]}/>
                    </div>
                    <div className="child">
                    <BarrasAgrupadas14 segregacion={segregacion} arrayS={arrayS[13]}/>
                    </div>
                    <div className="child">
                    <BarrasAgrupadas15 segregacion={segregacion} arrayS={arrayS[14]}/>
                    </div>

                    <div className="child">
                    <BarrasAgrupadas16 segregacion={segregacion} arrayS={arrayExtra[1]}/>
                    </div>
                    <div className="child">
                    <BarrasAgrupadas17 segregacion={segregacion} arrayS={arrayExtra2[1]}/>
                    </div>

                    <div className="child">
                    <BarrasAgrupadas18 segregacion={segregacion} arrayS={arrayS[17]}/>
                    </div>

            </div>

        </div>
      );
  }

export default Mipymesocial
import React from 'react';
import grafico from './candado_sinFONDO.png';



function BarrasAgrupadasC({gobernanzaP1}) {
 return( 
  <div className= "AdopcionsquarePLAN">
  <img  alt="imagen" className='imagengraficosADOPESTRA abajo'src={grafico}></img>
  <div className='titulograficosADOP'>
    <div className='numpregraficosADOP'>P.01</div>
   <div className='colornegro'>Total Criterio Seguridad</div>
  <div>{gobernanzaP1}
  </div>
  </div>
  </div>
);
}

export default BarrasAgrupadasC
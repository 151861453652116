import React, { useState, useEffect } from "react";
import PantallaCarga from "../pantallacarga/pantallacarga"
import axios from 'axios'

function CorreoEnvio({user, token}) {
    
    const [cargado, setCargado] = useState(true)

    
    useEffect(() => {

    console.log("ENVIANDO TOKEN: "+token)

    const headers = {
        "Accept":"application/json",
        'Authorization': `Bearer ${token}`

      }
    


    if(user.CorreoVerificado === 0 || user.CorreoVerificado === "0") {
       
       
        const base = "https://bid.adeneu.com.ar/autodiagnostico/index.php";

        axios.post(base+"?m=emailVerify",{},{headers}).then((response)=>{
            const resp = response.data
            const msj = response.data.message
            console.log ("Mensaje General: " +JSON.stringify(resp))
            console.log ("Mensaje Especifico: " +msj)
            }).catch((error)=>{
              console.error(error)
            })

        setCargado(false)
    } else {
        window.alert("YA TIENE EL CORREO VERIFICADO")
        setCargado(false)
    }
        
    }, []);
    

    return (
        <div>
        { (user.CorreoVerificado === 0 || user.CorreoVerificado === "0") &&
        <div className="container__finalauto">
            
            <div className="presentacionfinalINFO">
            <h1>Verificar Correo</h1>
            </div>

            <div className="SubtituloINFO">
            Se le enviara un mensaje con un enlace a la siguiente dirreción: "{user.Correo}"
            </div>

            {
            cargado
            ?
            <div>
            <PantallaCarga/>
            </div>
            :
                <div className="CuerpoINFO">
                El e-mail fue enviado a {user.Correo}. En caso no llegue el mail puede hacer click aquí para reenviar correo.
                </div>
            }
            </div>
        }

        { !(user.CorreoVerificado === 0 || user.CorreoVerificado === "0") &&
        <div className="container__finalauto">
            
            <div className="presentacionfinalINFO">
            <h1>Correo Verificado</h1>
            </div>

            <div className="SubtituloINFO">
            Su correo ya ha sido verificado: "{user.Correo}"
            </div>
            </div>
        }

        </div>
      );
  }

export default CorreoEnvio
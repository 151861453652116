import React from 'react';
import grafico from './pencilsymbol.png';


  
  function BarrasAgrupadas3({ambientalP3}) {
   return( 
    <div className= "AdopcionsquarePLAN">
    <img  alt="imagen" className='imagengraficosADOPESTRA abajo'src={grafico}></img>
    <div className='titulograficosADOP'>
    <div className='numpregraficosADOP'>P.03</div>
    <div className='colornegro'>Marketing de prod. y serv.</div>
    <div>{ambientalP3}
    </div>
    </div>
    </div>
  );
  }

  export default BarrasAgrupadas3
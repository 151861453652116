import React from 'react';
import { useEffect, useState} from 'react'

import imagen1 from './microship.png';
import imagen2 from './wi fi router.png';


/*
<img className='imagenreporte'src={grafico} alt="imagen"></img>
*/
function TablareporteB({estrategias, fechaM, fechaC, estrategiasNO,estrategiasDIF, setSocialP1, setSocialP2, setSocialP3, socialP1, socialP2, socialP3}) {

    const [contGenero,setContGenero] = useState("");
    const [contMujeres,setContMujeres] = useState("");
    const [contDiverso,setContDiverso] = useState("");
    const [contDiversas,setContDiversas] = useState("");
   
    useEffect(() => {
        var contadorGenero= 0;
        var contadorMujeres = 0;
        var contadorDiverso = 0;
        var contadorDiversas = 0;
    
        estrategiasNO.map(function(x) {
    
            if (x.cuestionario.Variable === "Diseño estratégico con enfoque de género" && x.estrategias.length !== 0) {
                x.estrategias.map(function(b) {
                    if(b.marcado === "0" && (estrategiasDIF.includes( parseInt(b.NumRespuesta,10)))) {
                        contadorGenero++
                    }
                return contadorGenero;
                });
            }
    
            if (x.cuestionario.Variable === "Participación de mujeres" && x.estrategias.length !== 0) {
                x.estrategias.map(function(b) {
                    if(b.marcado === "0" && (estrategiasDIF.includes( parseInt(b.NumRespuesta,10)))) {
                        contadorMujeres++
                    }
                return contadorMujeres;
                });
            }
    
            if (x.cuestionario.Variable === "Diseño estratégico con enfoque de poblaciones diversas" && x.estrategias.length !== 0) {
                x.estrategias.map(function(b) {
                    if(b.marcado === "0" && (estrategiasDIF.includes( parseInt(b.NumRespuesta,10)))) {
                        contadorDiverso++
                    }
                return contadorDiverso;
                });
            }

            if (x.cuestionario.Variable === "Participaciones de poblaciones diversas" && x.estrategias.length !== 0) {
                x.estrategias.map(function(b) {
                    if(b.marcado === "0" && (estrategiasDIF.includes( parseInt(b.NumRespuesta,10)))) {
                        contadorDiversas++
                    }
                return contadorDiversas;
                });
            }

            return "a";
        });

        var contadorGeneroP= 0;
        var contadorMujeresP = 0;
        var contadorDiversoP = 0;
        var contadorDiversasP = 0;

        estrategias.map(function(x) {
    
            if (x.cuestionario.Variable === "Diseño estratégico con enfoque de género" && x.estrategias.length !== 0) {
                x.estrategias.map(function(b) {
                    if(b.marcado === "1") {
                        contadorGeneroP++
                    }
                return contadorGeneroP;
                });
            }
    
            if (x.cuestionario.Variable === "Participación de mujeres" && x.estrategias.length !== 0) {
                x.estrategias.map(function(b) {
                    if(b.marcado === "1") {
                        contadorMujeresP++
                    }
                return contadorMujeresP;
                });
            }
    
            if (x.cuestionario.Variable === "Diseño estratégico con enfoque de poblaciones diversas" && x.estrategias.length !== 0) {
                x.estrategias.map(function(b) {
                    if(b.marcado === "1") {
                        contadorDiversoP++
                    }
                return contadorDiversoP;
                });
            }

            if (x.cuestionario.Variable === "Participaciones de poblaciones diversas" && x.estrategias.length !== 0) {
                x.estrategias.map(function(b) {
                    if(b.marcado === "1") {
                        contadorDiversasP++
                    }
                return contadorDiversasP;
                });
            }

            return "a";
        });



        if (contadorGenero === 0 && contadorGeneroP === 0) {
            setContGenero("Usted no registra actividades en esta sección.")
        }
    
        if (contadorMujeres === 0 && contadorMujeresP === 0) {
            setContMujeres("Usted no registra actividades en esta sección.")
        }
    
        if (contadorDiverso === 0 && contadorDiversoP === 0) {
            setContDiverso("Usted no registra actividades en esta sección.")
        }

        if (contadorDiversas === 0 & contadorDiversasP === 0) {
            setContDiversas("Usted no registra actividades en esta sección.")
        }

        console.log(contadorGenero+" s "+ contadorMujeres +" s  "+contadorDiverso+" s "+contadorDiversas)
        setSocialP1(socialP1+contadorGenero+contadorMujeres+contadorDiverso+contadorDiversas);
        setSocialP2(socialP2+contadorGenero+contadorMujeres);
        setSocialP3(socialP3+contadorDiverso+contadorDiversas);
        },[])

        

 return( 
  <div >

  <div className='titulograficosREPOR'>
    <div className='numpregraficosREPOR'>P.04</div>
    <div className='colornegro'>Planes de Acción según Brechas Identificadas el {fechaC}</div>
    </div>

    <div className="divTable">
    <table className="tablareporte">
    <thead>
        <tr>
            <th>Sub dimensiones</th>
            <th>Variables</th>
            <th className='estrategiaANCHO'>Planes de Accion</th>
        </tr>
    </thead>
    <tbody>
        <tr>
           
            <td rowSpan="2"><img className='imagenreporte'src={imagen1} alt="imagen"></img><br></br>Hardware</td>
            <td className='justificartexto'>1. Soluciones rápidas</td>
            <td className='estrategias'>
                <ul>
                { 
            estrategias.map(p =>(
                <div>
                {(p.cuestionario.Variable === "Diseño estratégico con enfoque de género" && p.estrategias.length  !== 0) &&
                <div>
                {p.estrategias.map(b =>(
                 <div>
                    {(b.marcado === "1") &&
                        <li>{b.Enunciado? b.Enunciado : ""}</li>
                    }
                </div>
                ))}  
                </div>
                }
                </div>
            ))  
            }
                </ul>

                <ul>
                { 
            estrategiasNO.map(p =>(
                <div>
                {(p.cuestionario.Variable === "Diseño estratégico con enfoque de género" && p.estrategias.length  !== 0) &&
                <div>
                {p.estrategias.map(b =>(
                 <div>
                     {(b.marcado === "0" && (estrategiasDIF.includes( parseInt(b.NumRespuesta,10)))) &&
                        <li>{b.Enunciado? b.Enunciado : ""}</li>
                    }
                </div>
                ))}  
                </div>
                }
                </div>
            ))  
            }
                </ul>
                <ul>
                {contGenero}
                </ul>
            </td>
        </tr>
        <tr>
            <td className='justificartexto'>2. Soluciones moderadas</td>
            <td className='estrategias'>
                <ul>
                { 
            estrategias.map(p =>(
                <div>
                {(p.cuestionario.Variable === "Participación de mujeres" && p.estrategias.length  !== 0) &&
                <div>
                {p.estrategias.map(b =>(
                    <div>
                    {(b.marcado === "1") &&
                        <li>{b.Enunciado? b.Enunciado : ""}</li>
                    }
                    </div>
                ))}  
                </div>
                }
                </div>
            ))  
             }
                </ul>
                <ul>
                { 
            estrategiasNO.map(p =>(
                <div>
                {(p.cuestionario.Variable === "Participación de mujeres" && p.estrategias.length  !== 0) &&
                <div>
                {p.estrategias.map(b =>(
                    <div>
                   {(b.marcado === "0" && (estrategiasDIF.includes( parseInt(b.NumRespuesta,10)))) &&
                        <li>{b.Enunciado? b.Enunciado : ""}</li>
                    }
                    </div>
                ))}  
                </div>
                }
                </div>
            ))  
             }
                </ul>
                <ul>
                {contMujeres}
                </ul>
            </td>
        </tr>
        <tr>
           
           <td rowSpan="2"><img className='imagenreporte'src={imagen2} alt="imagen"></img><br></br>Software</td>
           <td className='justificartexto'>3. Soluciones complejas (requieren estrategia)</td>
           <td className='estrategias'>
               <ul>
               { 
            estrategias.map(p =>(
                <div>
                {(p.cuestionario.Variable === "Diseño estratégico con enfoque de poblaciones diversas" && p.estrategias.length  !== 0) &&
                <div>
                {p.estrategias.map(b =>(
                    <div>
                        {(b.marcado === "1") &&
                        <li>{b.Enunciado? b.Enunciado : ""}</li>
                        }
                    </div>
                ))}  
                </div>
                }
                </div>
            ))  
             }
               </ul>
               <ul>
               { 
            estrategiasNO.map(p =>(
                <div>
                {(p.cuestionario.Variable === "Diseño estratégico con enfoque de poblaciones diversas" && p.estrategias.length  !== 0) &&
                <div>
                {p.estrategias.map(b =>(
                    <div>
                        {(b.marcado === "0" && (estrategiasDIF.includes( parseInt(b.NumRespuesta,10)))) &&
                        <li>{b.Enunciado? b.Enunciado : ""}</li>
                        }
                    </div>
                ))}  
                </div>
                }
                </div>
            ))  
             }
               </ul>
               <ul>
                {contDiverso}
                </ul>
           </td>
       </tr>
       <tr>
           <td className='justificartexto'>4. Soluciones de tiempo indefinido</td>
           <td className='estrategias'>
               <ul>
               { 
            estrategias.map(p =>(
                <div>
                {(p.cuestionario.Variable === "Participaciones de poblaciones diversas" && p.estrategias.length  !== 0) &&
                <div>
                {p.estrategias.map(b =>(
                    <div>
                        {(b.marcado === "1") &&
                            <li>{b.Enunciado? b.Enunciado : ""}</li>
                        }
                    </div>
                ))}  
                </div>
                }
                </div>
            ))  
             }
               </ul>
               <ul>
               { 
            estrategiasNO.map(p =>(
                <div>
                {(p.cuestionario.Variable === "Participaciones de poblaciones diversas" && p.estrategias.length  !== 0) &&
                <div>
                {p.estrategias.map(b =>(
                    <div>
                       {(b.marcado === "0" && (estrategiasDIF.includes( parseInt(b.NumRespuesta,10)))) &&
                            <li>{b.Enunciado? b.Enunciado : ""}</li>
                        }
                    </div>
                ))}  
                </div>
                }
                </div>
            ))  
             }
               </ul>
               <ul>
                {contDiversas}
                </ul>
           </td>
       </tr>
        
    </tbody>
</table>
</div>
  </div>
);
}

export default TablareporteB
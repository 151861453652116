import React from 'react';
import {dataformat} from "../../BaseDatos.js";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import grafico from './grafico3.png';


var datos = dataformat[0]

if (typeof localStorage?.getItem('DatoSocial') === "string") {
  const datosTemp = JSON.parse(localStorage.getItem('DatoSocial'));
    if (datosTemp.length > 1){
    datos = datosTemp[2]
}
  } 

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);


export const options = {
  animation: {
         duration: 500,
  },
  maintainAspectRatio: false,
    plugins: {
      datalabels: {
        display: true,
        color: 'white',
        anchor: 'center',
        align: 'start',
        offset: -5,
        formatter: function(value, index, values) {
          if(value >0 ){
            value = value.toString();
            value = value.split(/(?=(?:...)*$)/);
            value = value.join(',');
            return value+'%';
        }else{
            value = "";
            return value;
        }
        },
        font: {
          size: 10,
        }
     },
      title: {
        display: false,
        text: 'Capacitacion en temas de Género',
      },
      legend: {
        position: 'bottom',
        labels: {
          boxWidth: 5,
          boxHeight: 5,
          padding: 1,
          font: {
            size: 8
        }
        }
    }
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
        ticks: {
          font: {
            size: 8,
          }
        },
      },
      y: {
      stacked: true,
      display: false
    },
  },
};


function sumarDesconocido(PGraficoMic, PGraficoPeq, PGraficoMed1, PGraficoMed2, 
  PGraficoNueva, PGraficoConsolidacion,PGraficoConsolidada,
  PGraficoAgro,PGraficoIndus,PGraficoServi,PGraficoComer,PGraficoConstruc,
  PGraficoHombre,PGraficoMujer,
  PGraficoSiPertenece,PGraficoNoPertenece){

    var Tamaño = []
    var Años = []
    var Sector = []
    var Genero = []
    var Pertenencia = []  

  for (let i = 0; i < PGraficoMic.length; i++) {

  var sumaTamaño = 100 - (PGraficoMic[i] + PGraficoPeq[i] + PGraficoMed1[i] + PGraficoMed2[i])
  var sumaAños = 100 - (PGraficoNueva[i] + PGraficoConsolidacion[i] + PGraficoConsolidada[i])
  var sumaSector = 100 - (PGraficoAgro[i] + PGraficoIndus[i] + PGraficoServi[i] + PGraficoComer[i] + PGraficoConstruc[i])
  var sumaGenero = 100 - (PGraficoHombre[i] + PGraficoMujer[i])
  var sumaPertenencia = 100 - (PGraficoSiPertenece[i] + PGraficoNoPertenece[i])

  // SI UNO SALE NEGATIVO POR ALGUN ERROR PONER POSITIVO POR EL MOMENTO PARA QUE NO SE CAIGA
 

  if (sumaTamaño !== 100) {
  if (sumaTamaño >= 0) {
    Tamaño.push(sumaTamaño)
  } else {
    Tamaño.push(sumaTamaño*-1)
  }
} else {
  Tamaño.push(0)
}
    
if (sumaAños !== 100) {
  if (sumaAños >= 0) {
    Años.push(sumaAños)
  } else {
    Años.push(sumaAños*-1)
  }
} else {
  Años.push(0)
}

if (sumaSector !== 100) {
  if (sumaSector >= 0) {
    Sector.push(sumaSector)
  } else {
    Sector.push(sumaSector*-1)
  }
} else {
  Sector.push(0)
}

if (sumaGenero !== 100) {
  if (sumaGenero >= 0) {
    Genero.push(sumaGenero)
  } else {
    Genero.push(sumaGenero*-1)
  }
} else {
  Genero.push(0)
}

if (sumaPertenencia !== 100) {
  if (sumaPertenencia >= 0) {
    Pertenencia.push(sumaPertenencia)
  } else {
    Pertenencia.push(sumaPertenencia*-1)
  }
} else {
  Pertenencia.push(0)
}

  }

  var indefinidos = []
  indefinidos.push(Tamaño)
  indefinidos.push(Años)
  indefinidos.push(Sector)
  indefinidos.push(Genero)
  indefinidos.push(Pertenencia)
  return indefinidos

}
  



  
  function BarrasAgrupadas3({segregacion, arrayS =dataformat[0]}) {

    var prom =arrayS.PGraficoPorcentajeRespuesta;
  const labels = [['Especia','lizaciones', prom[0]+'%'],['Cursos','o','talleres', prom[1]+'%'],['Otros', prom[2]+'%'],['No', prom[3]+'%']]
 


  var indefinidos = sumarDesconocido(
  arrayS.PGraficoMic, arrayS.PGraficoPeq, arrayS.PGraficoMed1, arrayS.PGraficoMed2, 
  arrayS.PGraficoNueva, arrayS.PGraficoConsolidacion, arrayS.PGraficoConsolidada,
  arrayS.PGraficoAgro, arrayS.PGraficoIndus, arrayS.PGraficoServi, arrayS.PGraficoComer, arrayS.PGraficoConstruc,
  arrayS.PGraficoHombre, arrayS.PGraficoMujer,
  arrayS.PGraficoSiPertenece, arrayS.PGraficoNoPertenece)

  var colorIndefinido = "rgb(118, 118, 118)"

var rojo  = "rgb(245, 168, 130)"
var rojo1 = "rgb(208, 113, 88)"
var rojo2 = "rgb(186, 62, 30)"
var rojo3 = "rgb(144, 38, 23)"
var rojo4 = "rgb(113, 11, 42)"
  
const data = {
  labels,
  datasets: [
    {
      label: 'Micro',
      data: arrayS.PGraficoMic,
      backgroundColor: rojo,
    },
    {
      label: 'Pequeña',
      data:  arrayS.PGraficoPeq,
      backgroundColor:  rojo1,
    },
    {
      label: 'Mediana-1',
      data: arrayS.PGraficoMed1,
      backgroundColor: rojo2,
    },
    {
      label: 'Mediana-2',
      data: arrayS.PGraficoMed2,
      backgroundColor: rojo3,
    },
    /*  {
        label: 'No_precisa',
        data: indefinidos[1],
        backgroundColor: colorIndefinido,
      }, */
  ],
};

 const data1 = {
  labels,
  datasets: [
    {
      label: 'Nuevas',
      data:  arrayS.PGraficoNueva,
      backgroundColor: rojo,
    },
    {
      label: 'En Consolidación',
      data: arrayS.PGraficoConsolidacion,
      backgroundColor: rojo2,
    },
    {
      label: 'Consolidadas',
      data: arrayS.PGraficoConsolidada,
      backgroundColor: rojo3,
    },
    /*  {
        label: 'No_precisa',
        data: indefinidos[1],
        backgroundColor: colorIndefinido,
      }, */
  
  ],
};

 const data2 = {
  labels,
  datasets: [
    {
      label: 'Agropecuario',
      data: arrayS.PGraficoAgro,
      backgroundColor: rojo,
    },
    {
      label: 'Industrial',
      data: arrayS.PGraficoIndus,
      backgroundColor: rojo1,
    },
    {
      label: 'Servicio',
      data: arrayS.PGraficoServi,
      backgroundColor: rojo2,
    },
    {
      label: 'Comercio',
      data: arrayS.PGraficoComer,
      backgroundColor: rojo3,
    },
    {
      label: 'Construcción',
      data: arrayS.PGraficoConstruc,
      backgroundColor: rojo4,
    },
    /*  {
        label: 'No_precisa',
        data: indefinidos[2],
        backgroundColor: colorIndefinido,
      }, */
  ],
};

 const data3 = {
  labels,
  datasets: [
    {
      label: 'Hombre',
      data: arrayS.PGraficoHombre,
      backgroundColor: rojo,
    },
    {
      label: 'Mujer',
      data: arrayS.PGraficoMujer,
      backgroundColor: rojo1,
    },
    /*  {
        label: 'No_precisa',
        data: indefinidos[3],
        backgroundColor: colorIndefinido,
      }, */
  ],
};

 const data4 = {
  labels,
  datasets: [
    {
      label: 'Sí',
      data: arrayS.PGraficoSiPertenece,
      backgroundColor: rojo,
    },
    {
      label: 'No',
      data: arrayS.PGraficoNoPertenece,
      backgroundColor: rojo1,
    },
    /*  {
        label: 'No_precisa',
        data: indefinidos[4],
        backgroundColor: colorIndefinido,
      }, */

  ],
};

 return( 
    <div>
    {segregacion === 0 &&
    <div>
    <img  alt="imagen" className='imagengraficos abajo'src={grafico}></img>
    <div className='titulograficos'>
    <div className='numpregraficos'>P.23</div>
    <div className='colornegro'> Capacitacion en Temas de Género</div>
    <div>{arrayS.Noporcentaje}%
    <div>Especializaciones</div>
    </div>
    </div>
    <div className='subtitulograficos'>
     <ul className='Soc'>
      <li>Mic. = {arrayS.MicPorcentaje}% </li>
      <li>Peq. = {arrayS.PeqPorcentaje}%</li>
      <li>Med1. = {arrayS.Med1Porcentaje}%</li>
      <li>Med2. = {arrayS.Med2Porcentaje}%</li>
    </ul>
  </div>
    <div className="socialfgrafic">
      
     <Bar data={data} plugins={[ChartDataLabels]} options={options}/>
    </div>
    </div>
    }
    {segregacion === 1 &&
    <div>
    <img  alt="imagen" className='imagengraficos abajo'src={grafico}></img>
    <div className='titulograficos'>
    <div className='numpregraficos'>P.23</div>
    <div className='colornegro'> Capacitacion en Temas de Género</div>
    <div>{arrayS.Noporcentaje}%
    <div>Especializaciones</div>
    </div>
    </div>
    <div className='subtitulograficos'>
     <ul className='Soc'>
      <li>Nueva = {arrayS.NuevaPorcentaje}%</li>
      <li>En Consolidación= {arrayS.ConsolidacionPorcentaje}%</li>
      <li>Consolidada = {arrayS.ConsolidadasPorcentaje}%</li>
    </ul>
  </div>
    <div className="socialfgrafic">
      
     <Bar data={data1} plugins={[ChartDataLabels]} options={options}/>
    </div>
    </div>
    }
        {segregacion === 2 &&
    <div>
    <img  alt="imagen" className='imagengraficos abajo'src={grafico}></img>
    <div className='titulograficos'>
    <div className='numpregraficos'>P.23</div>
    <div className='colornegro'> Capacitacion en Temas de Género</div>
    <div>{arrayS.Noporcentaje}%
    <div>Especializaciones</div>
    </div>
    </div>
    <div className='subtitulograficos'>
     <ul className='Soc'>
    <li>Agr. = {arrayS.AgropecuarioPorcentaje}%</li>
      <li>Ind. = {arrayS.IndustriaPorcentaje}%</li>
      <li>Ser. = {arrayS.ServiciosPorcentaje}%</li>
      <li>Com. = {arrayS.ComercioPorcentaje}%</li>
      <li>Con. = {arrayS.ConstrucciónPorcentaje}%</li>
    </ul>
  </div>
    <div className="socialfgrafic">
      
     <Bar data={data2} plugins={[ChartDataLabels]} options={options}/>
    </div>
    </div>
    }
    {segregacion === 3 &&
    <div>
    <img  alt="imagen" className='imagengraficos abajo'src={grafico}></img>
    <div className='titulograficos'>
    <div className='numpregraficos'>P.23</div>
    <div className='colornegro'> Capacitacion en Temas de Género</div>
    <div>{arrayS.Noporcentaje}%
    <div>Especializaciones</div>
    </div>
    </div>
    <div className='subtitulograficos'>
     <ul className='Soc'>
     <li>H. = {arrayS.MasculinoPorcentaje}%</li>
     <li>M. = {arrayS.FemeninoPorcentaje}% </li>
    </ul>
  </div>
    <div className="socialfgrafic">
      
     <Bar data={data3} plugins={[ChartDataLabels]} options={options}/>
    </div>
    </div>
    }
        {segregacion === 4 &&
    <div>
    <img  alt="imagen" className='imagengraficos'src={grafico}></img>
    <div className='titulograficos'>
    <div className='numpregraficos'>P.23</div>
    <div className='colornegro'> Capacitacion en Temas de Género</div>
    <div>{arrayS.Noporcentaje}%
    <div>Especializaciones</div>
    </div>
    </div>
    <div className='subtitulograficos'>
     <ul className='Soc'>
     <li>Sí. = {arrayS.PertenecePorcentaje}%  </li>
      <li>No. = {arrayS.NoPertenecePorcentaje}% </li>
    </ul>
  </div>
    <div className="socialfgrafic">
      
     <Bar data={data4} plugins={[ChartDataLabels]} options={options}/>
    </div>
    </div>
    }
    </div>
  );
  }

  export default BarrasAgrupadas3
import './cabecera.css';
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {NombreUsuario}  from "./cabecera.tsx"
import { LazyLoadImage } from "react-lazy-load-image-component";

import logo2 from './Cosmo.png';

const adeneu = 'cuadroanimado/adeneufondo.jpg';

function Cabecerapagina({user, setUser}) {

  const [isDesktop, setDesktop] = useState(window.innerWidth > 850);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 850);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

    return (
      <div className="cabecera">
        { !(user.NumTipoUsuario === 10) &&
        <div  className='cabeceraLogo'>
        <Link to='/autodiagnostico'><LazyLoadImage className='imagencabecera' src={logo2} alt="logo empresa"></LazyLoadImage></Link>
          <nav className='linkcabeceras'>
          <Link to='/Preguntas'>Nosotros</Link>
          <Link to='/InicioAutodiagnosticoPRE'>Autodiagnóstico</Link>
          <Link to='/Resultado'>Resultado</Link>

          {/* <Link to='/Planes'>Planes de acción</Link> */}
          
          {/* NO logeado */}
          {(user.IDperfil === null && (isDesktop)) &&
           <Link to='/Loginusuario'>Ingresar</Link>
          }
          {(user.IDperfil === null && (isDesktop)) &&
          <Link to='/Registrousuario'>Registrarse</Link>
          }
          
          </nav>
          { (!(isDesktop) && user.IDperfil === null) &&
          <nav className='linkcabeceras'>
          <Link to='/Loginusuario'>Ingresar</Link>
          <Link to='/Registrousuario'>Registrarse</Link>
          </nav>
          }

          {/* SI logeado */}

          
          {user.IDperfil !== null &&
          <NombreUsuario
          user={user}
          setUser={setUser}
          />
          }
        </div>
        }
      </div>
      );
  }

export default Cabecerapagina
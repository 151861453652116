import React from 'react';
import grafico from './brain_SINFONDO.png';



function BarrasAgrupadasB({socialP1}) {
 return( 
  <div className= "AdopcionsquarePLAN">
  <img  alt="imagen" className='imagengraficosADOPESTRA abajo'src={grafico}></img>
  <div className='titulograficosADOP'>
    <div className='numpregraficosADOP'>P.01</div>
   <div className='colornegro'>Total Criterio Integración</div>
  <div>{socialP1}
  </div>
  </div>
  </div>
);
}

export default BarrasAgrupadasB
import React, { useState, useEffect } from "react";
import './App.css';
import { ProtectedRoute, ProtectedNoLogeo } from "./ProtectedRouteAdmin";

import Cabecerapagina from './cabecera/cabecera'

import Cuerpoindex from './presentacion/cuerpoindex/cuerpoindex';
import ContraCambiada from './presentacion/ContraCambiada.js';
import CuerpoASG from './informacion/cuerpoASG/cuerpoASG';
import Perfilusuario from './informacion/perfil.js'
import Barravertical from './barravertical/barravertical.js';
import PreguntasFrecuentes from './informacion/preguntasFrecuentes/PreguntasFrecuentes';
import Autodiagnostico from './cuestionario/cards/autopreguntas';

import FinalAutodiag from './cuestionario/finalAutodiagnostico/finalAutodiagnostico'
import InicioAutodiag from './cuestionario/inicioAutodiagnostico/inicioAutodiagnostico'
import InicioAutodiagPRE from './cuestionario/inicioAutodiagnostico/inicioAutodiagnosticoPRE'
import CambiopreCUIT from './cuestionario/inicioAutodiagnostico/cambiopreCUIT'

import Logeo from './logeo/login/login.js'
import RedireccionLogeo from './logeo/redireccionLogeo'
import RedireccionCor from "./logeo/redirrecionCorreo";
import RecuperacionContra from "./logeo/recuperacion/RecuperacionContra.js"

import CorreoEnvio from './logeo/correoenvio'
import Registro from './logeo/registro/registro.js'
import VerficarCorreo from './logeo/registro/verificarCorreo'

import Mipymeparticipante from './reportes/mipymeparticipante/mipymeparticipante.js';
import Mipymeadopcion from './reportes/mipymeadopcion/mipymeadopcion.js';
import Mipymesocial from './reportes/mipymesocial/mipymesocial.js';
import {Mipymeambiental} from './reportes/mipymeambiental/mipymeambiental.js';
import Mipymegobernanza from './reportes/mipymegobernanza/mipymegobernanza.js';
import MipymeTodos from './reportes/mipymereportestodos/reportetodos.js';

import Reporteautodiagnostico from './reportes/reporteautodiagnostico/reporteautodiagnostico.js';
import ReporteplanAccion from './reportes/reporteplanestrategia/reporteplanestrategia.js';

import Inicio from './pruebas/cargarprueba';

import RedireccionLogeoPost from './logeo/redireccionLogeoPostRegistro';

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
   }
  from "react-router-dom";


function App() {
//homepage
  //https://talleres.aria12.com/autodiagnostico/
  //https://talleres.aria12.com/
  //https://talleres.aria12.com/autodiagnostico


  /*PERFIL*/
  const [user, setUser] = useState({
    IDperfil: null,
    CUIT: null,
    Nombre: "prueba",
    NumTipoUsuario: 0,
    Correo: null,
    CorreoVerificado: 0
  })

  /*INFORME*/
  const [informe, setInforme] = useState({
    NumInforme: null,
    CUIT: null,
    fecha_creacion: null,
    fecha_modificacion: null,
    RazonSocial: null
  })


  /*RESULTADO talvez lo borre*/
  const [resultado, setResultado] = useState({
    NumInforme: null,
    RespuestaSeleccionada: null,
    /*
    PuntajeObtenido: null,
    especificacion: null,
    EstrategiaAPROB: null,
    */
  })

  /*TOKEN*/
  const [token,setToken] = useState()

  /*Perfil para registrar*/
  const [perfilR, setPerfilR] = useState({});

  /* LOCAL STORAGE CARGAR EL USUARIO Y EL TOKEN DE LA SESION ANTERIOR 
    VERIFIICAR E NREGISTRO TSX FUNCION LoginModal que no este mal
    LUEGO NECESITO VER COMO ACTUALIZAR CON LA URL DE REDIRECCION EL CORREO
  
  const queries = useQueryParams();
  console.log("Los queries: "+queries)
*/
  useEffect(() => {
    const usuario = JSON.parse(localStorage.getItem('userLocal'));
    const elToken = localStorage.getItem('tokenLocal');
    if (usuario) {
     
     console.log("RECUPERANDO: "+ JSON.stringify(usuario) +"EL_TOKEN: "+ JSON.stringify(elToken))
     setUser(usuario)
     setToken(elToken)

    } else {
      console.log("No existe usuario")
      console.log("usuario: "+usuario +"token: "+elToken)
    }
  }, []);


  return (
    <Router>
    <div className="App">
    <header>

      {(user.NumTipoUsuario === 2 || user.NumTipoUsuario === 3) &&
        <div>
         <Barravertical
         user={user}
         setUser={setUser}
         ></Barravertical>
        </div>
        }
        
        {(user.NumTipoUsuario === 0 || user.NumTipoUsuario === 1) &&
        <div>
         <Cabecerapagina
         user={user}
         setUser={setUser}
         ></Cabecerapagina>
        </div>
        }

    </header>
    <Routes>
      <Route  path="/autodiagnostico" element={<Presentacion
      user = {user}
      setUser = {setUser}
      />}/>
      <Route  path="/ContraCambiada" element={<ContraC/>}/>
      <Route  path="/Preguntas" element={<Preguntas/>}/>
      <Route  path="/Planes" element={<Planes/>}/>
      <Route  path="/RedirrecionLogeo" element={<RedireccionLog
      user = {user}
      />}/>
      <Route  path="/RedirrecionCorreo" element={<RedireccionCorreo/>}/>
      <Route  path="/RecuperacionContra" element={<Recuperacion/>}/>


      {/* crear una ruta protegida exclusivo para este y PERFIl */}
      <Route  path="/VerificarCorreo" element={<VerificarCorreo
      setToken={setToken}
      perfilR={perfilR}
      setUser={setUser}
      />}/>

      <Route  path="/CorreoEnvio" element={<CorreoEnvi
      user = {user}
      token = {token}
      />}/>
      

      <Route  path="/Loginusuario" element={<Loginusuario
       user={user}
       setUser={setUser}
       setToken = {setToken}
       ></Loginusuario>}/>

      <Route  path="/Registrousuario" element={<Registrousuario
      perfilR={perfilR}
      setPerfilR={setPerfilR}
      setUser={setUser}
      setToken = {setToken}
      />}/>

      <Route  path="/perfil" element={<PerfilUser
      user={user}
      />}/>
      
      <Route  path="/RedireccionLogeoPost" element={<RedireccionLogeoPostBORRAR/>}/>

      {/*NO LOGEADO*/}
      <Route element={<ProtectedNoLogeo user={user}></ProtectedNoLogeo>}>

      <Route  path="/CambiopreCUIT" element={<HechoCambiopreCUIT
      user={user}
      />}/>


      

      <Route  path="/InicioAutodiagnosticoPRE" element={<InicioAutodiagnosticoPRE
      />}/>

      <Route  path="/InicioAutodiagnostico" element={<InicioAutodiagnostico
      user={user}
      setUser={setUser}
      token = {token}
      />}/>
      
      <Route  path="/PregAutodiagnostico" element={<Autodiagnosticopreg
      resultado={resultado}
      setResultado={setResultado}
      user={user}
      informe = {informe}
      setInforme = {setInforme}
      ></Autodiagnosticopreg> }/>
      
      
      <Route  path="/FinalAutodiagnostico" element={
      <FinalAutodiagnostico
      resultado = {resultado}
      setResultado = {setResultado}
      informe = {informe}
      setInforme = {setInforme}
      ></FinalAutodiagnostico>}/>
      
      <Route  path="/Resultado" element={<Resultado
      token = {token}
      user = {user}
      setInforme = {setInforme}
      />}/>
      <Route  path="/Perfil" element={<Perfil/>}/>
      </Route>

      {/*SOLO PARA EL ADMIN*/}
      <Route element={<ProtectedRoute user={user}></ProtectedRoute>}>
        
        <Route  path="/ReporteParticipantes" element={<ReporteParticipantes
         user = {user}
         setUser = {setUser}
        />}/>
        
        <Route  path="/ReporteAdopcion" element={<ReporteAdopcion
         user = {user}
         setUser = {setUser}
        />}/>
        
        <Route  path="/ReporteAmbiental" element={<ReporteAmbiental
         user = {user}
         setUser = {setUser}
        />}/>
        
        <Route  path="/ReporteGobernanza" element={<ReporteGobernanza
         user = {user}
         setUser = {setUser}
        />}/>
        
        <Route  path="/ReporteSocial" element={<ReporteSocial
         user = {user}
         setUser = {setUser}
        />}/>
        
        <Route  path="/ReportesTodos" element={<ReporteTodo
         user = {user}
         setUser = {setUser}
        />}/>

      </Route>
      
      <Route  path="/ReporteAutodiagnostico" element={<ReporteAutodiagnostico
      informe = {informe}
      user = {user}
      setUser = {setUser}
      />}/>
      <Route  path="/ReportePlanesAccion" element={<ReportePlanesAccion
       informe = {informe}
       user = {user}
       setUser = {setUser}
      />}/>
      <Route  path="/Prueba" element={<Prueba/>}/>


    </Routes>
    <div>
      {/*<Pieindex/>*/}
    </div>

    </div>
    </Router>
  );
}

function Presentacion({user, setUser}) {
  return(
  <div className='cuerpo'>
    <div>
      <Cuerpoindex
      user = {user}
      setUser = {setUser}
      />
    </div>
  </div>
  );
}

function ContraC() {
  return(
    <div>
      <ContraCambiada/>
    </div>
  );
}



function Autodiagnosticopreg({resultado, setResultado, user, informe, setInforme}) {

  return (
    <div>
      <Autodiagnostico
      resultado = {resultado}
      setResultado = {setResultado}
      user = {user} //necesito el CUIT
      informe = {informe}
      setInforme = {setInforme}
      />
    </div>
    );
}

function FinalAutodiagnostico({resultado, setResultado, informe, setInforme }) {
  return(
  <div>
    <FinalAutodiag
    resultado = {resultado}
    setResultado = {setResultado}
    informe = {informe}
    setInforme = {setInforme}
    />
  </div>
  );
}

function Resultado({token, user, setInforme}) {
  return(
  <div>
    <CuerpoASG
    token = {token}
    user = {user}
    setInforme = {setInforme}
    //talvez tenga que agregar setinforme aqui
    />
  </div>
  );
}



function Planes() {
  return(
  <div>
  </div>
  );
}

function RedireccionLog({user}) {
  return(
  <div>
    <RedireccionLogeo
    user = {user}
    />
  </div>
  );
}

function RedireccionCorreo() {
  return(
  <div>
    <RedireccionCor/>
  </div>
  );
}

function Recuperacion() {
  return(
  <div>
    <RecuperacionContra/>
  </div>
  );
}

function CorreoEnvi({user, token}) {
  return(
  <div>
    <CorreoEnvio
     user = {user}
     token = {token}
    />
  </div>
  );
}

function VerificarCorreo({perfilR, setUser, setToken}) {
  return(
  <div>
    <VerficarCorreo
    setToken={setToken}
    perfilR={perfilR}
    setUser={setUser}
    />
  </div>
  );
}

function Preguntas() {
  return(
  <div>
    <PreguntasFrecuentes></PreguntasFrecuentes>
  </div>
  );
}



function Perfil() {
  return(
  <div>
  </div>
  );
}

/// REPORTES PARA ADMIN
function ReporteParticipantes({user, setUser}) {
  return(
  <div>
    <Mipymeparticipante
    user = {user}
    setUser = {setUser}
    ></Mipymeparticipante>
  </div>
  );
}

function ReporteAdopcion({user, setUser}) {
  return(
  <div>
    <Mipymeadopcion
    user = {user}
    setUser = {setUser}
    ></Mipymeadopcion>
  </div>
  );
}

function ReporteAmbiental({user, setUser}) {
  return(
  <div>
    <Mipymeambiental
    user = {user}
    setUser = {setUser}
    ></Mipymeambiental>
  </div>
  );
}

function ReporteGobernanza({user, setUser}) {
  return(
  <div>
    <Mipymegobernanza
    user = {user}
    setUser = {setUser}
    ></Mipymegobernanza>
  </div>
  );
}

function ReporteSocial({user, setUser}) {
  return(
  <div>
    <Mipymesocial
    user = {user}
    setUser = {setUser}
    ></Mipymesocial>
  </div>
  );
}

function ReporteTodo({user, setUser}) {
  return(
  <div>
    <MipymeTodos
      user = {user}
      setUser = {setUser}
    ></MipymeTodos>
  </div>
  );
}

//////

function ReporteAutodiagnostico({informe, user, setUser}) {
  return(
  <div>
    <Reporteautodiagnostico
    informe = {informe}
    user = {user}
    setUser = {setUser}
    ></Reporteautodiagnostico>
  </div>
  );
}

function ReportePlanesAccion({informe,user, setUser}) {
  return(
  <div>
   <ReporteplanAccion
    informe = {informe}
    user = {user}
    setUser = {setUser}
   ></ReporteplanAccion>
  </div>
  );
}

function HechoCambiopreCUIT({user}) {
  return(
  <div>
    <CambiopreCUIT
    user = {user}
    ></CambiopreCUIT>
  </div>
  );
}

function PerfilUser({user}) {
  return(
  <div>
    <Perfilusuario
    user = {user}
    ></Perfilusuario>
  </div>
  );
}

function RedireccionLogeoPostBORRAR() {
  return(
  <div>
    <RedireccionLogeoPost></RedireccionLogeoPost>
  </div>
  );
}

function InicioAutodiagnosticoPRE() {
  return(
  <div>
    <InicioAutodiagPRE
    ></InicioAutodiagPRE>
  </div>
  );
}

function InicioAutodiagnostico({user, setUser, token}) {
  return(
  <div>
    <InicioAutodiag
    user = {user}
    setUser = {setUser}
    token = {token}
    ></InicioAutodiag>
  </div>
  );
}


function Prueba() {
  return(
  <div>
    <Inicio></Inicio>
  </div>
  );
}


function Loginusuario({user, setUser, setToken}) {
  return(
  <div>
    <Logeo
    user={user}
    setUser={setUser}
    setToken = {setToken}
    ></Logeo>
  </div>
  );
}

function Registrousuario({ perfilR, setPerfilR, setUser, setToken}) {
  return(
  <div>
    <Registro
      perfilR={perfilR}
      setPerfilR={setPerfilR}
      setUser={setUser}
      setToken={setToken}
    ></Registro>
  </div>
  );
}

export default App;

import {VolverAutodiagnostico} from "./inicioAutodiagnostico.tsx"
import React, { useEffect } from "react";

function CambiopreCUIT({user}) {

    useEffect(() => {
    localStorage.setItem('userLocal', JSON.stringify(user));
    }, []);

    return (
        <div className="container__finalauto">
            <div className="presentacionfinalINFO">
            <h1>Nuevo RUC asociado</h1>
            </div>

            <div className="SubtituloINFO">
            El precuit es: {user.preCUIT}
            </div>

            <div className="CuerpoINFO">
                Si usted cree que los datos de la CUIT no corresponden con su empresa, comuniquese con un miembro del equipo, para actualizarlo.
                <VolverAutodiagnostico
                user={user}
                />
            </div>

            
        </div>
      );
  }

export default CambiopreCUIT
import React from 'react';
import grafico from './Participantes2.png';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  
  animation: {
         duration: 500,
  },
  maintainAspectRatio: false,
  indexAxis: 'y',
  elements: {
    bar: {
      borderWidth: 0,
    },
  },
  responsive: true,
  plugins: {
    datalabels: {
      display: true,
      color: 'white',
   },
    title: {
      display: false,
      text: 'Chart.js Horizontal Bar Chart',
    },
    legend: {
      position: 'left',
      labels: {
        boxWidth: 5,
        boxHeight: 5,
        padding: 1,
          font: {
            size: 8
        }
      }
  }
  },
};


var naranja  = "rgb(249, 156, 139)"
var naranja1 = "rgb(236, 121, 73)"
var naranja2 = "rgb(205, 103, 21)"
var naranja3 = "rgb(182, 113, 18)"


  
  function BarrasAgrupadas2({segregacion, arrayP}) {


  const labels = [''];

 const data = {
  labels,
  datasets: [
    {
      label: 'Mediana-2',
      data: [arrayP[4]],
      borderColor: naranja3,
      backgroundColor: naranja3,
    },
    {
      label: 'Mediana-1',
      data: [arrayP[3]],
      borderColor: naranja2,
      backgroundColor: naranja2,
    },
    {
      label: 'Pequeña',
      data: [arrayP[2]],
      borderColor: naranja1,
      backgroundColor: naranja1,
    },
    {
      label: 'Micro',
      data: [arrayP[1]],
      borderColor: naranja,
      backgroundColor: naranja,
    },
  
  ],
};


   return(
    <div>
  {segregacion === 0 &&

    <div>
  <img  alt="imagen" className='imagengraficos abajo'src={grafico}></img>
  <div className='titulograficos'>
  <div className='numpregraficos'>P.02</div>
  <div className='colornegro'>Años de operación</div>
  <div>{arrayP[0]}
  <div>Años en Promedio</div>
  </div>
  </div>
  <div className='subtitulograficos'>
    <ul className='Par'>
      <li>Mic.={arrayP[1]}</li>
      <li>Peq.={arrayP[2]}</li>
      <li>Med1.={arrayP[3]}</li>
      <li>Med2.={arrayP[4]}</li>
    </ul>
  </div>
  <div className="socialfgrafic">
    
 	<Bar data={data} plugins={[ChartDataLabels]} options={options}/>
	</div>
  </div>
    }
    </div> 
  );
  }

  export default BarrasAgrupadas2
import React from 'react';
import { useEffect, useState} from 'react'

import imagen1 from './working.png';
import imagen2 from './shield.png';




/*
<img className='imagenreporte'src={grafico} alt="imagen"></img>
*/
function TablareporteC({estrategias, fechaM, fechaC, estrategiasNO,estrategiasDIF, setGobernanzaP1, setGobernanzaP2, setGobernanzaP3, gobernanzaP1, gobernanzaP2, gobernanzaP3}) {

    const [contNivel,setContNivel] = useState("");
    const [contIncorporacion,setContIncorporacion] = useState("");
    const [contCalidad,setContCalidad] = useState("");
    const [contRelacion,setContRelacion] = useState("");

    useEffect(() => {
        var contadorNivel= 0;
        var contadorIncorporacion = 0;
        var contadorCalidad = 0;
        var contadorRelacion = 0;
    
        estrategiasNO.map(function(x) {
    
            if (x.cuestionario.Variable === "Nivel de madurez digital" && x.estrategias.length !== 0) {
                x.estrategias.map(function(b) {
                    if(b.marcado === "0" && (estrategiasDIF.includes( parseInt(b.NumRespuesta,10)))) {
                        contadorNivel++
                    }
                return contadorNivel;
                });
            }
    
            if (x.cuestionario.Variable === "Incorporación de especialistas" && x.estrategias.length !== 0) {
                x.estrategias.map(function(b) {
                    if(b.marcado === "0" && (estrategiasDIF.includes( parseInt(b.NumRespuesta,10)))) {
                        contadorIncorporacion++
                    }
                return contadorIncorporacion;
                });
            }
    
            if (x.cuestionario.Variable === "Calidad de la gestión" && x.estrategias.length !== 0) {
                x.estrategias.map(function(b) {
                    if(b.marcado === "0" && (estrategiasDIF.includes( parseInt(b.NumRespuesta,10)))) {
                        contadorCalidad++
                    }
                return contadorCalidad;
                });
            }

            if (x.cuestionario.Variable === "Relación con el entorno" && x.estrategias.length !== 0) {
                x.estrategias.map(function(b) {
                    if(b.marcado === "0" && (estrategiasDIF.includes( parseInt(b.NumRespuesta,10)))) {
                        contadorRelacion++
                    }
                return contadorRelacion;
                });
            }

            return "a";
        });

        var contadorNivelP= 0;
        var contadorIncorporacionP = 0;
        var contadorCalidadP = 0;
        var contadorRelacionP = 0;
    
        estrategias.map(function(x) {
    
            if (x.cuestionario.Variable === "Nivel de madurez digital" && x.estrategias.length !== 0) {
                x.estrategias.map(function(b) {
                    if(b.marcado === "1" ) {
                        contadorNivelP++
                    }
                return contadorNivelP;
                });
            }
    
            if (x.cuestionario.Variable === "Incorporación de especialistas" && x.estrategias.length !== 0) {
                x.estrategias.map(function(b) {
                    if(b.marcado === "1" ) {
                        contadorIncorporacionP++
                    }
                return contadorIncorporacionP;
                });
            }
    
            if (x.cuestionario.Variable === "Calidad de la gestión" && x.estrategias.length !== 0) {
                x.estrategias.map(function(b) {
                    if(b.marcado === "1" ) {
                        contadorCalidadP++
                    }
                return contadorCalidadP;
                });
            }

            if (x.cuestionario.Variable === "Relación con el entorno" && x.estrategias.length !== 0) {
                x.estrategias.map(function(b) {
                    if(b.marcado === "1" ) {
                        contadorRelacionP++
                    }
                return contadorRelacionP;
                });
            }

            return "a";
        });

        if (contadorNivel === 0 && contadorNivelP === 0 ) {
            setContNivel("Usted no registra actividades en esta sección.")
        }
    
        if (contadorIncorporacion === 0 && contadorIncorporacionP === 0 ) {
            setContIncorporacion("Usted no registra actividades en esta sección.")
        }
    
        if (contadorCalidad === 0 && contadorCalidadP === 0 ) {
            setContCalidad("Usted no registra actividades en esta sección.")
        }

        if (contadorRelacion === 0 && contadorRelacionP === 0 ) {
            setContRelacion("Usted no registra actividades en esta sección.")
        }

        console.log(contadorNivel+" g "+ contadorIncorporacion +" g  "+contadorCalidad+" g "+contadorRelacion)
        setGobernanzaP1(gobernanzaP1+contadorNivel+contadorIncorporacion+contadorCalidad+contadorRelacion);
        setGobernanzaP2(gobernanzaP2+contadorNivel+contadorIncorporacion);
        setGobernanzaP3(gobernanzaP3+contadorCalidad+contadorRelacion);

        },[])

       
 return( 
  <div >

  <div className='titulograficosREPOR'>
    <div className='numpregraficosREPOR'>P.04</div>
    <div className='colornegro'>Planes de Acción según Brechas Identificadas el {fechaC}</div>
    </div>

    <div className="divTable">
    <table className="tablareporte">
    <thead>
        <tr>
            <th>Sub dimensiones</th>
            <th>Variables</th>
            <th className='estrategiaANCHO'>Planes de Accion</th>
        </tr>
    </thead>
    <tbody>
        <tr>
           
            <td rowSpan="2"><img className='imagenreporte'src={imagen1} alt="imagen"></img><br></br>Seguridad Física</td>
            <td className='justificartexto'>1. Estrategias sencillas</td>
            <td className='estrategias'>
                <ul>
                { 
            estrategias.map(p =>(
                <div>
                {(p.cuestionario.Variable === "Nivel de madurez digital" && p.estrategias.length  !== 0) &&
                <div>
                {p.estrategias.map(b =>(
                 <div>
                    {(b.marcado === "1") &&
                        <li>{b.Enunciado? b.Enunciado : ""}</li>
                    }
                </div>
                ))}  
                </div>
                }
                </div>
            ))  
             }
                </ul>

                <ul>
                { 
            estrategiasNO.map(p =>(
                <div>
                {(p.cuestionario.Variable === "Nivel de madurez digital" && p.estrategias.length  !== 0) &&
                <div>
                {p.estrategias.map(b =>(
                 <div>
                    {(b.marcado === "0" && (estrategiasDIF.includes( parseInt(b.NumRespuesta,10)))) &&
                        <li>{b.Enunciado? b.Enunciado : ""}</li>
                    }
                </div>
                ))}  
                </div>
                }
                </div>
            ))  
             }
                </ul>

                <ul>
                {contNivel}
                </ul>

                
            </td>
        </tr>
        <tr>
            <td className='justificartexto'>2. Estrategias moderados</td>
            <td className='estrategias'>
                <ul>
                { 
            estrategias.map(p =>(
                <div>
                {(p.cuestionario.Variable === "Incorporación de especialistas" && p.estrategias.length  !== 0) &&
                <div>
                {p.estrategias.map(b =>(
                  <div>
                    {(b.marcado === "1") &&
                        <li>{b.Enunciado? b.Enunciado : ""}</li>
                    }
                </div>
                ))}  
                </div>
                }
                </div>
            ))  
             }
                </ul>

                <ul>
                { 
            estrategiasNO.map(p =>(
                <div>
                {(p.cuestionario.Variable === "Incorporación de especialistas" && p.estrategias.length  !== 0) &&
                <div>
                {p.estrategias.map(b =>(
                  <div>
                     {(b.marcado === "0" && (estrategiasDIF.includes( parseInt(b.NumRespuesta,10)))) &&
                        <li>{b.Enunciado? b.Enunciado : ""}</li>
                    }
                </div>
                ))}  
                </div>
                }
                </div>
            ))  
             }
                </ul>

                <ul>
                {contIncorporacion}
                </ul>


            </td>
        </tr>
        <tr>
           
           <td rowSpan="2"><img className='imagenreporte'src={imagen2} alt="imagen"></img><br></br>Seguridad Software</td>
           <td className='justificartexto'>3. Soluciones complejas (requieren estrategia) </td>
           <td className='estrategias'>
               <ul>
               { 
            estrategias.map(p =>(
                <div>
                {(p.cuestionario.Variable === "Calidad de la gestión" && p.estrategias.length  !== 0) &&
                <div>
                {p.estrategias.map(b =>(
                    <div>
                    {(b.marcado === "1") &&
                        <li>{b.Enunciado? b.Enunciado : ""}</li>
                    }
                    </div>
                ))}  
                </div>
                }
                </div>
            ))  
            }
               </ul>

               <ul>
               { 
            estrategiasNO.map(p =>(
                <div>
                {(p.cuestionario.Variable === "Calidad de la gestión" && p.estrategias.length  !== 0) &&
                <div>
                {p.estrategias.map(b =>(
                    <div>
                   {(b.marcado === "0" && (estrategiasDIF.includes( parseInt(b.NumRespuesta,10)))) &&
                        <li>{b.Enunciado? b.Enunciado : ""}</li>
                    }
                    </div>
                ))}  
                </div>
                }
                </div>
            ))  
            }
               </ul>
               <ul>
                {contCalidad}
                </ul>

           </td>
       </tr>
       <tr>
           <td className='justificartexto'>4. Soluciones de tiempo indefinido </td>
           <td className='estrategias'>
               <ul>
               { 
            estrategias.map(p =>(
                <div>
                {(p.cuestionario.Variable === "Relación con el entorno" && p.estrategias.length  !== 0) &&
                <div>
                {p.estrategias.map(b =>(
                  <div>
                    {(b.marcado === "1") &&
                     <li>{b.Enunciado? b.Enunciado : ""}</li>
                    }
                    </div>
                ))}  
                </div>
                }
                </div>
            ))  
              }
               </ul>

               <ul>
               { 
            estrategiasNO.map(p =>(
                <div>
                {(p.cuestionario.Variable === "Relación con el entorno" && p.estrategias.length  !== 0) &&
                <div>
                {p.estrategias.map(b =>(
                  <div>
                    {(estrategiasDIF.includes(b.NumRespuesta)) &&
                     <li>{b.Enunciado? b.Enunciado : ""}</li>
                    }
                    </div>
                ))}  
                </div>
                }
                </div>
            ))  
              }
               </ul>
               <ul>
                {contRelacion}
                </ul>

           </td>
       </tr>
        
    </tbody>
</table>
</div>
  </div>
);
}

export default TablareporteC
import React from 'react';

import {
  Chart,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import grafico from './Participantes1.png';

Chart.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  animation: {
         duration: 500,
  },
  maintainAspectRatio: false,
  plugins: {
    datalabels: {
        display: true,
        color: 'white',
        anchor: 'center',
        align: 'start',
        offset: -5,
        formatter: function(value, index, values) {
          if(value >0 ){
            value = value.toString();
            value = value.split(/(?=(?:...)*$)/);
            value = value.join(',');
            return value+'%';
        }else{
            value = "";
            return value;
        }
        },
        font: {
          size: 10,
        }
     },
    title: {
      display: false,
      text: 'Adopción del género en líderes',
    },
    legend: {
      position: 'bottom',
      labels: {
        boxWidth: 5,
        boxHeight: 5,
        padding: 1,
          font: {
            size: 8
        }
      },
  }
  },
  responsive: true,
  scales: {
    x: {
      stacked: true,
      ticks: {
        font: {
          size: 8,
        }
      },
    },
    y: {
      stacked: true,
      display: false
    },
  },
};


function sumarDesconocido(PGraficoMic, PGraficoPeq){

  console.log ("LOS ARRAYS: "+ PGraficoMic + " y "+PGraficoPeq )

  var Genero = []
  for (let i = 0; i < PGraficoMic.length; i++) {

  var sumaGenero = 100 - ( parseInt(PGraficoMic[i]) + parseInt(PGraficoPeq[i]))


if (sumaGenero !== 100) {
  if (sumaGenero >= 0) {
    Genero.push(sumaGenero)
    
  } else {
    Genero.push(sumaGenero*-1)
  }
} else {
  Genero.push(0)
}
  }

  console.log("FINAL INDEFINIDO: "+ Genero)

  return Genero
}



function BarrasAgrupadas({segregacion, arrayP}) {

  console.log("Extraigo: "+ JSON.stringify(arrayP))

  var naranja  = "rgb(249, 156, 139)"
  var naranja1 = "rgb(236, 121, 73)"
  var naranja2 = "rgb(205, 103, 21)"
  var naranja3 = "rgb(182, 113, 18)"

  var prom =[arrayP[2],arrayP[3],arrayP[4],arrayP[5]];
  const labels = [['Micro', prom[0]+'%'],['Pequeña', prom[1]+'%'],['Med1', prom[2]+'%'],['Med2', prom[3]+'%']]
  
  var array1 = []
  array1.push(arrayP[6])
  array1.push(arrayP[7])
  array1.push(arrayP[8])
  array1.push(arrayP[9])

  var array2 = []
  array2.push(arrayP[10])
  array2.push(arrayP[11])
  array2.push(arrayP[12])
  array2.push(arrayP[13])

  var array2 = [arrayP[10],arrayP[11],arrayP[12],arrayP[13]]
  var indefinidos = sumarDesconocido(array1, array2)
  
  var colorIndefinido = "rgb(118, 118, 118)"
  
  const data = {
    labels,
    datasets: [
      {
        label: 'Femenino',
        data: [arrayP[6],arrayP[7],arrayP[8],arrayP[9]],
        backgroundColor: naranja1,
      },
      {
        label: 'Masculino',
        data:  [arrayP[10],arrayP[11],arrayP[12],arrayP[13]],
        backgroundColor: naranja2,
      },
      {
        label: 'No_precisa',
        data:  indefinidos,
        backgroundColor: colorIndefinido,
      },
    ],
  };

 return(
  <div>
  {segregacion === 0 &&

  <div>
  <img alt="imagen" className='imagengraficos abajo'src={grafico}></img>
  <div className='titulograficos'>
    <div className='numpregraficos'>P.01</div>
   <div className='colornegro'>Tamaño de Empresa</div>
  <div>{arrayP[2]}%
  <div>Micro Empresa</div>
  </div>
  </div>
  <div className='subtitulograficos'>
    <ul className='Par'>
      <li>M.={arrayP[0]}%</li>
      <li>H.={arrayP[1]}%</li>
    </ul>
  </div>
 	<div className="socialfgrafic">
  
 	<Bar data={data} plugins={[ChartDataLabels]} options={options}/>
	</div>
  </div>
    }
    </div> 
);
}

export default BarrasAgrupadas
import {EscribirContra}  from "./ContraCambiada.tsx"
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";


function ContraCambiada() {
    return (
        <div className="container__finalauto">
             <Helmet>
                <meta charSet="utf-8" />
                <title>Herramienta de Autodiagnostico ASG</title>
                
                <meta name="description" content="React application" />
            </Helmet>
            {/*
            <meta http-equiv="Content-Security-Policy" content="upgrade-insecure-requests"></meta>
            */}
            <div className="presentacionfinalauto">
            <h1>Recuperacion de contraseña</h1>
            <h2>Ingrese su correo electrónico</h2>
            </div>
            <div className="Botonesfinalauto">

            <EscribirContra
            >
            </EscribirContra>

            </div>
        </div>
      );
  }

export default ContraCambiada
import './mipymegobernanza.css';
import React, { useEffect, useState } from "react";
import { BotonImprimir,Fechas, Seleccion }  from "../imprimir.tsx"
import BarrasAgrupadas from "./graficos/BarrasAgrupadas"
import BarrasAgrupadas2 from "./graficos/BarrasAgrupadas2"
import BarrasAgrupadas3 from "./graficos/BarrasAgrupadas3"
import BarrasAgrupadas4 from "./graficos/BarrasAgrupadas4"
import BarrasAgrupadas5 from "./graficos/BarrasAgrupadas5"
import BarrasAgrupadas6 from "./graficos/BarrasAgrupadas6"
import BarrasAgrupadas7 from "./graficos/BarrasAgrupadas7"
import BarrasAgrupadas8 from "./graficos/BarrasAgrupadas8"
import BarrasAgrupadas9 from "./graficos/BarrasAgrupadas9"
import BarrasAgrupadas10 from "./graficos/BarrasAgrupadas10"
import BarrasAgrupadas11 from "./graficos/BarrasAgrupadas11"
import BarrasAgrupadas12 from "./graficos/BarrasAgrupadas12"
import BarrasAgrupadas13 from "./graficos/BarrasAgrupadas13"
import BarrasAgrupadas14 from "./graficos/BarrasAgrupadas14"
import BarrasAgrupadas15 from "./graficos/BarrasAgrupadas15"
import BarrasAgrupadas16 from "./graficos/BarrasAgrupadas16"
import BarrasAgrupadas17 from "./graficos/BarrasAgrupadas17"
import BarrasAgrupadas18 from "./graficos/BarrasAgrupadas18"
import BarrasAgrupadas19 from "./graficos/BarrasAgrupadas19"
import BarrasAgrupadas20 from "./graficos/BarrasAgrupadas20"
import BarrasAgrupadas21 from "./graficos/BarrasAgrupadas21"
import BarrasAgrupadas22 from "./graficos/BarrasAgrupadas22"

import grafico1 from "./graficoGobernanza1.png"
import grafico2 from "./graficoGobernanza2.png"

function Mipymegobernanza({user,setUser}) {

    const obtenerFechaActual = () => 
    {
      var date = new Date();
      var fechaActual = date;
      var fechaA = fechaActual.toLocaleString("es-ES").substring(0,16)
      return fechaA;
    };

      /*Cambio de funciones*/
      const [segregacion, setSegregacion] = useState(0);
      const [fechaInicio, setFechaInicio] = useState("2022-01-01 00:00:00");
      const [fechaFinal, setFechaFinal] = useState("2200-01-01 00:00:00");
      const [tipo, setTipo] = useState("Gobernanza")
      const [arrayG, setArrayG] = useState([])
      const [fechaAct, setFechaAct] = useState("00/00/0000")

      useEffect(() => {
        if(typeof arrayG !== "undefined") {
        var fechaACtual = obtenerFechaActual()
        setFechaAct(fechaACtual)
        localStorage.setItem('DatoGobernanza', JSON.stringify(arrayG));}
    },[arrayG])

    return (
        <div>
        
            <div className="cuerpomreporteinicio">
                <div>
                   <div className='Azulado titulo'> Reporte  Adopción de Criterios de Gobernanza</div>
                    
                    <div className="BotonComenzar">
                        <BotonImprimir
                        user = {user}
                        setUser = {setUser}
                        fechaInicio = {fechaInicio}
                        fechaFinal = {fechaFinal}
                        tipo = {tipo}
                        setArrayR = {setArrayG}
                        segregacion={segregacion}
                        setSegregacion={setSegregacion}
                        />
                    </div>
                  
                </div>

            </div>
            <div className="insertefechas">
                <Fechas
                setFechaInicio = {setFechaInicio}
                setFechaFinal = {setFechaFinal}
                />
                <Seleccion
                segregacion={segregacion}
                setSegregacion={setSegregacion}
                ></Seleccion>
                </div>
            
            <div className="Relleno">aaaa</div>

            <div  className="graficosgobernanzaCELGRID">
                <div className="elemento1">
                <img className='imagenGRAFICO1'src={grafico1} alt="imagen numero"></img>
                <img className='imagenGRAFICO2'src={grafico2} alt="imagen logo"></img>
                Reporte de Adopción de Criterio Gobernanza
                <h4>Fecha de corte: {fechaAct}</h4>
                </div>
                    <div className="child">
                    <BarrasAgrupadas segregacion={segregacion} arrayG={arrayG[0]}/>
                    </div>
                    <div className="child">
                    <BarrasAgrupadas2 segregacion={segregacion} arrayG={arrayG[1]}/>
                    </div>
                    <div className="child">
                    <BarrasAgrupadas3 segregacion={segregacion} arrayG={arrayG[2]}/>
                    </div>
                    <div className="child">
                    <BarrasAgrupadas5 segregacion={segregacion} arrayG={arrayG[4]}/>
                    </div>
                    <div className="child">
                    <BarrasAgrupadas6 segregacion={segregacion} arrayG={arrayG[5]}/>
                    </div>
                    <div className="child">
                    <BarrasAgrupadas7 segregacion={segregacion} arrayG={arrayG[6]}/>
                    </div>
                    <div className="child">
                    
                    </div>
                    <div className="child">
                   
                    </div>
                    <div className="child">
                   
                    </div>

            </div>

            <div  className="graficosgobernanzaGRID">
                <div className="elemento1">
                <img className='imagenGRAFICO1'src={grafico1} alt="imagen numero"></img>
                <img className='imagenGRAFICO2'src={grafico2} alt="imagen logo"></img>
                Reporte de Adopción de Criterio Gobernanza
                <h4>Fecha de corte: {fechaAct}</h4>
                </div>
                    <div className="child">
                    <BarrasAgrupadas segregacion={segregacion} arrayG={arrayG[0]}/>
                    </div>
                    <div className="child">
                    <BarrasAgrupadas2 segregacion={segregacion} arrayG={arrayG[1]}/>
                    </div>
                    <div className="child">
                    <BarrasAgrupadas3 segregacion={segregacion} arrayG={arrayG[2]}/>
                    </div>
                    <div className="child">
                    <BarrasAgrupadas4 segregacion={segregacion} arrayG={arrayG[3]}/>
                    </div>
                    <div className="child">
                    <BarrasAgrupadas5 segregacion={segregacion} arrayG={arrayG[4]}/>
                    </div>
                    <div className="child">
                    <BarrasAgrupadas6 segregacion={segregacion} arrayG={arrayG[5]}/>
                    </div>
                    <div className="child">
                    <BarrasAgrupadas7 segregacion={segregacion} arrayG={arrayG[6]}/>
                    </div>
                    <div className="child">
                    <BarrasAgrupadas8 segregacion={segregacion} arrayG={arrayG[7]}/>
                    </div>
                    <div className="child">
                    <BarrasAgrupadas9 segregacion={segregacion} arrayG={arrayG[8]}/>
                    </div>

            </div>

            <div  className="graficosgobernanzaGRID">
                <div className="elemento1">
                <img className='imagenGRAFICO1'src={grafico1} alt="imagen numero"></img>
                <img className='imagenGRAFICO2'src={grafico2} alt="imagen logo"></img>
                Reporte de Adopción de Criterio Gobernanza
                <h4>Fecha de Corte {fechaAct}</h4>
                </div>
                    <div className="child">
                    <BarrasAgrupadas10 segregacion={segregacion} arrayG={arrayG[9]}/>
                    </div>
                    <div className="child">
                    <BarrasAgrupadas11 segregacion={segregacion} arrayG={arrayG[10]}/>
                    </div>
                    <div className="child">
                    <BarrasAgrupadas12 segregacion={segregacion} arrayG={arrayG[11]}/>
                    </div>
                    <div className="child">
                    <BarrasAgrupadas13 segregacion={segregacion} arrayG={arrayG[12]}/>
                    </div>
                    <div className="child">
                    <BarrasAgrupadas14 segregacion={segregacion} arrayG={arrayG[13]}/>
                    </div>
                    <div className="child">
                    <BarrasAgrupadas15 segregacion={segregacion} arrayG={arrayG[14]}/>
                    </div>
                    <div className="child">
                    <BarrasAgrupadas16 segregacion={segregacion} arrayG={arrayG[15]}/>
                    </div>
                    <div className="child">
                    <BarrasAgrupadas17 segregacion={segregacion} arrayG={arrayG[16]}/>
                    </div>
                    <div className="child">
                    <BarrasAgrupadas18 segregacion={segregacion} arrayG={arrayG[17]}/>
                    </div>

            </div>

            <div  className="graficosgobernanzaGRID">
                <div className="elemento1">
                <img className='imagenGRAFICO1'src={grafico1} alt="imagen numero"></img>
                <img className='imagenGRAFICO2'src={grafico2} alt="imagen logo"></img>
                Reporte de Adopción de Criterio Gobernanza
                <h4>Fecha de Corte {fechaAct}</h4>
                </div>
                    <div className="child">
                    <BarrasAgrupadas19 segregacion={segregacion} arrayG={arrayG[18]}/>
                    </div>
                    <div className="child">
                    <BarrasAgrupadas20 segregacion={segregacion} arrayG={arrayG[19]}/>
                    </div>
                    <div className="child">
                    <BarrasAgrupadas21 segregacion={segregacion} arrayG={arrayG[20]}/>
                    </div>
                    <div className="child">
                    <BarrasAgrupadas22 segregacion={segregacion} arrayG={arrayG[21]}/>
                    </div>
                    <div className="child">

                    </div>
                    <div className="child">
 
                    </div>
                    <div className="child">
                    
                    </div>
                    <div className="child">
                   
                    </div>
                    <div className="child">
                  
                    </div>

            </div>

        </div>
      );
  }

export default Mipymegobernanza
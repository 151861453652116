
function PreguntaObj2() {   

  return (
  <div className="container">
      <div className="preguntas">
    <h2>Si queremos hacer un CHECKBOX, el NAME debe ser distinto</h2>
    <ul>
     <li>
     <input type="radio" id="t-option" name="selector"></input>
    <label for="t-option">Cats</label>
    <div class="check"><div class="inside"></div></div>
    </li> 
    <li>
    <input type="radio" id="s-option" name="selector2"></input>
    <label for="s-option">Bacon</label>
    <div class="check"><div class="inside"></div></div>
    </li> 
    <li>
    <input type="radio" id="r-option" name="selector2"></input>
    <label for="r-option">abc</label>
    <div class="check"><div class="inside"></div></div>
    </li> 
    </ul>
    </div>
  </div>
  
  );
}

{/*

      {preguntas === 0 &&
        <div>
        <form>
        <div className="formulario">
        <Pregunta
        id="{cabecera[0].NumCuestionario}"
        titulo={cabecera2.EnunciadoPregunta}
        resp1="A nivel de datos o información básica"
        resp2="A nivel de informes técnicos"
        resp3="A nivel de estudios de impacto"
        resp4="No"
        resp5=""
        resp6=""
        resp7=""
        resp8=""
        />
        <Pregunta
        id="2"
        titulo="2) ¿La empresa tiene un plan de actividades de gestión ambiental?"
        resp1="Sí"
        resp2="No"
        resp3=""
        resp4=""
        resp5=""
        resp6=""
        resp7=""
        resp8=""
        />
        <Pregunta
        id="3"
        titulo="3) ¿La empresa usa algún mecanismo para la generación de energía eléctrica de fuentes renovables?"
        resp1="Generación hidroeléctrica"
        resp2="Paneles solares fotovoltaicos"
        resp3="Mini generadores eólicos"
        resp4="Residuos sólidos urbanos"
        resp5="Biomasa"
        resp6="Compra externa de red de fuente renovable"
        resp7="No"
        resp8=""
        />
        <Pregunta
        id="4"
        titulo="4) ¿La empresa usa energía renovable para otros fines?"
        resp1="Generación de energía térmica"
        resp2="Generación de energía mecánica"
        resp3="Refrigeración"
        resp4="Hidrógeno verde"
        resp5="Sistemas de almacenamiento de energía renovable"
        resp6="Otros"
        resp7="No"
        resp8=""
        />
        <Pregunta
        id="5"
        titulo="5) ¿La empresa usa algún sistema para mejorar la eficiencia energética?"
        resp1="Sistemas de ahorro de consumo de agua"
        resp2="Calefones solares"
        resp3="Eficiencia en electrodomésticos"
        resp4="Bombas de calor"
        resp5="Envolvente térmica de edificios"
        resp6="Sistemas de ahorro en iluminación"
        resp7="Otros"
        resp8="No"
        />
        <div className="cajainput">
        <input type ="button" value="Proseguir a criterio social" href="#iniciopreguntas" onClick={preguntaSiguiente}></input>   
        </div>

        </div>

      </form>
        </div>
        } 

        {preguntas === 1 &&
        <div>
        <form>
        <div className="formulario">
        <Pregunta
        id="21"
        titulo="21) ¿Los líderes de la empresa dan
        cuenta del tema de género en su
        lenguaje o discurso?"
        resp1="En diálogos cotidianos con empleados"
        resp2="En medios de comunicación"
        resp3="En campañas o eventos"
        resp4="No"
        resp5=""
        resp6=""
        resp7=""
        resp8=""
        />
        <Pregunta
        id="22"
        titulo="22) ¿Se han incorporado temas de género en documentos de la mipyme?"
        resp1="En documentos estratégicos u operativos"
        resp2="En reglamentos"
        resp3="En manuales"
        resp4="Otros"
        resp5="No"
        resp6=""
        resp7=""
        resp8=""
        />
        <div className="cajainput">
        <input type ="button" value="Regresar al criterio de Gobernanza" onClick={preguntaAnterior}></input>   
        <input type ="button" value="Proseguir a criterio de Gobernanza" onClick={preguntaSiguiente}></input>
        </div>
        </div>

      </form>
        </div>
        } 

        {preguntas === 2 &&
        <div>
        <form>
        <div className="formulario">
        <Pregunta
        id="39"
        titulo="39) ¿La empresa tiene interacción con clientes y proveedores vía smartphones (WhatsApp, delivery, otros)?"
        resp1="Sí"
        resp2="No"
        resp3=""
        resp4=""
        resp5=""
        resp6=""
        resp7=""
        resp8=""
        />
        <Pregunta
        id="40"
        titulo="40) ¿La empresa tiene un sitio web desarrollado?"
        resp1="Sí"
        resp2="No"
        resp3=""
        resp4=""
        resp5=""
        resp6=""
        resp7=""
        resp8=""
        />
        <div class="cajainput">
        <input type ="button" value="Regresar al criterio anterior" onClick={preguntaAnterior}></input>
        <Link to="/FinalAutodiagnostico">
        <input type ="button" value="Enviar resultados"></input>
        </Link>
        </div>
        </div>

      </form>
        </div>
        }
    */} 


export default PreguntaObj2
import './pregObj.css';
import '../cards/cards.css';
import React, { useState, useEffect } from "react";

function PreguntaObj({objeto, titulo, mul, nombre, respuestaSeleccionada, norespuestaSeleccionada}) {
  const titulos = titulo;
  const mutiples = mul;
  const nom = nombre;
  const largo = objeto.length
  /* TODAS LAS RPTAS GUARDADAS */
  console.log("AQUI EL PROBLEMA")
  console.log(typeof mul)
  const rptas = objeto.map(x => (x.NumRespuesta));
  /*conseguir todos las cabeceras para el INCLUDE*/
  const rsNumId = respuestaSeleccionada.map(x => (x[0]))
  /* TODOS LOS TEXTO INPUT GUARDADOS */ 
  const rsTextId = respuestaSeleccionada.map(x => (x[1]))
  /* Revisar si el no aplica  */

  /*Borra y crea rapidamente*/
  const [noresponder, setNoresponder] = useState(true);
  /*Mantendra oculto*/
  const [noocultar, setNoocultar] = useState(false);
  /*Borrar multiples cuando seleccione NO*/
  const [noocultarMul, setNoocultarMul] = useState(false);
  /*Borra y crea rapidamente para multiple*/
  const [noresponderMul, setNoresponderMul] = useState(true);

  const handleOnChange2 = () => {
  setNoocultarMul(!noocultarMul)

  if(noocultarMul) {
    setNoresponderMul(!noresponderMul)
  } else {
    setNoresponderMul(!noresponderMul)
  }
  setTimeout(prender2, 50);
  }

  const handleOnChange3 = (e) => {
    console.log(e.target.value)
    }



  const handleOnChange = () => {
   console.log("Marco no aplica")
  };

  const prender2 = () => {
    setNoresponderMul(true);
  };

  //AQUI ES PARA QUE MARQUE LO YA ANTES MARCADO

  useEffect(()=>{

    for (let i = 0; i < norespuestaSeleccionada.length; i++) {
      
      if((nom+"nulo") === norespuestaSeleccionada[i]) {
        var checkbox = document.getElementById(
          norespuestaSeleccionada[i]
        )
        checkbox.checked = true;
        console.log("SE VA A MARCAR: "+norespuestaSeleccionada[i]+" PARA: "+ nom )
      }
    
    }

    for (let i = 0; i < rptas.length; i++) {
      
      if( rsNumId.includes(""+rptas[i]) ){
        console.log("DEBE APARECER:"+rptas[i])
        var checkbox = document.getElementById(
          rptas[i]
        )
        /* PARA ESCRIBIR EL INPUT SI TIENE */
        var inputcheck = document.getElementById(
          rptas[i]+"INPUT"
        )

        if(checkbox){
          var indice = rsNumId.indexOf(""+rptas[i])
          var checkName = checkbox.name
          console.log("EL NOMBRE ES: "+checkName)
          checkbox.checked = true;
          /* CUANDO DA OC de NOMBRE TENGO QUE ACTIVAR LA OCULTACION SOLO SI SE MARCO NOM*/
          if ( checkName.substr(0,2) === "OC"){
          handleOnChange2()
          }
          
          /* ESCRIBE EL INPUT */
          if(inputcheck) {
            inputcheck.value = rsTextId[indice];
          }

        }
      }
    }
    
     
    
  },[])



  return (
    <div className="container">
        <div className="preguntas">
      <h2>{titulos}</h2>
      {mutiples === "1" &&
      <h4>(Opcion multiple)</h4>
      }
      <ul>

      <li className="noresponder">
      <input type="checkbox" id={nom+"nulo"} name="nulo" value={nom+"nulo"}  onChange={handleOnChange} ></input>
      <label htmlFor={nom+"nulo"}>No aplica</label>
      <div className="check"><div className="inside"></div></div>
      </li>
      {/*  <div className="mostrarPregunta"> */}
      <div className={`${noocultar ? "ocultarPregunta" : ""}`}>
        {(mutiples === "0" && noresponder) &&



          objeto.map(p =>(
        <li>
        <input type="radio" id={p.NumRespuesta} name={nom} value={p.NumRespuesta}></input>
        <label htmlFor={p.NumRespuesta}>{p.EnunciadoRespuesta}</label>
        <div className="check"><div className="inside"></div></div>
            
            {p.especifica === "1" &&
            <div className = "inputpreg">
              <input placeholder="Especifique" type="text" maxLength="50" id={p.NumRespuesta+"INPUT"} name={nom+p.NumRespuesta} ></input>
            </div>
            }
            {p.especifica === "2" &&
            <div className = "inputpreg">
              <input onChange={handleOnChange3} placeholder="Número" type="number" min="0" max="100" id={p.NumRespuesta+"INPUT"} name={nom+p.NumRespuesta} ></input>
            </div>
            }
            

        </li> 
          
          ))  
        }

        {(mutiples === "1" && noresponder) &&
          objeto.map((p,i) =>


          (
            (i == (largo-1))
            ?
            
            <div>
            {/*ULTIMA PREGUNTA DE MULTIPLES*/
            p.especifica !== "2" &&
            <li>
            <input type="checkbox" id={p.NumRespuesta} name={"OC"+nom+p.NumRespuesta} onChange={handleOnChange2} value={p.NumRespuesta}></input>
            <label htmlFor={p.NumRespuesta}>{p.EnunciadoRespuesta} (opcion única)</label>
            <div className="check"><div className="inside"></div></div>
            </li> 
            }

            {/*ULTIMA PREGUNTA DE MULTIPLES*/
            p.especifica === "2" &&
            <li>
            <input type="checkbox" id={p.NumRespuesta} name={nom+p.NumRespuesta} value={p.NumRespuesta}></input>
            <label htmlFor={p.NumRespuesta}>{p.EnunciadoRespuesta}</label>
            <div className="check"><div className="inside"></div></div>
            <div className = "inputpreg">
            <input placeholder="si/no" type="text" maxLength="2" id={p.NumRespuesta+"INPUT"} name={nom+p.NumRespuesta} ></input>
            </div>
            </li> 
            }
           
            
            </div>
           
            :
            <div>
            { noresponderMul &&
            <li className={`${noocultarMul ? "ocultarPregunta" : ""}`}>
            <input type="checkbox" id={p.NumRespuesta} name={nom+p.NumRespuesta} value={p.NumRespuesta}></input>
            <label htmlFor={p.NumRespuesta}>{p.EnunciadoRespuesta}</label>
            <div className="check"><div className="inside"></div></div>
            
            {(p.especifica === "1") &&
            <div className = "inputpreg">
            <input placeholder="Especifique" type="text" maxLength="50" id={p.NumRespuesta+"INPUT"} name={nom+p.NumRespuesta} ></input>
            </div>
            }
            {(p.especifica === "2") &&
            <div className = "inputpreg">
            <input placeholder="sí/no" type="text" maxLength="2" id={p.NumRespuesta+"INPUT"} name={nom+p.NumRespuesta} ></input>
            </div>
            }
           

            </li>


            }
            </div>
          )
          
          )  
        }
        </div>
      
      </ul>
      </div>
    </div>
  );
}



export default PreguntaObj
import './PreguntasFrecuentes.css';


import PreAmbiental from './shopbag_SINFONDO.png';
import PreGobernanza from './brain_SINFONDO.png';
import PreSocial from './candado_sinFONDO.png';

import PregIMG1 from './pencilsymbol.png';
import PregIMG2 from './exam.png';
import PregIMG3 from './monitor.png';
import PregIMG4 from './calculator.png';

import graficoAZUL1 from './graficoAZUL1.png';
import graficoAZUL2 from './graficoAZUL2.png';
import graficoAZUL3 from './graficoAZUL3.png';
import graficoAZUL4 from './graficoAZUL4.png';


function PreguntasFrecuentes() {
    return (
        <div className="container__finalauto">
            <div className="presentacionfinalINFO">
            <h1>P.M. COSMO S.A.C</h1>
            </div>

            <div className="SubtituloINFO">
            ¿Quienes somos nosotros?
            </div>

            <div className="CuerpoINFO">
            Somos RUC N° 20557512421 – P.M. COSMO S.A.C. Nosotros otorgamos asesoramiento y soluciones tecnologicas. Hemos trabajado con marcas nacionales e
            internacionales como por ejemplo Claro, El Banco Adeneu, y El ministerio de Desarrollo e inclusión social del Perú.
            </div>

            <div className="SubtituloINFO">
            ¿Que servicios ofrecemos?
            </div>

            <div className="CuerpoINFO">
            Realizamos analisis e implementación de soluciones tecnologicas, para potenciar los negocios. Desarrollo y despliege de páginas webs y base de datos.
            Implementación de soluciones tecnologicas para los procesos de la empresa (TI), asi como desarrollo de la seguridad Informatica. 
            </div>

            <div className="presentacionfinalINFO">
            <h1>Preguntas frecuentes</h1>
            </div>

            <div className="SubtituloINFO">
            ¿De qué trata el Cuestionario TI?
            </div>

            <div className="CuerpoINFO">
                El cuestionario es una herramienta de autodiagnóstico que aborda la evaluación del área de Tecnologia de la Información (TI)
                de su empresa. Este se separa en 3 temas principales (Marketing, Integración y Seguridad), las cuales son de suma importancia desarrollar
                para conseguir una ventaja competitiva. El cuestionario posee 60 preguntas en total.
            </div>
            <div className="presentacionfinalINFO"></div>

            <div className="contenedordeimagen">
                <div className="contenedordeimagenpreg">
                <img className='imagenPreg' src={PreAmbiental} alt="logo empresa"></img>
                </div>
                <nav>Marketing</nav>
                Expanda los medios por los cuales se promocionara. Paginas webs, anuncios virtuales, redes sociales. Informese sobre que ventajas
                tecnologicas no esta aprovechando, y permitanos guiarlos en la implementación de estas. 
                </div>

            <div className="contenedordeimagen">
                <div className="contenedordeimagenpreg">
                <img className='imagenPreg' src={PreGobernanza} alt="logo empresa"></img>
                </div>
                <nav>Integración</nav>
                Los procesos de su negocio pueden mejorarse al integrarse con procesos tecnologicos, como por ejemplo disponer de una VPN veloz
                para que los empleados puedan realizar trabajo a distancia. Tambien el procesar datos de forma efectiva y rápida mediante el uso de una
                base de datos.
            </div>

            <div className="contenedordeimagen">
                <div className="contenedordeimagenpreg">
                <img className='imagenPreg' src={PreSocial} alt="logo empresa"></img>
                </div>
                <nav>Seguridad</nav>
                La información debe tener el triangulo de la seguridad Informatica: Estar siempre disponible cuando se necesite (disponibilidad);
                Su información solo debe ser accesible al personal autorizado (Confidencialidad) y ádemas esta no debe modificarse por error o perderse (Integridad)
            </div>
            <div className="presentacionfinalINFO"></div>

            <div className="SubtituloINFO">
            ¿Por qué debería realizar el cuestionario?
            </div>

            <div className="CuerpoINFO">
            Para identificar brechas y oportunidades de su modelo de negocio. Podra tener conocimientos de que servicios requeriere usted para su área TI.
            Mejore su área de Marketing. Integre tecnologia a sus procesos. Implemente la seguridad de la Información en su empresa.
            </div>

            <div className="presentacionfinalINFO"></div>

            <div className="SubtituloINFO2">
            ¿Cómo es el proceso del cuestionario?
            </div>

            <div className="contenedordeimagen2">
                <div className="contenedordeimagenpreg">
                <img className='imagenPreg' src={graficoAZUL1} alt="logo empresa"></img>
                </div>
                <nav>Registrese usted y su empresa</nav>
                <div className="contenedordeimagenpreg">
                <img className='imagenPreg' src={PregIMG1} alt="logo empresa"></img>
                </div>
            </div>

            <div className="contenedordeimagen2">
                <div className="contenedordeimagenpreg">
                <img className='imagenPreg' src={graficoAZUL2} alt="logo empresa"></img>
                </div>
                <nav>Responder las preguntas para el autodiagnóstico</nav>
                <div className="contenedordeimagenpreg">
                <img className='imagenPreg' src={PregIMG2} alt="logo empresa"></img>
                </div>
            </div>

            <div className="contenedordeimagen2">
                <div className="contenedordeimagenpreg">
                <img className='imagenPreg' src={graficoAZUL3} alt="logo empresa"></img>
                </div>
                <nav>Visualizar y descargar el informe de resultados</nav>
                <div className="contenedordeimagenpreg">
                <img className='imagenPreg' src={PregIMG3} alt="logo empresa"></img>
                </div>
            </div>

            <div className="contenedordeimagen2">
                <div className="contenedordeimagenpreg">
                <img className='imagenPreg' src={graficoAZUL4} alt="logo empresa"></img>
                </div>
                <nav>Solicite nuestros servicios</nav>
                <div className="contenedordeimagenpreg">
                <img className='imagenPreg' src={PregIMG4} alt="logo empresa"></img>
                </div>
            </div>

        </div>
      );
  }

export default PreguntasFrecuentes
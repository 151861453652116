import React, { useState, useEffect } from "react";
import Typography from '@mui/material/Typography';
import { Link, useNavigate } from "react-router-dom";
import { FormControl, InputLabel, Container, FormHelperText, Input, Button, Grid, MenuItem, Checkbox, FormControlLabel} from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import axios from 'axios'
import PantallaCarga from "../../pantallacarga/pantallacarga";

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


function RegistroModal({ user, setUser, token }) {
  // TELEFONO EsParticipante

    const [preCUIT, setpreCUIT] = React.useState('');

    const [CUIT, setCUIT] = React.useState('');
    const [CUITV, setCUITV] = React.useState('')

    const [tele, setTele] = React.useState(''); //DUDA

    const [crea, setCrea] = React.useState('');
    const [creaV, setCreaV] = React.useState('')

    const [social, setSocial] = React.useState('');
    const [socialV, setSocialV] = React.useState('')

    const [pais, setPais] = React.useState('');
    const [paisV, setPaisV] = React.useState('');

    const [region, setRegion] = React.useState('');
    const [regionV, setRegionV] = React.useState('');

    const [provincia, setProvincia] = React.useState('');
    const [provinciaV, setProvinciaV] = React.useState('');

    const [municipalidad, setMunicipalidad] = React.useState('');
    const [municipalidadV, setMunicipalidadV] = React.useState('');

    const [participante, setParticipante] = React.useState(''); // Agregar a futuro

    const [tamaño, setTamaño] = React.useState('');


    const [area, setArea] = React.useState('');


    const [destino, setDestino] = React.useState('');


    const [sector, setSector] = React.useState('');


    const [rubro, setRubro] = React.useState('');


    const [sexopropietario, setGenero] = React.useState('');

    

    const handleChange = (event: SelectChangeEvent) => {
      setPais(event.target.value as string);
    }

    const handleChangeP = (event: SelectChangeEvent) => {
      setParticipante(event.target.value as string);
    }

    const handleChange2 = (event: SelectChangeEvent) => {
      setTamaño(event.target.value as string);
      ve.Tamaño = "valido"
    }

    const handleChange3 = (event: SelectChangeEvent) => {
      setArea(event.target.value as string);
      ve.Area = "valido"
    }

    const handleChange4 = (event: SelectChangeEvent) => {
      setDestino(event.target.value as string);
      ve.Destino = "valido"
    }

    const handleChange5 = (event: SelectChangeEvent) => {
      setSector(event.target.value as string);
      ve.Sector = "valido"
      ve.Rubro = "invalido"
    }

    const handleChange6 = (event: SelectChangeEvent) => {
      setRubro(event.target.value as string);
      ve.Rubro = "valido"
    }

    const handleChange7 = (event: SelectChangeEvent) => {
      setGenero(event.target.value as string);
      ve.SexoPropietario = "valido"
    }

    const navigate  = useNavigate();

    const [cargado, setCargado] = useState(false)
    
    const [cargado2, setCargado2] = useState(false)

    const [cargado3, setCargado3] = useState(false)

    const [cargadoCambio, setCargadoCambio] = useState(false)
    const [checked, setChecked] = React.useState(false);
    const handleChangeChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

    const [ve, setVe] = useState({
      "CUIT": CUIT,
      "Sector":sector,
      "Rubro": rubro, 
      "Tamaño":tamaño,
      "RazonSocial":social,
      "Area":area,
      "SexoPropietario":sexopropietario,
      "UbicacionOficinaPAIS": pais,
      "UbicacionOficinaRegion": region,
      "UbicacionOficinaProvincia": provincia,
      "UbicacionOficinaMunicipio": municipalidad,
      "CreacionAnos": crea,
      "Destino": destino,
      "preCUIT": preCUIT
    });

    const base = "https://bid.adeneu.com.ar/autodiagnostico/index.php";  
    useEffect(() => {
      setCargado(true)
        const headers = {
          "Accept":"application/json",
          'Authorization': `Bearer ${token}`
        }
        axios.get(base+"?m=buscarPreCUIT",{headers}).then((response)=>{
          
          if (response.data.message === "CUIT valido"){
            setCargado(false)
            setCargado3(true)
            console.log("NO EXIISTE EL CUIT: "+response.data.message)
            ve.CUIT = "valido"
            setCUIT(user.preCUIT)
            setCUITV(user.preCUIT)
            
          } else {
          setCargado3(true)
          setCargado2(true) 
          const cuitdato = response.data.data[0]
          console.log(cuitdato)          
          
          setSocialV(cuitdato.RazonSocial)
          setCUITV(cuitdato.CUIT)
          setGenero(cuitdato.SexoPropietario)
          setCreaV(cuitdato.CreacionAnos)
          setPaisV(cuitdato.UbicacionOficinaPAIS)
          setRegionV(cuitdato.UbicacionOficinaRegion)
          setProvinciaV(cuitdato.UbicacionOficinaProvincia)
          setMunicipalidadV(cuitdato.UbicacionOficinaMunicipio)


          setCUIT(cuitdato.CUIT)
          setTamaño(cuitdato.Tamaño)
          setArea(cuitdato.Area)
          setDestino(cuitdato.Destino)
          setSector(cuitdato.Sector)
          setRubro(cuitdato.Rubro)
          setCargado(false)

          ve.CUIT = "valido"
          ve.CreacionAnos = "valido"
          ve.RazonSocial = "valido"
          ve.UbicacionOficinaPAIS = "valido"
          ve.UbicacionOficinaProvincia = "valido"
          ve.UbicacionOficinaMunicipio = "valido"
          ve.UbicacionOficinaRegion = "valido"

          ve.SexoPropietario = "valido"
          ve.Rubro = "valido"
          ve.Sector = "valido"
          ve.Destino = "valido"
          ve.Area = "valido"
          ve.Tamaño = "valido"
          }
          
        }).catch((error)=>{
          console.error(error)
          setCargado(false)   
        })

      }, []);

    const CuitCambio = () => {
      setCargado(true);
      const headers = {
        "Accept":"application/json",
        'Authorization': `Bearer ${token}`
      }
      axios.get(base+"?m=actualizarPreCUIT&nuevoPreCUIT="+preCUIT,{headers}).then((response)=>{
        if (response.data.message === "Usuario editado"){
          console.log("Se cambio el preCUIT a: "+preCUIT)
          user.preCUIT = preCUIT
          setCargado(false);
          navigate('/CambiopreCUIT');
        } else{

        }
      }).catch((error)=>{
        console.error(error)
        setCargado(false)   
      })
      
    }


    const CuitRegistro = () => {
      
      if(
        ve.CUIT === "valido" &&
        ve.Destino === "valido" &&
        ve.RazonSocial === "valido" &&
        ve.CreacionAnos === "valido" &&
        ve.UbicacionOficinaPAIS === "valido" &&
        ve.UbicacionOficinaProvincia === "valido" &&
        ve.UbicacionOficinaMunicipio === "valido" &&
        ve.UbicacionOficinaRegion=== "valido" &&
        ve.Sector=== "valido" &&
        ve.Rubro=== "valido" &&
        ve.SexoPropietario === "valido" &&
        ve.Area === "valido" &&
        ve.Tamaño === "valido"
      ){
        setCargado(true);
        const headers = {
          "Accept":"application/json",
        }
        const base = "https://bid.adeneu.com.ar/autodiagnostico/index.php";
    
            axios.post(base+"?m=pymeStore", {
                "CUIT": CUIT,
                "Sector":sector,
                "Rubro": rubro, 
                "Tamaño":tamaño,
                "RazonSocial":social,
                "Area":area,
                "SexoPropietario":sexopropietario,
                "UbicacionOficinaPAIS": pais,
                "UbicacionOficinaRegion": region,
                "UbicacionOficinaProvincia": provincia,
                "UbicacionOficinaMunicipio": municipalidad,
                "CreacionAnos": crea,
                "Destino": destino

          }, {headers}).then((response)=>{
            //REVISAR MENSAJE
            
            if (response.data.data === null) {
              console.log("YA EXISTIA ESA COSA: "+CUIT);
            } else{
              console.log("El CUIT CREADO RECEPCIONADO: "+ response.data.data);
            }
            var NewCUIT = CUIT
            
            if (NewCUIT !== null){
              console.log("NUEVO RUC: "+ NewCUIT);
              axios.put(base+"?m=updateUser", {
                  "IDperfil": user.IDperfil,
                  "CUIT": NewCUIT
              }, {headers}).then((response)=>{
                console.log("El USUARIO QUE OBTENEMOS ES: "+ JSON.stringify(response.data.data));
                
                var usertemporal = {
                  IDperfil: user.IDperfil,
                  CUIT: NewCUIT,
                  Nombre: user.Nombre,
                  NumTipoUsuario: user.NumTipoUsuario,
                  preCUIT: user.preCUIT,
                  Correo: user.Correo,
                  CorreoVerificado: user.CorreoVerificado
                }

                /*ACTUALIZO*/
                localStorage.setItem('userLocal', JSON.stringify(usertemporal));

                setUser({
                  IDperfil: user.IDperfil,
                  CUIT: NewCUIT,
                  Nombre: user.Nombre,
                  NumTipoUsuario: user.NumTipoUsuario,
                  preCUIT: user.preCUIT,
                  Correo: user.Correo,
                  CorreoVerificado: user.CorreoVerificado
                  })


              }).catch((error)=>{
                console.error(error)
                setCargado(false);
                })
              setCargado(false);
              navigate('/PregAutodiagnostico');
            } else {
              window.alert("NO SE CREO EL CUIT, HUBO UN ERROR");
              setCargado(false);
            }
          }).catch((error)=>{
            console.error(error)
            setCargado(false);
            })
          } else {
            window.alert("Rellenar bien los campos");
            console.log(JSON.stringify(ve));
          }
          
    }

  
    return (
      <div>

{
    cargado
    ?
    <div>
    <PantallaCarga/>
    </div>
    :
   
        <Container maxWidth="md">

            <Typography id="modal-modal-title" variant="h4" component="h2">
              Registre a la empresa
            </Typography>

            <FormControlLabel
            label="Deseo cambiar la RUC"
            control={
            <Checkbox
            checked={checked}
            onChange={handleChangeChecked}
            inputProps={{ 'aria-label': 'controlled' }}
            />
            }
            />

          {checked &&
           <Grid container>
           <Grid item md={6}>
             <FormControl   disabled={cargadoCambio} sx={{ mt: 3 }}>
               <InputLabel htmlFor="CUITNuevo">Nuevo RUC</InputLabel>
               <Input id="CUITNuevo" type="number" onChange={(e)=>{
                 setpreCUIT(e.target.value)
                 if(/^[0-9]{11,11}$/.test(e.target.value) ) {
                 ve.preCUIT = "valido"
                 } else {
                 ve.preCUIT = "Invalido"
                 }
 
               }} aria-describedby="my-helper-text" />
              {ve.preCUIT === "" && <FormHelperText id="my-helper-text">Obligatorio</FormHelperText>}
             {ve.preCUIT !== "" && <FormHelperText id="my-helper-text">{ve.preCUIT}</FormHelperText>}
             </FormControl>
           </Grid>
 
           <Grid item md={6}>
             <FormControl   disabled={cargadoCambio} sx={{ mt: 3 }}>
               <Button  variant="contained" color="primary" onClick={CuitCambio}>
                 Actualizar
               </Button>
             </FormControl>
           </Grid>
         </Grid>
          }

        <Grid container>
          <Grid item md={6}>
            <FormControl   disabled={cargado3} sx={{ mt: 6 }}>
              <InputLabel htmlFor="CUIT">RUC</InputLabel>
              <Input id="CUIT" defaultValue={CUITV} type="number" onChange={(e)=>{
                setCUIT(e.target.value)
                if(/^[0-9]{11,11}$/.test(e.target.value) ) {
                ve.CUIT = "valido"
                } else {
                ve.CUIT = "Invalido"
                }

              }} aria-describedby="my-helper-text" />
             {ve.CUIT === "" && <FormHelperText id="my-helper-text">Obligatorio</FormHelperText>}
             {ve.CUIT !== "" && <FormHelperText id="my-helper-text">{ve.CUIT}</FormHelperText>}
            </FormControl>
          </Grid>
          
        </Grid>
        

        <Grid container>
          <Grid item md={6}>
            <FormControl disabled={cargado2} sx={{ mt: 6 }}>
              <InputLabel htmlFor="crea">Años de creación </InputLabel>
              <Input id="crea" defaultValue={creaV} type="number" onChange={(e)=>{
                setCrea(e.target.value)
                if(/^[0-9]{0,2}$/.test(e.target.value) ) {
                  ve.CreacionAnos = "valido"
                  } else {
                  ve.CreacionAnos = "Invalido"
                  }
                }} aria-describedby="my-helper-text" />
            {ve.CreacionAnos === "" && <FormHelperText id="my-helper-text">Obligatorio</FormHelperText>}
            {ve.CreacionAnos !== "" && <FormHelperText id="my-helper-text">{ve.CreacionAnos}</FormHelperText>}
            </FormControl>
          </Grid>


          <Grid item md={6}>
            <FormControl disabled={cargado2} sx={{ mt: 6 }}>
              <InputLabel htmlFor="social">Razón Social</InputLabel>
              <Input id="social" defaultValue={socialV} onChange={(e)=>{
                setSocial(e.target.value)
                if(/^[\s\S]{2,75}$/.test(e.target.value) ) {
                  ve.RazonSocial = "valido"
                  } else {
                  ve.RazonSocial = "Invalido"
                  }
                }} aria-describedby="my-helper-text" />
             {ve.RazonSocial === "" && <FormHelperText id="my-helper-text">Obligatorio</FormHelperText>}
             {ve.RazonSocial !== "" && <FormHelperText id="my-helper-text">{ve.RazonSocial}</FormHelperText>}
            </FormControl>
          </Grid>
        </Grid>

        <Grid container>

          <Grid item md={4} sx={{ mt: 6 }}>
          <FormControl disabled={cargado2} fullWidth >
          <InputLabel id="demo-simple-select-label">Sexo del Propietario</InputLabel>
          <Select
            value={sexopropietario}
            label="sexo_propietario__"
            onChange={handleChange7}>
            <MenuItem value={"M"}>(M) Masculino</MenuItem>
            <MenuItem value={"F"}>(F) Femenino</MenuItem>
          </Select>
          </FormControl>
          </Grid>

          <Grid item md={2}></Grid>

          
          {/* 
            
            <Grid item md={4} sx={{ mt: 6 }}>
          <FormControl fullWidth >
          <InputLabel id="demo-simple-select-label">¿Es participante?</InputLabel>
          <Select
            value={participante}
            label="sexo_propietario__"
            onChange={handleChangeP}>
            <MenuItem value={"Sí"}>Sí</MenuItem>
            <MenuItem value={"No"}>No</MenuItem>
          </Select>
          </FormControl>
          </Grid>
            
          */}

          

        </Grid>



          <Typography id="modal-modal-title" sx={{ mt: 6 }} variant="h4" component="h2">
              Ingrese la ubicación de la oficina principal
            </Typography>        

        <Grid container>
          <Grid item md={6}>
            <FormControl disabled={cargado2} sx={{ mt: 6 }}>
              <InputLabel htmlFor="pais">País</InputLabel>
              <Input id="pais" defaultValue={paisV} onChange={(e)=>{
                setPais(e.target.value)
                if(/^[a-zA-ZÀ-ÿ\s]{3,74}$/.test(e.target.value) ) {
                  ve.UbicacionOficinaPAIS = "valido"
                  } else {
                  ve.UbicacionOficinaPAIS = "Invalido"
                  }
              }} aria-describedby="my-helper-text" />
             {ve.UbicacionOficinaPAIS === "" && <FormHelperText id="my-helper-text">Obligatorio</FormHelperText>}
             {ve.UbicacionOficinaPAIS !== "" && <FormHelperText id="my-helper-text">{ve.UbicacionOficinaPAIS}</FormHelperText>}
            </FormControl>
          </Grid>
          <Grid item md={6}>
            <FormControl disabled={cargado2} sx={{ mt: 6 }}>
              <InputLabel htmlFor="region">Region/Departamento</InputLabel>
              <Input id="region" defaultValue={regionV} onChange={(e)=>{
                setRegion(e.target.value)
                if(/^[a-zA-ZÀ-ÿ\s]{3,74}$/.test(e.target.value) ) {
                  ve.UbicacionOficinaRegion = "valido"
                  } else {
                  ve.UbicacionOficinaRegion = "Invalido"
                  }
                }} aria-describedby="my-helper-text" />
              {ve.UbicacionOficinaRegion === "" && <FormHelperText id="my-helper-text">Obligatorio</FormHelperText>}
              {ve.UbicacionOficinaRegion !== "" && <FormHelperText id="my-helper-text">{ve.UbicacionOficinaRegion}</FormHelperText>}
            </FormControl>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item md={6}>
            <FormControl disabled={cargado2} sx={{ mt: 6 }}>
              <InputLabel htmlFor="provincia">Provincia</InputLabel>
              <Input id="provincia" defaultValue={provinciaV} onChange={(e)=>{
                setProvincia(e.target.value)
                if(/^[a-zA-ZÀ-ÿ\s]{3,74}$/.test(e.target.value) ) {
                  ve.UbicacionOficinaProvincia = "valido"
                  } else {
                  ve.UbicacionOficinaProvincia = "Invalido"
                  }
                }} aria-describedby="my-helper-text" />
              {ve.UbicacionOficinaProvincia === "" && <FormHelperText id="my-helper-text">Obligatorio</FormHelperText>}
              {ve.UbicacionOficinaProvincia !== "" && <FormHelperText id="my-helper-text">{ve.UbicacionOficinaProvincia}</FormHelperText>}
            </FormControl>
          </Grid>

         <Grid item md={6}>
            <FormControl disabled={cargado2} sx={{ mt: 6 }}>
              <InputLabel htmlFor="Muni">Municipalidad</InputLabel>
              <Input id="Muni" defaultValue={municipalidadV} onChange={(e)=>{
                setMunicipalidad(e.target.value)
                if(/^[a-zA-ZÀ-ÿ\s]{3,74}$/.test(e.target.value) ) {
                  ve.UbicacionOficinaMunicipio = "valido"
                  } else {
                  ve.UbicacionOficinaMunicipio = "Invalido"
                  }
                }} aria-describedby="my-helper-text" />
               {ve.UbicacionOficinaMunicipio === "" && <FormHelperText id="my-helper-text">Obligatorio</FormHelperText>}
               {ve.UbicacionOficinaMunicipio !== "" && <FormHelperText id="my-helper-text">{ve.UbicacionOficinaMunicipio}</FormHelperText>}
            </FormControl>
          </Grid>
        </Grid>

        <Typography sx={{ mt: 6 }} id="modal-modal-title" variant="h4" component="h2">
              Información de la Empresa
            </Typography>

        <Grid container>

          <Grid item md={6}>
            <FormControl disabled={cargado2} style = {{width: 200}} sx={{ mt: 6 }}>
            <InputLabel id="demo-simple-select-label">Tamaño</InputLabel>
          <Select
            value={tamaño}
            label="tamaño"
            onChange={handleChange2}>
            <MenuItem value={"Micro empresa"}>Micro empresa</MenuItem>
            <MenuItem value={"Pequeña Empresa"}>Pequeña Empresa</MenuItem>
            <MenuItem value={"Mediana Empresa - tramo 1"}>Mediana Empresa - tramo 1</MenuItem>
            <MenuItem value={"Mediana Empresa - tramo 2"}>Mediana Empresa - tramo 2</MenuItem>
          </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Grid container>
        <Grid item md={6}>
            <FormControl disabled={cargado2}  style = {{width: 200}}  sx={{ mt: 6 }}>
            <InputLabel id="demo-simple-select-label">Área</InputLabel>
          <Select
            value={area}
            label="area"
            onChange={handleChange3}>
            <MenuItem value={"Urbano"}>Urbano</MenuItem>
            <MenuItem value={"Rural"}>Rural</MenuItem>
            <MenuItem value={"Tiene sedes tanto en áreas urbanas como rurales"}>Tiene sedes tanto en áreas urbanas como rurales</MenuItem>
          </Select>
            </FormControl>
          </Grid>

        </Grid>


        <Grid container>
          <Grid item md={6}>
            <FormControl disabled={cargado2} style = {{width: 200}} sx={{ mt: 6 }}>
            <InputLabel id="demo-simple-select-label">Destino principal de las ventas</InputLabel>
          <Select
            value={destino}
            label="Destino principal de las ventas"
            onChange={handleChange4}>
            <MenuItem value={"Mercado internacional"}>Mercado Internacional</MenuItem>
            <MenuItem value={"Mercado nacional"}>Mercado Nacional</MenuItem>
            <MenuItem value={"Mercado local"}>Mercado local</MenuItem>
            <MenuItem value={"Nacional e Internacional"}>Nacional e Internacional</MenuItem>
            <MenuItem value={"Nacional y Local"}>Nacional y Local</MenuItem>
            <MenuItem value={"Nacional Local Internacional"}>Nacional Local Internacional</MenuItem>
          </Select>
            </FormControl>
          </Grid>
          </Grid>


          <Grid container>
         <Grid item md={6}>
            <FormControl disabled={cargado2} style = {{width: 200}} sx={{ mt: 6 }}>
            <InputLabel id="demo-simple-select-label">Sector de actividad económica</InputLabel>
          <Select
            value={sector}
            label="Sector de actividad económica"
            onChange={handleChange5}>
            <MenuItem value={"S/C"}>S/C</MenuItem>
            <MenuItem value={"Agropecuario"}>Agropecuario</MenuItem>
            <MenuItem value={"Industria y Míneria"}>Industria y Míneria</MenuItem>
            <MenuItem value={"Comercio"}>Comercio</MenuItem>
            <MenuItem value={"Servicios"}>Servicios</MenuItem>
            <MenuItem value={"Construcción"}>Construcción</MenuItem>
           
          </Select>
            </FormControl>
          </Grid>
        </Grid>

        
        
       
        <Grid container>
        {sector === "S/C" &&
          <Grid item md={4}>
            <FormControl disabled={cargado2} style = {{width: 200}} sx={{ mt: 6 }}>
            <InputLabel id="demo-simple-select-label">Rubro de actividad económica</InputLabel>
          <Select
            value={rubro}
            label="Rubro de actividad económica"
            onChange={handleChange6}>
            <MenuItem value={"S/C"}>S/C</MenuItem>
          </Select>
            </FormControl>
          </Grid>
        }

        {sector === "Agropecuario" &&
          <Grid item md={4}>
            <FormControl disabled={cargado2} style = {{width: 200}} sx={{ mt: 6 }}>
            <InputLabel id="demo-simple-select-label">Rubro de actividad económica</InputLabel>
          <Select
            value={rubro}
            label="Rubro de actividad económica"
            onChange={handleChange6}>
            <MenuItem value={"Agricultura"}>Agricultura</MenuItem>
            <MenuItem value={"Ganadería"}>Ganadería</MenuItem>
            <MenuItem value={"Pesca"}>Pesca</MenuItem>
            <MenuItem value={"Forestal"}>Forestal</MenuItem>
          </Select>
            </FormControl>
          </Grid>
        }
        {sector === "Industria y Míneria" &&
          <Grid item md={4}>
            <FormControl disabled={cargado2} style = {{width: 200}} sx={{ mt: 6 }}>
            <InputLabel id="demo-simple-select-label">Rubro de actividad económica</InputLabel>
          <Select
            value={rubro}
            label="Rubro de actividad económica"
            onChange={handleChange6}>
            <MenuItem value={"Minería e Hidrocarburos"}>Minería e Hidrocarburos</MenuItem>
            <MenuItem value={"Manufactura "}>Manufactura</MenuItem>
            <MenuItem value={"Suministro de electricidad, gas y agua"}>Suministro de electricidad, gas y agua</MenuItem>
            <MenuItem value={"Construcción"}>Construcción</MenuItem>
          </Select>
            </FormControl>
          </Grid>
        }
         {sector === "Servicios" &&
          <Grid item md={4}>
            <FormControl disabled={cargado2} style = {{width: 200}} sx={{ mt: 6 }}>
            <InputLabel id="demo-simple-select-label">Rubro de actividad económica</InputLabel>
          <Select
            value={rubro}
            label="Rubro de actividad económica"
            onChange={handleChange6}>
            <MenuItem value={"Hoteles y restaurantes"}>Hoteles y restaurantes</MenuItem>
            <MenuItem value={"Transporte, almacenamiento y comunicaciones"}>Transporte, almacenamiento y comunicaciones</MenuItem>
            <MenuItem value={"Intermediación financiera"}>Intermediación financiera</MenuItem>
            <MenuItem value={"Servicios inmobiliarios, empresariales y de alquiler"}>Servicios inmobiliarios, empresariales y de alquiler</MenuItem>
            <MenuItem value={"Administración pública, defensa y seguridad social"}>Administración pública, defensa y seguridad social</MenuItem>
            <MenuItem value={"Educación"}>Educación</MenuItem>
            <MenuItem value={"Servicios sociales y de salud"}>Servicios sociales y de salud</MenuItem>
            <MenuItem value={"Servicios comunitarios, sociales y personales"}>Servicios comunitarios, sociales y personales</MenuItem>
            <MenuItem value={"Servicios a los hogares y servicio doméstico"}>Servicios a los hogares y servicio doméstico</MenuItem>
            <MenuItem value={"Otros"}>Otros</MenuItem>
          </Select>
            </FormControl>
          </Grid>
        }
         {sector === "Comercio" &&
          <Grid item md={4}>
            <FormControl disabled={cargado2} style = {{width: 200}} sx={{ mt: 6 }}>
            <InputLabel id="demo-simple-select-label">Rubro de actividad económica</InputLabel>
          <Select
            value={rubro}
            label="Rubro de actividad económica"
            onChange={handleChange6}>
            <MenuItem value={"Mayorista"}>Mayorista</MenuItem>
            <MenuItem value={"Minorista"}>Minorista</MenuItem>

          </Select>
            </FormControl>
          </Grid>
        }
        {sector === "Construcción" &&
          <Grid item md={4}>
            <FormControl disabled={cargado2} style = {{width: 200}} sx={{ mt: 6 }}>
            <InputLabel id="demo-simple-select-label">Rubro de actividad económica</InputLabel>
          <Select
            value={rubro}
            label="Rubro de actividad económica"
            onChange={handleChange6}>
            <MenuItem value={"Construcción"}>Construcción</MenuItem>
          </Select>
            </FormControl>
          </Grid>
        }

        </Grid>
          <Button  variant="contained" color="primary" onClick={CuitRegistro}  sx={{ mt: 12 }}>
          Ingresar
          </Button>

        </Container>
      }
      </div>
    );
  }

  function RegistradoModal({user, StoreA, StoreS, StoreG, noStoreA, noStoreS, noStoreG}) {

    const [btnExtra, setBtnExtra] = useState(false)
    const navigate  = useNavigate();

    const BorrarAvance = () => {

        localStorage.removeItem("Ambiental")
        localStorage.removeItem("Social")
        localStorage.removeItem("Gobernanza")
        localStorage.removeItem("noAmbiental")
        localStorage.removeItem("noSocial")
        localStorage.removeItem("noGobernanza")
        navigate('/PregAutodiagnostico');

    }



      return (
        <div>
            <Typography id="modal-modal-title" variant="h4" component="h2">
            Realizar Informe para empresa de RUC: {user.CUIT}
            </Typography>     
     
          <Container maxWidth="md">
          
          {(StoreA|| StoreS || StoreG || noStoreA || noStoreS || noStoreG ) &&
           <Grid item md={6}>
           <FormControl sx={{ mt: 6 }}>
           <Button  variant="contained" color="primary" onClick={BorrarAvance}  sx={{ mt: 4 }}>
           Iniciar desde 0
           </Button>
           </FormControl>
           </Grid>

          }
          <Grid item md={6}>
           <FormControl sx={{ mt: 6 }}>
            <Link to="/PregAutodiagnostico">
            <Button  variant="contained" color="primary"   sx={{ mt: 4 }}>
            Continuar
            </Button>
            </Link>
            </FormControl>
           </Grid>
          </Container>
  
        </div>
      );
    }

    function VolverAutodiagnostico() {

      return (
        <div>
          <Container maxWidth="md">
            <Link to="/InicioAutodiagnostico">
            <Button  variant="contained" color="primary"   sx={{ mt: 4, mb: 8 }}>
            Autodiagnóstico
            </Button>
            </Link>
          </Container>
  
        </div>
      );
    }

  export {RegistroModal, RegistradoModal, VolverAutodiagnostico};
import {RedireccionLogin}  from "./registro.tsx"

function RedireccionLogeoPost() {
    return (
    <div>
 
    
        <div className="container__finalauto">
            <div className="presentacionfinalauto">
            <h1>Su cuenta ha sido registrado</h1>
            <h2>Se le enviara un correo de confirmación en unos minutos, porfavor ingrese al login.</h2>
            </div>

            <div className="Botonesfinalauto">

            <RedireccionLogin/>

            </div>

        </div>

    </div>
      );
  }

export default RedireccionLogeoPost
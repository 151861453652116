import {LoginModal}  from "../registro.tsx"
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";

function Logeo({user, setUser, setToken }) {
    return (
        <div className="container__finalauto">

            <Helmet>
                <meta charSet="utf-8" />
                <title>Herramienta de Autodiagnostico ASG</title>
                
                <meta name="description" content="React application" />
            </Helmet>
            {/*
            <meta http-equiv="Content-Security-Policy" content="upgrade-insecure-requests"></meta>
            */}
            <div className="presentacionfinalauto">
            <h1>Ingreso de Usuario</h1>
            <h2>Utilice su correo y contraseña</h2>
            <Link to='/RecuperacionContra'><br></br>¿Has olvidado tu contraseña?</Link>
            </div>
            <div className="Botonesfinalauto">

            <LoginModal
             user={user}
             setUser={setUser}
             setToken = {setToken}
            >
            </LoginModal>

            </div>
        </div>
      );
  }

export default Logeo
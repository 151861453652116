import {RegistroModal}  from "../registro.tsx"

function Registro({ perfilR, setPerfilR, setUser, setToken }) {
    return (
        <div className="container__finalauto">
            <div className="presentacionfinalauto">
            <h1>Registro de Usuario</h1>
            <h2>Escriba los datos que usara para su cuenta y el RUC de su empresa.
</h2>
            </div>

            <div className="Botonesfinalauto">
            <RegistroModal
             perfilR={perfilR}
             setPerfilR={setPerfilR}
             setUser={setUser}
             setToken={setToken}
            >
            </RegistroModal>

            </div>
        </div>
      );
  }

export default Registro
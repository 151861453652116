import React from 'react';
import grafico from './Adopcion1.png';



function BarrasAgrupadas({P01}) {
 return( 
  <div className= "Adopcionsquare">
  <img  alt="imagen" className='imagengraficosADOP'src={grafico}></img>
  <div className='titulograficosADOP'>
    <div className='numpregraficosADOP'>P.01</div>
   <div className='colornegro'>IAC-ASG</div>
  <div>{P01}
  <div>Sobre 100 puntos</div>
  </div>
  </div>
  </div>
);
}

export default BarrasAgrupadas
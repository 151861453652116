import React from 'react';
import grafico from './working.png';

  
  function BarrasAgrupadas2C({gobernanzaP2}) {
   return( 
    <div className= "AdopcionsquarePLAN">
    <img  alt="imagen" className='imagengraficosADOPESTRA abajo'src={grafico}></img>
    <div className='titulograficosADOP'>
    <div className='numpregraficosADOP'>P.02</div>
    <div className='colornegro'>Seguridad física</div>
    <div>{gobernanzaP2}
    </div>
    </div>
    </div>
  );
  }

  export default BarrasAgrupadas2C
import React from 'react';
import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bubble } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import grafico1 from './tamanoedificio.png';
import grafico2 from './Participantes6.png';
import grafico3 from './Participantes3.png';
import grafico4 from './Operacion.png';
import grafico5 from './Participantes5.jpg';

ChartJS.register(LinearScale, PointElement, Tooltip, Legend);


export const options = {
  plugins: {
    legend: {
      display:false
    },
    datalabels: {
      display: true,
      color: 'black',
      anchor: 'center',
      align: 'start',
      offset: 18,
      formatter:function(value,context) {
        var num = context.dataIndex;
        var index = context.dataset.label;
        var mostrar = ((value.r)*5).toFixed() 
        return mostrar+"%"+index;
      }
   },
  },
  scales: {
    y: {
      beginAtZero: true,
      suggestedMin: 0,
      suggestedMax: 100,
      ticks: { /*Agrega porcentaje */
        callback: (value,index,values) =>{
          return  `${value}%`;
        }
      }
    },
    x: {
      beginAtZero: true,
      suggestedMin: 0,
      suggestedMax: 500,
      display: false
    },
  },
};





function Burbujas({segregacion, arrayT}) {

  var naranja  = "rgb(249, 156, 139)"
  var naranja1 = "rgb(236, 121, 73)"
  var naranja2 = "rgb(205, 103, 21)"
  var naranja3 = "rgb(182, 113, 18)"
  var naranja4 = "rgb(151, 109, 6)"

  const data0 ={
    /*El radio debe divirse entre 10*/
    datasets: [
      {
        label: "Micro" ,
        data:[
          {x:50, y:20, r:arrayT[0]/5},],
        backgroundColor: naranja,
      },
      {
        label: "Pequeña",
        data: [
          {x:50, y:49, r:arrayT[1]/5}],
        backgroundColor: naranja1,
      },
      {
        label: "Med.-1",
        data:[
          {x:50, y:80, r:arrayT[2]/5}],
        backgroundColor: naranja2,
      },
      {
        label: "Med.-2",
        data:[
          {x:50, y:100, r:arrayT[3]/5}],
        backgroundColor: naranja3,
      }
    ],
  }

   const data = {
    /*El radio debe divirse entre 10*/
    datasets: [
      {
        label: "Micro" ,
        data:[
          {x:50, y:20, r:arrayT[0]/5},],
        backgroundColor: naranja,
      },
      {
        label: "Pequeña",
        data: [
          {x:50, y:49, r:arrayT[1]/5}],
        backgroundColor: naranja1,
      },
      {
        label: "Med.-1",
        data:[
          {x:50, y:80, r:arrayT[2]/5}],
        backgroundColor: naranja2,
      },
      {
        label: "Med.-2",
        data:[
          {x:50, y:100, r:arrayT[3]/5}],
        backgroundColor: naranja3,
      },
      {
        label: "Agropecuario",
        data:[
          {x:150, y:20, r:arrayT[4]/5}],
        backgroundColor: naranja,
      },
      {
        label: "Ind. y mineria",
        data: [
          {x:150, y:80, r:arrayT[6]/5}],
        backgroundColor: naranja1,
      },
      {
        label: "Comercio",
        data:[
          {x:150, y:100, r:arrayT[7]/5}],
        backgroundColor: naranja3,
      },
      {
        label: "Servicios",
        data: [
          {x:150, y:60, r:arrayT[5]/5}],
        backgroundColor: naranja1,
      },
      {
        label: "Construcción",
        data: [
          {x:150, y:40, r:arrayT[8]/5}],
        backgroundColor: naranja4,
      },
      {
        label: "Hombre",
        data:[
        {x:250, y:20, r:arrayT[9]/5}],
        backgroundColor: naranja,
      },
           
      {
        label: "Mujer",
        data:[
          {x:250, y:90, r:arrayT[10]/5}],
        backgroundColor: naranja3,
      },
      {
        label: "Nueva",
        data:[
          {x:350, y:20, r:arrayT[11]/3}],
        backgroundColor: naranja,
      },
      {
        label: "Consolidaciòn",
        data: [
          {x:450, y:60, r:arrayT[12]/5}],
        backgroundColor: naranja1,
      },
      
      {
        label: "Consolidada",
        data:[
          {x:350, y:90, r:arrayT[13]/5}],
        backgroundColor: naranja3,
      },
      {
        label: "InterNa.",
        data:[
          {x:450, y:90, r:arrayT[14]/5}],
        backgroundColor: naranja3,
      },
      {
        label: "Nacional",
        data: [
          {x:340, y:60, r:arrayT[15]/5}],
        backgroundColor: naranja1,
      },
        
        {
          label: "Local",
          data:[
            {x:450, y:20, r:arrayT[16]/5}],
          backgroundColor: naranja,
        },
    ],
  };

    return(  
    <div>
      <div className="socialfgraficBUBRBUJA">
      <Bubble options={options} plugins={[ChartDataLabels]} data={data} />
      </div>
      <div className='subtituBURBUJA'>
      <ul>
      <li>Tamaño <img alt="imagen" className='imagengraficosBURBUJA'src={grafico1}></img> </li>
      <li>Sector <img alt="imagen" className='imagengraficosBURBUJA'src={grafico2}></img></li>
      <li>Propiedad <img alt="imagen" className='imagengraficosBURBUJA'src={grafico3}></img></li>
      <li>Años Operacion <img alt="imagen" className='imagengraficosBURBUJA'src={grafico4}></img></li>
      <li>Mercados <img alt="imagen" className='imagengraficosBURBUJA'src={grafico5}></img></li>
      </ul>
  </div>
   </div>
   );
   }
 
   export default Burbujas

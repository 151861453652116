import React from 'react';

import PAmbiental from './Pambiental.png';
import PSocial from './Psocial.png';
import PGobernanza from './Pgobernanza.png';
import imagen1 from './Ambiental10.jpg';
import imagen2 from './Ambiental3.png';
import imagen3 from './grafico7.jpg';
import imagen4 from './grafico10.png';
import imagen5 from './Participantes5.jpg';
import imagen6 from './grafico5.png';


/*
<img className='imagenreporte'src={grafico} alt="imagen"></img>
*/
function Tablareporte({P06}) {
 return( 
  <div >

  <div className='titulograficosREPOR'>
    <div className='numpregraficosREPOR'>P.06</div>
    <div className='colornegro'>Puntaje obtenido según variables</div>
    </div>

    <div className="divTable">
    <table className="tablareporte">
    <thead className='Anaranjado'>
        <tr>
            <th>Dimensiones</th>
            <th>Sub dimensiones</th>
            <th>Variables</th>
            <th>Puntaje <br></br> (0-100)</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td rowSpan="4"> <img className='imagenreporte'src={PAmbiental} alt="imagen"></img> <br></br>Ambiental</td>
            <td rowSpan="2"><img className='imagenreporte'src={imagen1} alt="imagen"></img><br></br>Mitigación al cambio climático</td>
            <td className='justificartexto'>1. Planificación</td>
            <td>{P06[0]}</td>
        </tr>
        <tr>
            <td className='justificartexto'>2. Implementación de actividades de mitigación</td>
            <td>{P06[1]}</td>
        </tr>
        <tr>
            <td><img className='imagenreporte'src={imagen2} alt="imagen"></img><br></br>Adaptación al cambio climático</td>
            <td className='justificartexto'>3. Implementación de actividades de adaptación</td>
            <td>{P06[2]}</td>
        </tr>
        <tr>
            
        </tr>
        <tr>
            <td rowSpan="4"> <img className='imagenreporte'src={PSocial} alt="imagen"></img><br></br>Social</td>
            <td rowSpan="2"><img className='imagenreporte'src={imagen3} alt="imagen"></img><br></br>Enfoque de género</td>
            <td className='justificartexto'>4. Diseño estratégico con enfoque de género </td>
            <td>{P06[3]}</td>
        </tr>
        <tr>
        <td className='justificartexto'>5.Participación de mujeres </td>
        <td>{P06[4]}</td>
        </tr>
        <tr>
            <td rowSpan="2"><img className='imagenreporte'src={imagen4} alt="imagen"></img><br></br>Poblaciones diversas</td>
            <td className='justificartexto'>6.Diseño estratégico con enfoque de poblaciones diversas</td>
            <td>{P06[5]}</td>
            
        </tr>
        <tr>
        <td className='justificartexto'>7. Participaciones de poblaciones diversas</td>
        <td>{P06[6]}</td>
        </tr>
        <tr>
            <td rowSpan="4"> <img className='imagenreporte'src={PGobernanza} alt="imagen"></img><br></br>Gobernanza</td>
            <td rowSpan="2"><img className='imagenreporte'src={imagen5} alt="imagen"></img><br></br>Transformación digital</td>
            <td className='justificartexto'>8.Nivel de madurez digital</td>
            <td>{P06[7]}</td>
        </tr>
        <tr>
        <td className='justificartexto'>9.Incorporación de especialistas</td>
        <td>{P06[8]}</td>
        </tr>
        <tr>
            <td rowSpan="2"><img className='imagenreporte'src={imagen6} alt="imagen"></img><br></br>Cultura organizacional</td>
            <td className='justificartexto'>10.Calidad de la gestión</td>
            <td>{P06[9]}</td>
        </tr>
        <tr>
        <td className='justificartexto'>11.Relación con el entorno</td>
        <td>{P06[10]}</td>
        </tr>
        <tr>
           
        </tr>

    </tbody>
</table>
</div>
  </div>
);
}

export default Tablareporte
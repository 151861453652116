import React from 'react';
import { useEffect, useState} from 'react'
import imagen1 from './bitcoin.png';
import imagen2 from './pencilsymbol.png';


function Tablareporte({estrategias, fechaM, fechaC, estrategiasNO,estrategiasDIF, setAmbientalP1, setAmbientalP2, setAmbientalP3, ambientalP1, ambientalP2, ambientalP3 }) {



    const [contPlanificacion,setContPlanificacion] = useState("");
    const [contMitigacion,setContMitigacion] = useState("");
    const [contAdaptacion,setContAdaptacion] = useState("");

    const [EvitarPrimmera,setEvitarPrimmera] = useState(false);

    useEffect(() => {
    var contadorPlanificacion= 0;
    var contadorMitigacion = 0;
    var contadorAdaptacion = 0;

    console.log(estrategias);

    estrategiasNO.map(function(x) {

        if (x.cuestionario.Variable === "Planificación" && x.estrategias.length !== 0) {
            x.estrategias.map(function(b) {
                if(b.marcado === "0" && (estrategiasDIF.includes( parseInt(b.NumRespuesta,10)))) {
                    contadorPlanificacion++
                }
            return contadorPlanificacion;
            });
        }

        if (x.cuestionario.Variable === "Implementación de actividades de mitigación" && x.estrategias.length !== 0) {
            x.estrategias.map(function(b) {
                if(b.marcado === "0" && (estrategiasDIF.includes( parseInt(b.NumRespuesta,10)))) {
                    contadorMitigacion++
                }
            return contadorMitigacion;
            });
        }

        if (x.cuestionario.Variable === "Implementación de actividades de adaptación" && x.estrategias.length !== 0) {
            x.estrategias.map(function(b) {
                if(b.marcado === "0" && (estrategiasDIF.includes( parseInt(b.NumRespuesta,10)))) {
                    contadorAdaptacion++
                }
            return contadorAdaptacion;
            });
        }

        return "a";
    });

    var contadorPlanificacionP = 0;
    var contadorMitigacionP = 0;
    var contadorAdaptacionP = 0;

    estrategias.map(function(x) {

        if (x.cuestionario.Variable === "Planificación" && x.estrategias.length !== 0) {
            x.estrategias.map(function(b) {
                if(b.marcado === "1" ) {
                    contadorPlanificacionP++
                }
            return contadorPlanificacionP;
            });
        }

        if (x.cuestionario.Variable === "Implementación de actividades de mitigación" && x.estrategias.length !== 0) {
            x.estrategias.map(function(b) {
                if(b.marcado === "1" ) {
                    contadorMitigacionP++
                }
            return contadorMitigacionP;
            });
        }

        if (x.cuestionario.Variable === "Implementación de actividades de adaptación" && x.estrategias.length !== 0) {
            x.estrategias.map(function(b) {
                if(b.marcado === "1" ) {
                    contadorAdaptacionP++
                }
            return contadorAdaptacionP;
            });
        }

        return "a";
    });


        if (contadorPlanificacion === 0 && contadorPlanificacionP === 0) {
            setContPlanificacion("Usted no registra actividades en esta sección.")
            
        }
    
        if (contadorMitigacion === 0 && contadorMitigacionP === 0) {
            setContMitigacion("Usted no registra actividades en esta sección.")
        }
    
        if (contadorAdaptacion === 0 && contadorAdaptacionP === 0) {
            setContAdaptacion("Usted no registra actividades en esta sección.")
        }

    

    console.log(contadorPlanificacion+" y "+ contadorMitigacion +" y "+contadorAdaptacion)

    console.log(contadorPlanificacionP+" y "+ contadorMitigacionP +" y "+contadorAdaptacionP)
    setAmbientalP1(ambientalP1+contadorPlanificacion+contadorMitigacion+contadorAdaptacion)
    setAmbientalP2(ambientalP2+contadorPlanificacion+contadorMitigacion)
    setAmbientalP3(ambientalP3+contadorAdaptacion)

    },[])


    
 return( 
  <div >

  <div className='titulograficosREPOR'>
    <div className='numpregraficosREPOR'>P.04</div>
    <div className='colornegro'>Planes de Acción según Brechas Identificadas el {fechaC}</div>
    </div>

    <div className="divTable">
    <table className="tablareporte">
    <thead>
        <tr>
            <th>Sub dimensiones</th>
            <th>Variables</th>
            <th className='estrategiaANCHO' >Planes de Accion</th>
        </tr>
    </thead>
    <tbody>
        <tr>
           
            <td rowSpan="2"><img className='imagenreporte'src={imagen1} alt="imagen"></img><br></br>Marketing de la Empresa</td>
            <td className='justificartexto'>1. Soluciones sencillas</td>
            <td className='estrategias'>

                <ul>
            {
            estrategias.map(p =>(
                <div>
                    
                    {(p.cuestionario.Variable === "Planificación" && p.estrategias.length !== 0) &&
                        <div>
                        {p.estrategias.map(b =>(                          
                            <div>
                                {(b.marcado === "1") &&
                                    <li>
                                        
                                        {b.Enunciado? b.Enunciado : ""}   
                                    </li>
                                }
                            </div>
                        ))}  
                        </div>
                    }
                </div>
            ))  
            }
                </ul>

                <ul>
            {
            estrategiasNO.map(p =>(
                <div>
                    {(p.cuestionario.Variable === "Planificación" && p.estrategias.length !== 0) &&
                        <div>
                        {p.estrategias.map(b =>(
                            <div>
                                 {(b.marcado === "0" && (estrategiasDIF.includes( parseInt(b.NumRespuesta,10)))) &&
                                    <li>
                                        {b.Enunciado? b.Enunciado : ""}   
                                    </li>
                                }
                            </div>
                        ))}  
                        </div>
                    }
                </div>
            ))  
            }
                </ul>
                <ul>
                {contPlanificacion}
                </ul>
                
                
            </td>
        </tr>
        <tr>
            <td className='justificartexto'>2. Soluciones moderadas</td>
            <td className='estrategias'>

                <ul>
                { 
            estrategias.map(p =>(
                <div>
                {(p.cuestionario.Variable === "Implementación de actividades de mitigación" && p.estrategias.length  !== 0) &&
                <div>
                {p.estrategias.map(b =>(
                    <div>
                         {(b.marcado === "1") &&
                        <li>{b.Enunciado? b.Enunciado : ""}</li>
                         }
                    </div>
                ))}  
                </div>
                }
                </div>
            ))  
             }
                </ul>

                <ul>
                { 
            estrategiasNO.map(p =>(
                <div>
                {(p.cuestionario.Variable === "Implementación de actividades de mitigación" && p.estrategias.length  !== 0) &&
                <div>
                {p.estrategias.map(b =>(
                    <div>
                        {(b.marcado === "0" && (estrategiasDIF.includes( parseInt(b.NumRespuesta,10)))) &&
                        <li>{b.Enunciado? b.Enunciado : ""}</li>
                         }
                    </div>
                ))}  
                </div>
                }
                </div>
            ))  
             }
                </ul>

                <ul>
                {contMitigacion}
                </ul>

            </td>
        </tr>
        <tr>
            <td><img className='imagenreporte'src={imagen2} alt="imagen"></img><br></br>Marketing de productos y servicios</td>
            <td className='justificartexto'>3. Soluciones complejas (requieren estrategia)</td>
            <td className='estrategias'>
                
                <ul>
                { 
            estrategias.map(p =>(
                <div>
                {(p.cuestionario.Variable === "Implementación de actividades de adaptación" && p.estrategias.length  !== 0) &&
                <div>
                {p.estrategias.map(b =>(
                    <div>
                         {(b.marcado === "1") &&
                        <li>{b.Enunciado? b.Enunciado : ""}</li>
                         }
                    </div>
                ))}  
                </div>
                }
                </div>
            ))  
               }
                </ul>

                <ul>
                { 
            estrategiasNO.map(p =>(
                <div>
                {(p.cuestionario.Variable === "Implementación de actividades de adaptación" && p.estrategias.length  !== 0) &&
                <div>
                {p.estrategias.map(b =>(
                    <div>
                         {(b.marcado === "0" && (estrategiasDIF.includes( parseInt(b.NumRespuesta,10)))) &&
                        <li>{b.Enunciado? b.Enunciado : ""}</li>
                         }
                    </div>
                ))}  
                </div>
                }
                </div>
            ))  
               }
                </ul>

                <ul>
                {contAdaptacion}
                </ul>

            </td>
        </tr>
        <tr>
            
        </tr>
        
    </tbody>
</table>
</div>
  </div>
);
}

export default Tablareporte
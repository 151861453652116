import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faDownLong} from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import { useState} from 'react'

import {Button} from '@mui/material';

function TrInforme({setInforme, informeslistado}) {
    const navigate  = useNavigate();
    
    const handleClick = event => {
        
        var numINFO = event.target.value
        var fechaM = event.target.name
        var fechaC = event.target.id
  

        console.log("NUM INFORME: "+ numINFO+ " FECHA ES: " +fechaM +" FECHA2: "+ fechaC);
        navigate('/ReporteAutodiagnostico');
        setInforme({

          NumInforme: numINFO,
          CUIT: informeslistado[0].CUIT,
          fecha_modificacion: fechaM,
          fecha_creacion: fechaC,
          RazonSocial: informeslistado[0].RazonSocial,
          

          })
      };
    
      const handleClick2 = event => {
        
        var numINFO = event.target.value
        var fechaM = event.target.name
        var fechaC = event.target.id
     

        console.log("NUM INFORME: "+ numINFO+ " FECHA ES: " +fechaM +" FECHA2: "+ fechaC);
        navigate('/ReportePlanesAccion');
        setInforme({
          NumInforme: numINFO,
          CUIT: informeslistado[0].CUIT,
          fecha_modificacion: fechaM,
          fecha_creacion: fechaC,
          RazonSocial: informeslistado[0].RazonSocial,

          })
      };

    return (
        <div>
            { 
            informeslistado.map(p =>(
            <tr>
                <td>Fecha<br></br>
                  <div>{p.fecha_creacion?.substring(0,16)}</div>
                </td>
                    {/*
                    <td>Puntaje ASG<br></br>
                    {p.PuntajeTotalASG}
                    </td>
                    
                    <td>Informe ASG<br></br>
                    <Button
                    variant="contained"

                    value={p.NumInforme}
                    name = {p.fecha_modificacion?.substring(0,16)}
                    id={p.fecha_creacion?.substring(0,16)}
               

                    sx={{ fontWeight: 'bold' }} 
                    onClick={handleClick} >
                    {p.NumInforme} <FontAwesomeIcon icon={faDownLong}/>
                    </Button>
                    </td>
                    */}

                    <td>Planes de Acción<br></br>
                    <Button
                    variant="contained"
                    
                    value={p.NumInforme}
                    name = {p.fecha_modificacion?.substring(0,16)}
                    id={p.fecha_creacion?.substring(0,16)}


                    sx={{ fontWeight: 'bold' }} 
                    onClick={handleClick2} >
                    {p.NumInforme}<FontAwesomeIcon icon={faDownLong}/>
                    </Button>
                    </td>
            </tr>
            ))  
            }
        </div>
      );
  }

export default TrInforme
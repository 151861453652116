import React from 'react';
import grafico from './shield.png';


  
  function BarrasAgrupadas3C({gobernanzaP3}) {
   return( 
    <div className= "AdopcionsquarePLAN">
    <img  alt="imagen" className='imagengraficosADOPESTRA abajo'src={grafico}></img>
    <div className='titulograficosADOP'>
    <div className='numpregraficosADOP'>P.03</div>
    <div className='colornegro'>Seguridad software</div>
    <div>{gobernanzaP3}
    </div>
    </div>
    </div>
  );
  }

  export default BarrasAgrupadas3C
import './cuerpoASG.css';
import TrInforme from './tablainforme';
import axios from 'axios'
import { useEffect, useState} from 'react'
import PantallaCarga from "./../../pantallacarga/pantallacarga"

function CuerpoASG({token, user, setInforme}) {

    const [informeslistado, setInformeslistado] = useState([]);
    const [cargado, setCargado] = useState(true)
    const [noCUIT, setNoCUIT] = useState(true)
    const [noToken, setNoToken] = useState(false)

    
    
      //PRUEBAS NOMAS
      //const base = "https://talleres.aria12.com";
      const base = "https://bid.adeneu.com.ar/autodiagnostico/index.php";

      

        useEffect(() => {
            const headers = {
                "Accept":"application/json",
                'Authorization': `Bearer ${token}`

              }
          console.log("ENVIANDO TOKEN: "+token)
          // SI NO TIENE CUIT LIGADO
          console.log("\n"+"pruebas")
          if (user.CUIT) {

             axios.get(base+"?m=informesMiPyme",{headers}).then((response)=>{

              if(response.data.message === "Token invalido"){

  
                setCargado(false)
                setNoToken(true)
                

              } else if(response.data.data.length != 0){
                
                const inf = response.data.data

                setInformeslistado(inf)
                setCargado(false)
                setNoCUIT(false)
              } 
              
              else {
                console.log ("No tiene reportes")
                setCargado(false)
              }
              

              }).catch((error)=>{
                console.error(error)
              })

          } else {
            console.log ("No esta ligado a un CUIT")
            setCargado(false)
          }

        }, []);


    return (
        <div className="container__ASG">

            <div className="presentacionASG">
            </div>
      {
      cargado
      ?
      <div>
      <PantallaCarga/>
      </div>
      :
                <div className='contenedortablalista'>

                {(noToken=== true) &&
                <div className='contenedordeimagenExtra'>

                Su sesión vencio, posiblemente ha iniciado otra sesión en un nuevo navegador, salga y vuelva a ingresar.

                </div>
                }

                {(noCUIT === true) &&
                 <div className='contenedordeimagen'>

                 No se encontraron reportes

                 </div>
                }
                
               

                {(noCUIT=== false) &&
                <table className="tablareporteLista">
                <thead>
                    <tr>
                        <th  className='center'>Reportes del RUC: {informeslistado[0].CUIT}</th>
                    </tr>
                </thead>
                <tbody>               
                    <TrInforme
                    setInforme = {setInforme}
                    informeslistado = {informeslistado}
                    ></TrInforme>
                </tbody>
                </table>
                }
                </div>

        }
        </div>
      );
  }

export default CuerpoASG
import React, { useState} from "react";
import Typography from '@mui/material/Typography';
import { Link, useNavigate } from "react-router-dom";
import { FormControl, InputLabel, Container, FormHelperText, Input, Button, Grid} from '@mui/material';
import axios from 'axios'
import PantallaCarga from "./../pantallacarga/pantallacarga"

function EscribirContra() {

    const navigate  = useNavigate(); /**/
    const [contra, setContra] = useState("")
    const [contra2, setContra2] = useState("")
    const [cargado, setCargado] = useState(false)
    const [cargado2, setCargado2] = useState(false)
    const TokenContra = localStorage.getItem('tokenContraCambio');
    const [ve, setVe] = useState({
        "Contraseña": contra
      });


    
    const CambioContra = () => {
    if (contra === contra2) {
        if (!(ve.Contraseña === "valido")){
            window.alert("Algunos campos estan mal validados");
          } else {
        
            setCargado(true);
            console.log("El token que traje es"+TokenContra)

            const headers = {
            "Accept":"application/json",
            }
            const base = "https://bid.adeneu.com.ar/autodiagnostico/index.php";
            axios.post(base+"?m=cambiarContra&token="+TokenContra, {
                "Contraseña": contra,
                "RepetirContraseña": contra2

            }, {headers}).then((response)=>{
                var Mensaje = response.data.message
                console.log(Mensaje)
                setCargado(false);
                setCargado2(true);

    
            }).catch((error)=>{
            console.error(error)
            console.log(error.response)
            setCargado(false);
            })

          }
    } else {
        window.alert("las contraseñas no son iguales");
    }
      

    }
  
      return (
        <div>
              {
            cargado
            ?
            <div>
            <PantallaCarga/>
            </div>
            :
            <form>
                {
                cargado2
                ?
                <div>
                <Container maxWidth="md">
                <Typography id="modal-modal-title" variant="h4" component="h2">
                    Su Contraseña ha sido cambiada
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 3 }}>
                    Valla a ingresar para probar
                </Typography>

                <Link to="/Loginusuario">
                    <Button  variant="contained" color="primary" sx={{ mt: 6 }}>
                    Ingresar
                    </Button>
                </Link>
                </Container>
                </div>

                :

                <Container maxWidth="md">
                <Typography id="modal-modal-title" variant="h4" component="h2">
                    Cambio de contraseña
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 3 }}>
                    Elija su nueva contraseña
                </Typography>
        
                <Grid container>
                <Grid item md={6}>
                <FormControl disabled={cargado} sx={{ mt: 6 }}>
                <InputLabel  htmlFor="Contra">Cree Contraseña</InputLabel>
                <Input id="Contra" type="password" onChange={(e)=>{
                    setContra(e.target.value)
            
                    if( /^[a-z0-9_-]{6,18}$/.test(e.target.value) ) {
                    ve.Contraseña = "valido"
                    } else {
                    ve.Contraseña = "Invalido"
                    }

                    }} aria-describedby="my-helper-text" />
                {ve.Contraseña === "" && <FormHelperText id="my-helper-text">Obligatorio</FormHelperText>}
                {ve.Contraseña !== "" && <FormHelperText id="my-helper-text">{ve.Contraseña}</FormHelperText>}
                </FormControl>
                </Grid>

                <Grid item md={6}>
                <FormControl disabled={cargado} sx={{ mt: 6 }}>
                <InputLabel  htmlFor="Contra2">Repita Contraseña</InputLabel>
                <Input id="Contra2" type="password" onChange={(e)=>setContra2(e.target.value)} aria-describedby="my-helper-text" />
                <FormHelperText id="my-helper-text">Obligatorio</FormHelperText>
                </FormControl>
                </Grid>
                </Grid>
            
            <Button  variant="contained" onClick={CambioContra} color="primary" sx={{ mt: 6 }}>
            Cambiar
            </Button>
            </Container>
            }
            </form>
            }
        </div>
      );
    
    }

    export {EscribirContra}; 
  
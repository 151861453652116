import React,{useEffect, useState} from 'react'
import {todospruebas} from './pruebaAPI.js'
import PreguntaObj2 from "../cuestionario/cards/preg/pregObj2"


const Inicio = () => {

    const templateParams = {
        Username: 'lenonmpoma@gmail.com',
        Password: '2B90384F2BA1A032728F073B28DAD0241F23',
        Server: 'smtp.elasticemail.com',
        Port: '2525',
        To : 'them@website.com',
        From : "you@isp.com",
        Subject : "This is the subject",
        Body : "And this is the body"
    };

    window.Email.send(templateParams)
    
    return(
                <div>
                    Correo de verificacion enviado
                </div>
            
    )
}

export default Inicio
import React from 'react';
import {dataformat} from "../../BaseDatos.js";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import BarrasAgrupadas19 from "./BarrasAgrupadas19";


var datos = dataformat[0]

if (typeof localStorage?.getItem('DatoAmbiental') === "string") {
  const datosTemp = JSON.parse(localStorage.getItem('DatoAmbiental'));
    if (datosTemp.length > 1){
    datos = datosTemp[19]
}
  } 

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);


export const options = {
  animation: {
         duration: 500,
  },
  maintainAspectRatio: false,
    plugins: {
      datalabels: {
        display: true,
        color: 'white',
        anchor: 'center',
        align: 'start',
        offset: -5,
        formatter: function(value, index, values) {
          if(value >0 ){
            value = value.toString();
            value = value.split(/(?=(?:...)*$)/);
            value = value.join(',');
            return value+'%';
        }else{
            value = "";
            return value;
        }
        },
        font: {
          size: 10,
        }
     },
      title: {
        display: false,
        text: 'Diferencia salarial entre hombres/mujeres',
      },
      legend: {
        position: 'bottom',
        labels: {
          boxWidth: 5,
          boxHeight: 5,
          padding: 1,
          font: {
            size: 8
        }
        }
    }
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
        ticks: {
          font: {
            size: 8,
          }
        },
      },
      y: {
      stacked: true,
      display: false
    },
  },
};


function sumarDesconocido(PGraficoMic, PGraficoPeq, PGraficoMed1, PGraficoMed2, 
  PGraficoNueva, PGraficoConsolidacion,PGraficoConsolidada,
  PGraficoAgro,PGraficoIndus,PGraficoServi,PGraficoComer,PGraficoConstruc,
  PGraficoHombre,PGraficoMujer,
  PGraficoSiPertenece,PGraficoNoPertenece){

    var Tamaño = []
    var Años = []
    var Sector = []
    var Genero = []
    var Pertenencia = []  

  for (let i = 0; i < PGraficoMic.length; i++) {

  var sumaTamaño = 100 - (PGraficoMic[i] + PGraficoPeq[i] + PGraficoMed1[i] + PGraficoMed2[i])
  var sumaAños = 100 - (PGraficoNueva[i] + PGraficoConsolidacion[i] + PGraficoConsolidada[i])
  var sumaSector = 100 - (PGraficoAgro[i] + PGraficoIndus[i] + PGraficoServi[i] + PGraficoComer[i] + PGraficoConstruc[i])
  var sumaGenero = 100 - (PGraficoHombre[i] + PGraficoMujer[i])
  var sumaPertenencia = 100 - (PGraficoSiPertenece[i] + PGraficoNoPertenece[i])

  // SI UNO SALE NEGATIVO POR ALGUN ERROR PONER POSITIVO POR EL MOMENTO PARA QUE NO SE CAIGA
 

  if (sumaTamaño !== 100) {
  if (sumaTamaño >= 0) {
    Tamaño.push(sumaTamaño)
  } else {
    Tamaño.push(sumaTamaño*-1)
  }
} else {
  Tamaño.push(0)
}
    
if (sumaAños !== 100) {
  if (sumaAños >= 0) {
    Años.push(sumaAños)
  } else {
    Años.push(sumaAños*-1)
  }
} else {
  Años.push(0)
}

if (sumaSector !== 100) {
  if (sumaSector >= 0) {
    Sector.push(sumaSector)
  } else {
    Sector.push(sumaSector*-1)
  }
} else {
  Sector.push(0)
}

if (sumaGenero !== 100) {
  if (sumaGenero >= 0) {
    Genero.push(sumaGenero)
  } else {
    Genero.push(sumaGenero*-1)
  }
} else {
  Genero.push(0)
}

if (sumaPertenencia !== 100) {
  if (sumaPertenencia >= 0) {
    Pertenencia.push(sumaPertenencia)
  } else {
    Pertenencia.push(sumaPertenencia*-1)
  }
} else {
  Pertenencia.push(0)
}

  }

  var indefinidos = []
  indefinidos.push(Tamaño)
  indefinidos.push(Años)
  indefinidos.push(Sector)
  indefinidos.push(Genero)
  indefinidos.push(Pertenencia)
  
  return indefinidos

}
  
  
  function BarrasAgrupadas20({segregacion, arrayA =dataformat[0], arrayB =dataformat[0] }) {


    var prom =arrayB.PGraficoPorcentajeRespuesta;
    const labels = [['Ahorro','Agua', prom[0]+'%'],['Calefon','Solar', prom[1]+'%'],['Eficien','Electro', prom[2]+'%'], ['Bombas','Calor', prom[3]+'%'], ['Envolv','térmica', prom[4]+'%'],['Ahorro','Ilumi', prom[5]+'%'],['Otro', prom[6]+'%'],['No', prom[7]+'%']];
    
  
    
    var indefinidos = sumarDesconocido(
    arrayB.PGraficoMic, arrayB.PGraficoPeq, arrayB.PGraficoMed1, arrayB.PGraficoMed2, 
    arrayB.PGraficoNueva, arrayB.PGraficoConsolidacion, arrayB.PGraficoConsolidada,
    arrayB.PGraficoAgro, arrayB.PGraficoIndus, arrayB.PGraficoServi, arrayB.PGraficoComer, arrayB.PGraficoConstruc,
    arrayB.PGraficoHombre, arrayB.PGraficoMujer,
    arrayB.PGraficoSiPertenece, arrayB.PGraficoNoPertenece)

  var colorIndefinido = "rgb(118, 118, 118)"

  var verde  = "rgb(163, 207, 208)"
    var verde1 = "rgb(106, 163, 147)"
    var verde2 = "rgb(68, 181, 120)"
    var verde3 = "rgb(52, 117, 69)"
    var verde4 = "rgb(24, 64, 18)"
  
    const data = {
      labels,
      datasets: [
        {
          label: 'Micro',
          data: arrayB.PGraficoMic,
          backgroundColor: verde,
        },
        {
          label: 'Pequeña',
          data:  arrayB.PGraficoPeq,
          backgroundColor:  verde1,
        },
        {
          label: 'Mediana-1',
          data: arrayB.PGraficoMed1,
          backgroundColor: verde2,
        },
        {
          label: 'Mediana-2',
          data: arrayB.PGraficoMed2,
          backgroundColor: verde3,
        },
    /*  {
        label: 'No_precisa',
        data: indefinidos[0],
        backgroundColor: colorIndefinido,
      }, */
      ],
    };
    
     const data1 = {
      labels,
      datasets: [
        {
          label: 'Nuevas',
          data:  arrayB.PGraficoNueva,
          backgroundColor: verde,
        },
        {
          label: 'En Consolidación',
          data: arrayB.PGraficoConsolidacion,
          backgroundColor: verde2,
        },
        {
          label: 'Consolidadas',
          data: arrayB.PGraficoConsolidada,
          backgroundColor: verde3,
        },
    /*  {
        label: 'No_precisa',
        data: indefinidos[0],
        backgroundColor: colorIndefinido,
      }, */
      
      ],
    };
    
     const data2 = {
      labels,
      datasets: [
        {
          label: 'Agropecuario',
          data: arrayB.PGraficoAgro,
          backgroundColor: verde,
        },
        {
          label: 'Industrial',
          data: arrayB.PGraficoIndus,
          backgroundColor: verde1,
        },
        {
          label: 'Servicio',
          data: arrayB.PGraficoServi,
          backgroundColor: verde2,
        },
        {
          label: 'Comercio',
          data: arrayB.PGraficoComer,
          backgroundColor: verde3,
        },
        {
          label: 'Construcción',
          data: arrayB.PGraficoConstruc,
          backgroundColor: verde4,
        },
    /*  {
        label: 'No_precisa',
        data: indefinidos[0],
        backgroundColor: colorIndefinido,
      }, */
      ],
    };
    
     const data3 = {
      labels,
      datasets: [
        {
          label: 'Hombre',
          data: arrayB.PGraficoHombre,
          backgroundColor: verde,
        },
        {
          label: 'Mujer',
          data: arrayB.PGraficoMujer,
          backgroundColor: verde1,
        },
    /*  {
        label: 'No_precisa',
        data: indefinidos[0],
        backgroundColor: colorIndefinido,
      }, */
      ],
    };
  
     const data4 = {
      labels,
      datasets: [
        {
          label: 'Sí',
          data: arrayB.PGraficoSiPertenece,
          backgroundColor: verde,
        },
        {
          label: 'No',
          data: arrayB.PGraficoNoPertenece,
          backgroundColor: verde1,
        },
    /*  {
        label: 'No_precisa',
        data: indefinidos[0],
        backgroundColor: colorIndefinido,
      }, */
    
      ],
    };

 return(

    <div className="PARTICIPAATRAPAR">
    
    <div  className="socialfgraficPARTICIPA">
    <BarrasAgrupadas19 segregacion={segregacion} arrayB={arrayA} />
    </div>
        {segregacion === 0 &&
    <div  className="socialfgraficPARTICIPA">
     <Bar data={data} plugins={[ChartDataLabels]} options={options}/>
    </div>
    }
        {segregacion === 1 &&
    <div  className="socialfgraficPARTICIPA">
     <Bar data={data1} plugins={[ChartDataLabels]} options={options}/>
    </div>
    }
        {segregacion === 2 &&
    <div  className="socialfgraficPARTICIPA">
     <Bar data={data2} plugins={[ChartDataLabels]} options={options}/>
    </div>
    }
        {segregacion === 3 &&
    <div  className="socialfgraficPARTICIPA">
     <Bar data={data3} plugins={[ChartDataLabels]} options={options}/>
    </div>
    }
        {segregacion === 4 &&
    <div  className="socialfgraficPARTICIPA">
     <Bar data={data4} plugins={[ChartDataLabels]} options={options}/>
    </div>
    }
    </div>
    
  );
  }

  export default BarrasAgrupadas20
import React from 'react';
import {dataformat} from "../../BaseDatos.js";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import grafico from './Ambiental3.png';
var datos = dataformat[0]

if (typeof localStorage?.getItem('DatoAmbiental') === "string") {

  const datosTemp = JSON.parse(localStorage.getItem('DatoAmbiental'));
    if (datosTemp.length > 1){
    datos = datosTemp[2]
    }
  }


ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);


export const options = {
  animation: {
         duration: 500,
  },
  maintainAspectRatio: false,
    plugins: {
      datalabels: {
        display: true,
        color: 'white',
        anchor: 'center',
        align: 'start',
        offset: -5,
        formatter: function(value, index, values) {
          if(value >0 ){
            value = value.toString();
            value = value.split(/(?=(?:...)*$)/);
            value = value.join(',');
            return value+'%';
        }else{
            value = "";
            return value;
        }
        },
        font: {
          size: 10,
        }
        /*
        padding: {
          top: 0,
        } */
     },
      title: {
        display: false,
        text: 'Capacitacion en temas de Género',
      },
      legend: {
        position: 'bottom',
        labels: {
          boxWidth: 5,
          boxHeight: 5,
          padding: 1,
          font: {
            size: 8
        }
        }
    }
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
        ticks: {
          font: {
            size: 8,
          }
        },
      },
      y: {
      stacked: true,
      display: false
    },
  },
};


function sumarDesconocido(PGraficoMic, PGraficoPeq, PGraficoMed1, PGraficoMed2, 
  PGraficoNueva, PGraficoConsolidacion,PGraficoConsolidada,
  PGraficoAgro,PGraficoIndus,PGraficoServi,PGraficoComer,PGraficoConstruc,
  PGraficoHombre,PGraficoMujer,
  PGraficoSiPertenece,PGraficoNoPertenece){

    var Tamaño = []
    var Años = []
    var Sector = []
    var Genero = []
    var Pertenencia = []  

  for (let i = 0; i < PGraficoMic.length; i++) {

  var sumaTamaño = 100 - (PGraficoMic[i] + PGraficoPeq[i] + PGraficoMed1[i] + PGraficoMed2[i])
  var sumaAños = 100 - (PGraficoNueva[i] + PGraficoConsolidacion[i] + PGraficoConsolidada[i])
  var sumaSector = 100 - (PGraficoAgro[i] + PGraficoIndus[i] + PGraficoServi[i] + PGraficoComer[i] + PGraficoConstruc[i])
  var sumaGenero = 100 - (PGraficoHombre[i] + PGraficoMujer[i])
  var sumaPertenencia = 100 - (PGraficoSiPertenece[i] + PGraficoNoPertenece[i])

  // SI UNO SALE NEGATIVO POR ALGUN ERROR PONER POSITIVO POR EL MOMENTO PARA QUE NO SE CAIGA
 

  if (sumaTamaño !== 100) {
  if (sumaTamaño >= 0) {
    Tamaño.push(sumaTamaño)
  } else {
    Tamaño.push(sumaTamaño*-1)
  }
} else {
  Tamaño.push(0)
}
    
if (sumaAños !== 100) {
  if (sumaAños >= 0) {
    Años.push(sumaAños)
  } else {
    Años.push(sumaAños*-1)
  }
} else {
  Años.push(0)
}

if (sumaSector !== 100) {
  if (sumaSector >= 0) {
    Sector.push(sumaSector)
  } else {
    Sector.push(sumaSector*-1)
  }
} else {
  Sector.push(0)
}

if (sumaGenero !== 100) {
  if (sumaGenero >= 0) {
    Genero.push(sumaGenero)
  } else {
    Genero.push(sumaGenero*-1)
  }
} else {
  Genero.push(0)
}

if (sumaPertenencia !== 100) {
  if (sumaPertenencia >= 0) {
    Pertenencia.push(sumaPertenencia)
  } else {
    Pertenencia.push(sumaPertenencia*-1)
  }
} else {
  Pertenencia.push(0)
}

  }

  var indefinidos = []
  indefinidos.push(Tamaño)
  indefinidos.push(Años)
  indefinidos.push(Sector)
  indefinidos.push(Genero)
  indefinidos.push(Pertenencia)
  
  return indefinidos

}
  





  
  function BarrasAgrupadas3({segregacion, arrayA =dataformat[0]}) {

    var prom =arrayA.PGraficoPorcentajeRespuesta;
    const labels = [
      ['Gener','Hidro', prom[0]+'%'],
    ['Panel','Solar', prom[1]+'%'],
    ['Mini','eólico', prom[2]+'%'],
    ['Resi','Urban', prom[3]+'%'],
    ['Biom', prom[4]+'%'],
    ['exte','red', prom[5]+'%'],
    ['No', prom[6]+'%']
  ]

  var indefinidos = sumarDesconocido(
    arrayA.PGraficoMic, arrayA.PGraficoPeq, arrayA.PGraficoMed1, arrayA.PGraficoMed2, 
    arrayA.PGraficoNueva, arrayA.PGraficoConsolidacion, arrayA.PGraficoConsolidada,
    arrayA.PGraficoAgro, arrayA.PGraficoIndus, arrayA.PGraficoServi, arrayA.PGraficoComer, arrayA.PGraficoConstruc,
    arrayA.PGraficoHombre, arrayA.PGraficoMujer,
    arrayA.PGraficoSiPertenece, arrayA.PGraficoNoPertenece)

  var colorIndefinido = "rgb(118, 118, 118)"

  var verde  = "rgb(163, 207, 208)"
  var verde1 = "rgb(106, 163, 147)"
  var verde2 = "rgb(68, 181, 120)"
  var verde3 = "rgb(52, 117, 69)"
  var verde4 = "rgb(24, 64, 18)"

        const data = {
    labels,
    datasets: [
      {
        label: 'Micro',
        data: arrayA.PGraficoMic,
        backgroundColor: verde,
      },
      {
        label: 'Pequeña',
        data:  arrayA.PGraficoPeq,
        backgroundColor:  verde1,
      },
      {
        label: 'Mediana-1',
        data: arrayA.PGraficoMed1,
        backgroundColor: verde2,
      },
      {
        label: 'Mediana-2',
        data: arrayA.PGraficoMed2,
        backgroundColor: verde3,
      },
    /*  {
        label: 'No_precisa',
        data: indefinidos[0],
        backgroundColor: colorIndefinido,
      }, */
    ],
  };
  
   const data1 = {
    labels,
    datasets: [
      {
        label: 'Nuevas',
        data:  arrayA.PGraficoNueva,
        backgroundColor: verde,
      },
      {
        label: 'En Consolidación',
        data: arrayA.PGraficoConsolidacion,
        backgroundColor: verde2,
      },
      {
        label: 'Consolidadas',
        data: arrayA.PGraficoConsolidada,
        backgroundColor: verde3,
      },
    /*  {
        label: 'No_precisa',
        data: indefinidos[0],
        backgroundColor: colorIndefinido,
      }, */
    
    ],
  };
  
   const data2 = {
    labels,
    datasets: [
      {
        label: 'Agropecuario',
        data: arrayA.PGraficoAgro,
        backgroundColor: verde,
      },
      {
        label: 'Industrial',
        data: arrayA.PGraficoIndus,
        backgroundColor: verde1,
      },
      {
        label: 'Servicio',
        data: arrayA.PGraficoServi,
        backgroundColor: verde2,
      },
      {
        label: 'Comercio',
        data: arrayA.PGraficoComer,
        backgroundColor: verde3,
      },
      {
        label: 'Construcción',
        data: arrayA.PGraficoConstruc,
        backgroundColor: verde4,
      },
    /*  {
        label: 'No_precisa',
        data: indefinidos[0],
        backgroundColor: colorIndefinido,
      }, */
    ],
  };
  
   const data3 = {
    labels,
    datasets: [
      {
        label: 'Hombre',
        data: arrayA.PGraficoHombre,
        backgroundColor: verde,
      },
      {
        label: 'Mujer',
        data: arrayA.PGraficoMujer,
        backgroundColor: verde1,
      },
    /*  {
        label: 'No_precisa',
        data: indefinidos[0],
        backgroundColor: colorIndefinido,
      }, */
    ],
  };

   const data4 = {
    labels,
    datasets: [
      {
        label: 'Sí',
        data: arrayA.PGraficoSiPertenece,
        backgroundColor: verde,
      },
      {
        label: 'No',
        data: arrayA.PGraficoNoPertenece,
        backgroundColor: verde1,
      },
    /*  {
        label: 'No_precisa',
        data: indefinidos[0],
        backgroundColor: colorIndefinido,
      }, */
  
    ],
  };
 return( 
    <div>
    {segregacion === 0 &&
    <div>
    <img className='imagengraficos'src={grafico} alt="imagen"></img>
    <div className='titulograficos'>
    <div className='numpregraficos'>P.03</div>
    <div className='colornegro'> Energía Eléctrica de Fuentes Renovables</div>
    <div>{arrayA.Noporcentaje}%
    <div>Panel solar</div>
    </div>
    </div>
    <div className='subtitulograficos'>
     <ul className='Amb'>
     <li>Mic. = {arrayA.MicPorcentaje}% </li>
      <li>Peq. = {arrayA.PeqPorcentaje}%</li>
      <li>Med1. = {arrayA.Med1Porcentaje}%</li>
      <li>Med2. = {arrayA.Med2Porcentaje}%</li>
    </ul>
  </div>
    <div className="socialfgrafic">
      
     <Bar data={data} plugins={[ChartDataLabels]} options={options}/>
    </div>
    </div>
    }

{segregacion === 1 &&
    <div>
    <img className='imagengraficos'src={grafico} alt="imagen"></img>
    <div className='titulograficos'>
    <div className='numpregraficos'>P.03</div>
    <div className='colornegro'> Energía Eléctrica de Fuentes Renovables</div>
    <div>{arrayA.Noporcentaje}%
    <div>Panel solar</div>
    </div>
    </div>
    <div className='subtitulograficos'>
     <ul className='Amb'>
     <li>Nueva = {arrayA.NuevaPorcentaje}%</li>
      <li>En Consolidación= {arrayA.ConsolidacionPorcentaje}%</li>
      <li>Consolidada = {arrayA.ConsolidadasPorcentaje}%</li>
    </ul>
  </div>
    <div className="socialfgrafic">
      
     <Bar data={data1} plugins={[ChartDataLabels]} options={options}/>
    </div>
    </div>
    }

{segregacion === 2 &&
    <div>
    <img className='imagengraficos'src={grafico} alt="imagen"></img>
    <div className='titulograficos'>
    <div className='numpregraficos'>P.03</div>
    <div className='colornegro'> Energía Eléctrica de Fuentes Renovables</div>
    <div>{arrayA.Noporcentaje}%
    <div>Panel solar</div>
    </div>
    </div>
    <div className='subtitulograficos'>
     <ul className='Amb'>
     <li>Agr. = {arrayA.AgropecuarioPorcentaje}%</li>
      <li>Ind. = {arrayA.IndustriaPorcentaje}%</li>
      <li>Ser. = {arrayA.ServiciosPorcentaje}%</li>
      <li>Com. = {arrayA.ComercioPorcentaje}%</li>
      <li>Con. = {arrayA.ConstrucciónPorcentaje}%</li>
    </ul>
  </div>
    <div className="socialfgrafic">
      
     <Bar data={data2} plugins={[ChartDataLabels]} options={options}/>
    </div>
    </div>
    }

    {segregacion === 3 &&
    <div>
    <img className='imagengraficos'src={grafico} alt="imagen"></img>
    <div className='titulograficos'>
    <div className='numpregraficos'>P.03</div>
    <div className='colornegro'> Energía Eléctrica de Fuentes Renovables</div>
    <div>{arrayA.Noporcentaje}%
    <div>Panel solar</div>
    </div>
    </div>
    <div className='subtitulograficos'>
     <ul className='Amb'>
     <li>H. = {arrayA.MasculinoPorcentaje}%</li>
      <li>M. = {arrayA.FemeninoPorcentaje}% </li>
    </ul>
  </div>
    <div className="socialfgrafic">
      
     <Bar data={data3} plugins={[ChartDataLabels]} options={options}/>
    </div>
    </div>
    }
        {segregacion === 4 &&
    <div>
    <img className='imagengraficos'src={grafico} alt="imagen"></img>
    <div className='titulograficos'>
    <div className='numpregraficos'>P.03</div>
    <div className='colornegro'> Energía Eléctrica de Fuentes Renovables</div>
    <div>{arrayA.Noporcentaje}%
    <div>Panel solar</div>
    </div>
    </div>
    <div className='subtitulograficos'>
     <ul className='Amb'>
     <li>Sí. = {arrayA.PertenecePorcentaje}%  </li>
    <li>No. = {arrayA.NoPertenecePorcentaje}% </li>
    </ul>
  </div>
    <div className="socialfgrafic">
      
     <Bar data={data4} plugins={[ChartDataLabels]} options={options}/>
    </div>
    </div>
    }
    </div>
  );
  }

  export default BarrasAgrupadas3
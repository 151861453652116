export const dataformat = [
    {
        "Noporcentaje": "0",
        "MicPorcentaje": "0",
        "PeqPorcentaje": "0",
        "Med1Porcentaje": "0",
        "Med2Porcentaje": "0",
        "NuevaPorcentaje": "0",
        "ConsolidacionPorcentaje": "0",
        "ConsolidadasPorcentaje": "0",
        "AgropecuarioPorcentaje": "0",
        "IndustriaPorcentaje": "0",
        "ComercioPorcentaje": "0",
        "ServiciosPorcentaje": "0",
        "ConstrucciónPorcentaje": "0",
        "MasculinoPorcentaje": "0",
        "FemeninoPorcentaje": "0",
        "PertenecePorcentaje": "0",
        "NoPertenecePorcentaje": "0",
        "PGraficoConstruc": [
            0,
            0,
            0,
            0,
            0,
            0,
            0
        ],
        "PGraficoServi": [
            0,
            0,
            0,
            0,
            0,
            0,
            0
        ],
        "PGraficoMed2": [
            0,
            0,
            0,
            0,
            0,
            0,
            0
        ],
        "PGraficoMic": [
            0,
            0,
            0,
            0,
            0,
            0,
            0
        ],
        "PGraficoNueva": [
            0,
            0,
            0,
            0,
            0,
            0,
            0
        ],
        "PGraficoAgro": [
            0,
            0,
            0,
            0,
            0,
            0,
            0
        ],
        "PGraficoNoPertenece": [
            0,
            0,
            0,
            0,
            0,
            0,
            0
        ],
        "PGraficoPeq": [
            0,
            0,
            0,
            0,
            0,
            0,
            0
        ],
        "PGraficoConsolidacion": [
            0,
            0,
            0,
            0,
            0,
            0,
            0
        ],
        "PGraficoIndus": [
            0,
            0,
            0,
            0,
            0,
            0,
            0
        ],
        "PGraficoSiPertenece": [
            0,
            0,
            0,
            0,
            0,
            0,
            0
        ],
        "PGraficoMed1": [
            0,
            0,
            0,
            0,
            0,
            0,
            0
        ],
        "PGraficoConsolidada": [
            0,
            0,
            0,
            0,
            0,
            0,
            0
        ],
        "PGraficoComer": [
            0,
            0,
            0,
            0,
            0,
            0,
            0
        ],
        "PGraficoHombre": [
            0,
            0,
            0,
            0,
            0,
            0,
            0
        ],
        "PGraficoMujer": [
            0,
            0,
            0,
            0,
            0,
            0,
            0
        ],
        "PGraficoPorcentajeRespuesta": [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0
        ]
    }
  ]
  ;

  export const dataformat2 = [
    {
        "PromPertenenciaMic": "0",
        "FemeninoPorcentaje": "0",
        "MasculinoPorcentaje": "0.",
        "TOTALMicPorcF": "0",
        "TOTALMicPorcM": "0",
        "TOTALPeqPorcF": "0",
        "TOTALPeqPorcM": "0",
        "TOTALMed1PorcF": "0",
        "TOTALMed1PorcM": "0",
        "TOTALMed2PorcF": "0",
        "TOTALMed2PorcM": "0",
        "Apromedio": "0",
        "ApromedioMic": "0",
        "ApromedioPeq": "0",
        "ApromedioMed1": "0",
        "ApromedioMed2": "0",
        "GraMicGenero": [
            0,
            0
        ],
        "GraPeqGenero": [
            0,
            0
        ],
        "GraMed1Genero": [
            0,
            0
        ],
        "GraMed2Genero": [
            0,
            0
        ],
        "GraCabeceraGenero": [
            0,
            0
        ],
        "GraMicZona": [
            0,
            0,
            0
        ],
        "GraPeqZona": [
            0,
            0,
            0
        ],
        "GraMed1Zona": [
            0,
            0,
            0
        ],
        "GraMed2Zona": [
            0,
            0,
            0
        ],
        "GraCabeceraZona": [
            0,
            0,
            0
        ],
        "GraMicDestino": [
            0,
            0,
            0,
            0,
            0,
            0
        ],
        "GraPeqDestino": [
            0,
            0,
            0,
            0,
            0,
            0
        ],
        "GraMed1Destino": [
            0,
            0,
            0,
            0,
            0,
            0
        ],
        "GraMed2Destino": [
            0,
            0,
            0,
            0,
            0,
            0
        ],
        "GraCabeceraDestino": [
            0,
            0,
            0,
            0,
            0,
            0
        ],
        "GraMicEconomico": [
            0,
            0,
            0,
            0,
            0
        ],
        "GraPeqEconomico": [
            0,
            0,
            0,
            0,
            0
        ],
        "GraMed1Economico": [
            0,
            0,
            0,
            0,
            0
        ],
        "GraMed2Economico": [
            0,
            0,
            0,
            0,
            0
        ],
        "GraCabeceraEconomico": [
            0,
            0,
            0,
            0,
            0
        ],
        "GraMicRubro": [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0
        ],
        "GraPeqRubro": [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0
        ],
        "GraMed1Rubro": [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0
        ],
        "GraMed2Rubro": [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0
        ],
        "GraCabeceraRubro": [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0
        ],
        "GraMicCargo": [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0
        ],
        "GraPeqCargo": [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0
        ],
        "GraMed1Cargo": [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0
        ],
        "GraMed2Cargo": [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0
        ],
        "GraCabeceraCargo": [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0
        ],
        "GeneroCabeceraMic": "0",
        "GeneroCabeceraPeq": "0",
        "GeneroCabeceraMed1": "0",
        "GeneroCabeceraMed2": "0",
        "ZonaCabeceraMic": "0",
        "ZonaCabeceraPeq": "0",
        "ZonaCabeceraMed1": "0",
        "ZonaCabeceraMed2": "0",
        "DestinoCabeceraMic": "0",
        "DestinoCabeceraPeq": "0",
        "DestinoCabeceraMed1": "0",
        "DestinoCabeceraMed2": "0",
        "SectorCabeceraMic": "0",
        "SectorCabeceraPeq": "0",
        "SectorCabeceraMed1": "0",
        "SectorCabeceraMed2": "0",
        "RubroCabeceraMic": "0",
        "RubroCabeceraPeq": "0",
        "RubroCabeceraMed1": "0",
        "RubroCabeceraMed2": "0",
        "CargoCabeceraMic": "0",
        "CargoCabeceraPeq": "0",
        "CargoCabeceraMed1": "0",
        "CargoCabeceraMed2": "0"
    }
];

export const socialPromedios = [
    {

            "PTotal": "0",
            "PMic": "0",
            "PPeq": "0",
            "PMed1": "0",
            "PMed2": "0",
            "PNueva": "0",
            "PConsolidacion": "0",
            "PConsolidada": "0",
            "PAgropecuario": "0",
            "PIndustrial": "0",
            "PServicio": "0",
            "PComercio": "0",
            "PConstruccion": "0",
            "PHombre": "0",
            "PMujer": "0",
            "PSi": "0",
            "PNo": "0"
    }
];

export const socialDiferencias = [
    {
        "PNoexiste": "0",
        "PSiexiste": "0",
        "PSI_Mic": "0",
        "PSI_Peq": "0",
        "PSI_Med1": "0",
        "PSI_Med2": "0",
        "PSI_Nueva": "0",
        "PSI_Consolidacion": "0",
        "PSI_Consolidada": "0",
        "PSI_Agropecuario": "0",
        "PSI_Industria": "0",
        "PSI_Comercio": "0",
        "PSI_Servicios": "0",
        "PSI_Construcción": "0",
        "PSI_HOMBRE": "0",
        "PSI_MUJER": "0",
        "PSI_SI": "0",
        "PSI_NO": "0",
        "PNO_Mic": "0",
        "PNO_Peq": "0",
        "PNO_Med1": "0",
        "PNO_Med2": "0",
        "PNO_Nueva": "0",
        "PNO_Consolidacion": "0",
        "PNO_Consolidada": "0",
        "PNO_Agropecuario": "0",
        "PNO_Industria": "0",
        "PNO_Comercio": "0",
        "PNO_Servicios": "0",
        "PNO_Construcción": "0",
        "PNO_HOMBRE": "0",
        "PNO_MUJER": "0",
        "PNO_SI": "0",
        "PNO_NO": "0"
    }
];
import React from 'react';
import grafico from './Participantes7B.png';
import grafico2 from './Participantes8.png';



  function DobleTexto({arrayP}) {


   return( 
    <div>
    <img alt="imagen" className='imagengraficosPARTICIPA'src={grafico}></img>
    <div className='titulograficosPARTICIPA'>
    <div className='numpregraficosPARTICIPA'>P.07</div>
    <div className='colornegro'>Rubro Sectorial</div>
    <div>{arrayP.GraCabeceraRubro[0]}%
    <div>Agricultura</div>
    </div>
    </div>
    <div className='subtitulograficosPARTICIPA'>
    <ul className='Par'>
      <li>Mic. = {arrayP.RubroCabeceraMic}%</li>
      <li>Peq. =  {arrayP.RubroCabeceraPeq}%</li>
      <li>Med1. =  {arrayP.RubroCabeceraMed1}%</li>
      <li>Med2. =  {arrayP.RubroCabeceraMed2}%</li>
    </ul>

 <img alt="imagen" className='imagengraficosPARTICIPA'src={grafico2}></img>
    <div className='titulograficosPARTICIPA'>
    <div className='numpregraficosPARTICIPA'>P.08</div>
    <div className='colornegro'> Cargo del Encuestado</div>
    <div>{arrayP.GraCabeceraCargo[0]}%
    <div>Propietario</div>
    </div>
    
    </div>
    <div className='subtitulograficosPARTICIPA'>
    <ul className='Par'>
    <li>Mic. = {arrayP.CargoCabeceraMic}%</li>
      <li>Peq. =  {arrayP.CargoCabeceraPeq}%</li>
      <li>Med1. =  {arrayP.CargoCabeceraMed1}%</li>
      <li>Med2. =  {arrayP.CargoCabeceraMed2}%</li>
    </ul>
  </div>
  </div>
    </div>
  );
  }

  export default DobleTexto
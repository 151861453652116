import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

interface IQueryState {
    [key: string]: string;
}

export function useQueryParams(){
    
    const[queries, setQueries] = useState<IQueryState>({} as IQueryState)
    const {search} = useLocation();

    const OnDecodeParams = useCallback((params: string) => {
        const quitarprimercaracter = params.replace("?","");
        const spliteado = quitarprimercaracter.split("&");
        
        const formatoQueies = {} as IQueryState;

        //DAR FORMATEO
        spliteado.forEach(query =>{
            const [key, value] = query.split("=");
            Object.assign(formatoQueies, {
                [key]: value
            })
        })
        setQueries(formatoQueies)
    },[])

    useEffect(() => {
        if(search.trim()){
            OnDecodeParams(search);
        }
    },[OnDecodeParams, search])

    return queries
}
import React from 'react';
import grafico from './bitcoin.png';


  
  function BarrasAgrupadas2({ambientalP2}) {
   return( 
    <div className= "AdopcionsquarePLAN">
    <img  alt="imagen" className='imagengraficosADOPESTRA abajo'src={grafico}></img>
    <div className='titulograficosADOP'>
    <div className='numpregraficosADOP'>P.02</div>
    <div className='colornegro'>Marketing de la Empresa</div>
    <div>{ambientalP2}
    </div>
    </div>
    </div>
  );
  }

  export default BarrasAgrupadas2
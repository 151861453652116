import {VerificarCorreo}  from "../registro.tsx"

function VerficarCorreo({perfilR, setUser, setToken}) {

    
    return (
        <div className="container__finalauto">
            <div className="presentacionfinalauto">
            <h1>Verifique su correo electronico</h1>
            <h2>Se ha enviado un mensaje a su correo electrónico para validarlo, por favor verifique su bandeja de correo, también la carpeta de spam o "no deseados"</h2>
            </div>

            <div className="Botonesfinalauto">

            <VerificarCorreo
             setToken={setToken}
             perfilR={perfilR}
             setUser={setUser}
            />

            </div>

        </div>
      );
  }

export default VerficarCorreo
import React from 'react';
import grafico from './wi fi router.png';


  
  function BarrasAgrupadas3B({socialP3}) {
   return( 
    <div className= "AdopcionsquarePLAN">
    <img  alt="imagen" className='imagengraficosADOPESTRA'src={grafico}></img>
    <div className='titulograficosADOP'>
    <div className='numpregraficosADOP'>P.03</div>
    <div className='colornegro'>Integración de Software</div>
    <div>{socialP3}
    </div>
    </div>
    </div>
  );
  }

  export default BarrasAgrupadas3B
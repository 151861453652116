import './mipymeadopcion.css';

import {Button} from '@mui/material';
import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import axios from 'axios'
import PantallaCarga from "./../../pantallacarga/pantallacarga.js"

import BarrasAgrupadas from "./graficos/BarrasAgrupadas"
import BarrasAgrupadas2 from "./graficos/BarrasAgrupadas2"
import BarrasAgrupadas3 from "./graficos/BarrasAgrupadas3"
import BarrasAgrupadas4 from "./graficos/BarrasAgrupadas4"
import BarrasHorizontales from "./graficos/BarrasHorizontales"
import BarrasHorizontales2 from "./graficos/BarrasHorizontales2"
import BarrasHorizontales3 from "./graficos/BarrasHorizontales3"
import GraficoRombo from "./graficos/graficos"
import Bubble from "./graficos/Burbujas"

import { BotonImprimir,Fechas, Seleccion }  from "../imprimir.tsx"
import grafico1 from "./graficoNara2.png"


function Mipymeadopcion({user,setUser}) {

    const obtenerFechaActual = () => 
    {
      var date = new Date();
      var fechaActual = date;
      var fechaA = fechaActual.toLocaleString("es-ES").substring(0,16)
      return fechaA;
    };

      /*Cambio de funciones*/
      const [segregacion, setSegregacion] = useState(0);
      const [fechaInicio, setFechaInicio] = useState("2022-01-01 00:00:00");
      const [fechaFinal, setFechaFinal] = useState("2200-01-01 00:00:00");
      const [tipo, setTipo] = useState("Adopción")
      const [arrayA, setArrayA] = useState([])
      const [fechaAct, setFechaAct] = useState("00/00/0000")

      useEffect(() => {
        console.log("EL PROMEDIO GLOBAL DE ADOP: "+arrayA.PromedioGOBLAL)
        if(typeof arrayA !== "undefined") {
        var fechaACtual = obtenerFechaActual()
        setFechaAct(fechaACtual)
        localStorage.setItem('DatoAdop', JSON.stringify(arrayA));}
    },[arrayA])
    
    return (
        <div>
        
            <div className="cuerpomreporteinicio">
                <div>
                    <div className='Anaranjado titulo'>Reporte del Índice de Adopción de Criterios ASG </div>
                    
                    <div className="BotonComenzar">
                        <BotonImprimir
                            user = {user}
                            setUser = {setUser}
                            fechaInicio = {fechaInicio}
                            fechaFinal = {fechaFinal}
                            tipo = {tipo}
                            setArrayR = {setArrayA}
                            segregacion={segregacion}
                            setSegregacion={setSegregacion}
                            />
                        </div>

                </div>

            </div>
            
            <div className="insertefechas">
                <Fechas
                setFechaInicio = {setFechaInicio}
                setFechaFinal = {setFechaFinal}
                />
                <Seleccion
                segregacion={segregacion}
                setSegregacion={setSegregacion}
                ></Seleccion>
            </div>
            
            <div>
            
            </div>
            <div className="RellenoADOP">aaaa</div>
            

            <div  className="graficosadopcionGRID">
                <div className="elemento1 NARANJA">
                <img className='imagenGRAFICO1'src={grafico1} alt="imagen numero"></img>
                Reporte de Adopción de Criterios ASG 
                <h4>Fecha de corte: {fechaAct}</h4>
                </div>
                    <div className="chiADO">
                    <BarrasAgrupadas  arrayA={arrayA.PromedioGOBLAL}/>
                    </div>
                    <div className="chiADO elementofusion">
                    <GraficoRombo   PromA={arrayA.PromedioAMBIENTAL}  PromS={arrayA.PromedioSOCIAL}  PromG={arrayA.PromedioGOBERNANZA}/>
                    </div>
                    <div className="chiADO">
                    <BarrasAgrupadas2  arrayA={arrayA.PromMic} />
                    </div>
                    <div className="chiADO">
                    <BarrasAgrupadas3  arrayA={arrayA.PromPeq}/>
                    </div>
                    <div className="chiADO">
                    <BarrasAgrupadas4   arrayA={arrayA.PromMed1} arrayB={arrayA.PromMed2}/>
                    </div>
                    <div className="chiADO">
                    <BarrasHorizontales 
                    segregacion={segregacion}
                    arrayA={[
                        arrayA.PromedioMed2A,
                        arrayA.PromedioMed1A,
                        arrayA.PromedioPeqA,
                        arrayA.PromedioMicroA,
                        arrayA.PromedioNuevaA,
                        arrayA.PromedioConsolidacionA,
                        arrayA.PromedioConsolidadaA,
                        arrayA.PromedioAgroA,
                        arrayA.PromedioIndA,
                        arrayA.PromedioServiA,
                        arrayA.PromedioComA,
                        arrayA.PromedioConsA,
                        arrayA.PromedioHA,
                        arrayA.PromedioMA,
                        arrayA.PromedioNOparA,
                        arrayA.PromedioSIparA,
                        arrayA.PromedioAMBIENTAL
                        ]}/>
                    </div>
                    <div className="chiADO">
                    <BarrasHorizontales2 
                    segregacion={segregacion}
                    arrayS={[
                        arrayA.PromedioMed2S,
                        arrayA.PromedioMed1S,
                        arrayA.PromedioPeqS,
                        arrayA.PromedioMicroS,
                        arrayA.PromedioNuevaS,
                        arrayA.PromedioConsolidacionS,
                        arrayA.PromedioConsolidadaS,
                        arrayA.PromedioAgroS,
                        arrayA.PromedioIndS,
                        arrayA.PromedioServiS,
                        arrayA.PromedioComS,
                        arrayA.PromedioConsS,
                        arrayA.PromedioHS,
                        arrayA.PromedioMS,
                        arrayA.PromedioNOparS,
                        arrayA.PromedioSIparS,
                        arrayA.PromedioSOCIAL,
                        ]}/>
                    </div>
                    <div className="chiADO">
                    <BarrasHorizontales3
                    segregacion={segregacion}
                    arrayG={[
                        arrayA.PromedioMed2G,
                        arrayA.PromedioMed1G,
                        arrayA.PromedioPeqG,
                        arrayA.PromedioMicroG,
                        arrayA.PromedioNuevaG,
                        arrayA.PromedioConsolidacionG,
                        arrayA.PromedioConsolidadaG,
                        arrayA.PromedioAgroG,
                        arrayA.PromedioIndG,
                        arrayA.PromedioServiG,
                        arrayA.PromedioComG,
                        arrayA.PromedioConsG,
                        arrayA.PromedioHG,
                        arrayA.PromedioMG,
                        arrayA.PromedioNOparG,
                        arrayA.PromedioSIparG,
                        arrayA.PromedioGOBERNANZA,
                        ]}/>
                    </div>
                    <div className="chiADO elementofusion2">
                    <Bubble  segregacion={segregacion} arrayT={[
                        arrayA.CirculoMicP,
                        arrayA.CirculoPeqP,
                        arrayA.CirculoMed1P,
                        arrayA.CirculoMed2P,
                        arrayA.CirculoAgroP,
                        arrayA.CirculoServiP,
                        arrayA.CirculoIndP,
                        arrayA.CirculoComP,
                        arrayA.CirculoConsP,
                        arrayA.CirculoHP,
                        arrayA.CirculoMP,
                        arrayA.CirculoNuevaP,
                        arrayA.CirculoConsolidacionP,
                        arrayA.CirculoConsolidadaP,
                        arrayA.CirculoInternacionalP,
                        arrayA.CirculoNacionalP,
                        arrayA.CirculoLocalP
                    ]}/>
                    </div>

            </div>

        </div>
      );
  }

export default Mipymeadopcion
import React, {Component} from "react";

import { Radar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from 'chart.js';


ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);


export const options ={
  
	responsive: true,
	animation: {
         duration: 500,
  },
  maintainAspectRatio: false,
  plugins: {
    datalabels: {
      display: true,
      color: "#000",
      font: 50,
   },
    title: {
      display: false,
      text: 'GRAFICO ROMBO',
    },
    legend: {
      display: false,
      position: 'left',
      labels: {
        boxWidth: 5,
        boxHeight: 5,
        padding: 1,
          font: {
            size: 8
        }
      }
  }
  },
  
	scales:{
		r: { 
      /* REMOVER TEXTO DE LOS EXTREMOS
      pointLabels: {
        display: false 
    }, */
    ticks: {
      display: false // Hides the labels in the middel (numbers)
  },
            angleLines: {
                display: true,
                color: "black"
            },
            suggestedMin: 0,
            suggestedMax: 75
        }
    }
};

export const data = {
  labels: [['Sub Indice Ambiental','75'], '75 Subíndice Social', 'Sub Indice Social 75'],
  datasets: [
    {
      label: 'Mi Mype',
      data: [56, 65, 36],
      backgroundColor: 'rgba(255, 99, 82, 0.2)',
      borderColor: 'rgba(255, 99, 20, 22)',
      borderWidth: 5,
    },
  ],
};

function GraficoRombo({PromA, PromS, PromG}) {

   const data = {
    labels: [['Sub Indice Ambiental','100'], ['Subíndice', 'Social 100'], ['Subíndice', 'Gobernanza 100']],
    datasets: [
      {
        label: 'Mi Mype',
        data: [PromA, PromS,  PromG],
        backgroundColor: 'rgba(255, 99, 82, 0.2)',
        borderColor: 'rgba(255, 99, 20, 22)',
        borderWidth: 5,
      },
    ],
  };

 return( 
 	<div className="socialfgraficADOP">
 	<Radar data={data} plugins={[ChartDataLabels]} options={options}/>
	</div>
);
}

export default GraficoRombo

import React,{useState} from "react";
import './barravertical.css';
import { Link } from "react-router-dom";

import adeneu from './SanJuanfondo.jpg';



/*ESTO SIRVE PARA PODER OCULTAR LOS BOTONES DE LOGEADO Y NO LOGEADO*/


function Barravertical({user, setUser}) {
  /*BOTON DESLOGEO*/
  const deslogeo = () => {
    setUser({
      IDperfil: null,
      CUIT: null,
      Nombre: "prueba",
      NumTipoUsuario: 0,
      Correo: null,
      CorreoVerificado: 0
    });
    localStorage.clear();
  }

    return (
      <div className="verticalBarra">
         <div>
        <div className="btn-hamburger"></div>
        <div className="btn-hamburger"></div>
        <div className="btn-hamburger"></div>
        </div>

        <img className='imagenvertical'src={adeneu} alt="logo"></img>

        <div className="lista">
        <ul> Listar:
          <li>Usuarios</li>
          <li><Link to='/ReportesTodos'>Informes</Link></li>
        </ul>
        <ul> Reportes:
          <li><Link to='/ReporteParticipantes'>(1) Reporte de Empresas Participantes del Neuquén</Link></li>
          <li><Link to='/ReporteAdopcion'>(2) Reporte del Índice de Adopción de Criterios ASG</Link></li>
          <li><Link to='/ReporteAmbiental'>(3) Reporte de adopción de criterio ambiental</Link></li>
          <li><Link to='/ReporteSocial'>(4) Reporte de adopción de criterio social</Link></li>
          <li><Link to='/ReporteGobernanza'>(5) Reporte de adopción de criterio de gobernanza</Link></li>
          <li><Link to='/autodiagnostico' onClick={deslogeo}>DESLOGEARSE</Link></li>
        </ul>
        </div>
      </div>
      );
  }

export default Barravertical
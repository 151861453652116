import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Link } from "react-router-dom";
import Modal from '@mui/material/Modal';
import { FormControl, InputLabel, Container, FormHelperText, Input, Button, Grid, Menu, MenuItem, Avatar} from '@mui/material';
import { deepOrange, deepPurple } from '@mui/material/colors';
import Select, { SelectChangeEvent } from '@mui/material/Select';



const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function NombreUsuario({user, setUser}){
  
  const [nom, setNom] = React.useState('');
  const handleChange = (event: SelectChangeEvent) => {
    setNom(event.target.value as string);}

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseSALIR = () => {
    setAnchorEl(null);
    localStorage.clear();
    setUser({
      IDperfil: null,
      CUIT: null,
      Nombre: "prueba",
      NumTipoUsuario: 0,
      Correo: null,
      CorreoVerificado: 0
    });
    
  };

  return(
    <div className='NombrePerfil'>
    <Container>
      <Grid item md={6}>
        <FormControl>

      <Avatar 
      
      sx={{ bgcolor: deepOrange[500] }}
        id="demo-positioned-button"
        aria-controls={open ? 'demo-positioned-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick} >
        {user.Nombre.substring(0,2)}
      </Avatar >


      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Link to="/perfil"><MenuItem onClick={handleClose}>Perfil</MenuItem></Link>
        <Link to="/autodiagnostico"><MenuItem onClick={handleCloseSALIR}>Salir</MenuItem></Link>
        <Link to="/CorreoEnvio"><MenuItem onClick={handleClose}>Correo</MenuItem></Link>
      </Menu>
        </FormControl>
      </Grid>
    </Container>

    </div>
  );
}
 
function LoginModal({ tipocabecera, setTipocabecera }) {

  const logeo = () => setTipocabecera(tipocabecera - 1)

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
  
    return (
      <div>
        {/*PONER BUTTON EN VEZ DE A*/}

        <Button
        variant="contained"
        sx={{ fontWeight: 'bold' }} 
        onClick={handleOpen} >
        Acceder
      </Button>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>

        <Container maxWidth="md">
          <Typography id="modal-modal-title" variant="h4" component="h2">
              Acceso
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 3 }}>
              Ingrese a su cuenta:
            </Typography>

        <Grid container>
        <Grid item md={6}>
          <FormControl sx={{ mt: 6 }}>
            <InputLabel htmlFor="correo">Correo</InputLabel>
            <Input id="correo" aria-describedby="my-helper-text" />
          </FormControl>
        </Grid>

        <Grid item md={6}>
        <FormControl sx={{ mt: 6 }}>
          <InputLabel htmlFor="password">Contraseña</InputLabel>
          <Input id="password" aria-describedby="my-helper-text" />
        </FormControl>
        </Grid>

        <Link to="/ReporteParticipantes">
        <Button onClick={logeo} variant="contained" color="primary" sx={{ mt: 12 }}>
        Ingresar
        </Button>
        </Link>

        </Grid>
        </Container>

          </Box>
        </Modal>
      </div>
    );
  }



function RegistroModal({ tipocabecera, setTipocabecera }) {

  const logeo = () => setTipocabecera(tipocabecera - 1)


    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [pais, setPais] = React.useState('');
    const handleChange = (event: SelectChangeEvent) => {
      setPais(event.target.value as string);}
  
    return (
      <div>
        <Button
        variant="contained"
        sx={{ fontWeight: 'bold' }} 
        onClick={handleOpen} >
        Registrarse
      </Button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            
        <Container maxWidth="md">
          <Typography id="modal-modal-title" variant="h4" component="h2">
              Registro 
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 3 }}>
              cree una cuenta:
            </Typography>

        <Grid container>
          <Grid item md={6}>
            <FormControl sx={{ mt: 6 }}>
              <InputLabel htmlFor="nombre">Nombres</InputLabel>
              <Input id="nombre" aria-describedby="my-helper-text" />
              <FormHelperText id="my-helper-text">Obligatorio</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item md={6}>
            <FormControl sx={{ mt: 6 }}>
              <InputLabel htmlFor="apellido">Apellidos</InputLabel>
              <Input id="apellido" aria-describedby="my-helper-text" />
              <FormHelperText id="my-helper-text">Obligatorio</FormHelperText>
            </FormControl>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item md={6}>
            <FormControl sx={{ mt: 6 }}>
              <InputLabel htmlFor="correo">Correo</InputLabel>
              <Input id="correo" aria-describedby="my-helper-text" />
              <FormHelperText id="my-helper-text">Obligatorio</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item md={6}>
            <FormControl sx={{ mt: 6 }}>
              <InputLabel htmlFor="pais">Teléfono</InputLabel>
              <Input  type="number" id="pais" aria-describedby="my-helper-text" />
              <FormHelperText id="my-helper-text">Obligatorio</FormHelperText>
            </FormControl>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item md={6}>
            <FormControl sx={{ mt: 6 }}>
              <InputLabel htmlFor="contra">Cree Contraseña</InputLabel>
              <Input id="contra" aria-describedby="my-helper-text" />
              <FormHelperText id="my-helper-text">Obligatorio</FormHelperText>
            </FormControl>
          </Grid>
          

          <Grid item md={6}>
            <FormControl sx={{ mt: 6 }}>
              <InputLabel htmlFor="contra2">Repita Contraseña</InputLabel>
              <Input id="contra2" aria-describedby="my-helper-text" />
              <FormHelperText id="my-helper-text">Obligatorio</FormHelperText>
            </FormControl>
          </Grid>
          </Grid>

          <Grid container>
          <Grid item md={6} sx={{ mt: 6 }}>
          <FormControl fullWidth >
          <InputLabel id="demo-simple-select-label">País</InputLabel>
          <Select
            value={pais}
            label="Age"
            onChange={handleChange}>
            <MenuItem value={"Peru"}>Perú</MenuItem>
            <MenuItem value={"Peru"}>Perú</MenuItem>
            <MenuItem value={"Peru"}>Perú</MenuItem>
          </Select>
          </FormControl>
          </Grid>
          </Grid>

          <Button variant="contained" color="primary" sx={{ mt: 12 }}>
          Ingresar
          </Button>
        

        </Container>
          </Box>
        </Modal>
      </div>
    );
  }

  export {LoginModal, RegistroModal, NombreUsuario}; 
import React from 'react';
import {dataformat} from "../../BaseDatos.js";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import grafico from './Gobernanza20.jpg';


var datos = dataformat[0]

if (typeof localStorage?.getItem('DatoGobernanza') === "string") {
  const datosTemp = JSON.parse(localStorage.getItem('DatoGobernanza'));
    if (datosTemp.length > 1){
    datos = datosTemp[19]
}
  } 

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);


export const options = {
  animation: {
         duration: 500,
  },
  maintainAspectRatio: false,
    plugins: {
      datalabels: {
        display: true,
        color: 'white',
        anchor: 'center',
        align: 'start',
        offset: -5,
        formatter: function(value, index, values) {
          if(value >0 ){
            value = value.toString();
            value = value.split(/(?=(?:...)*$)/);
            value = value.join(',');
            return value+'%';
        }else{
            value = "";
            return value;
        }
        },
        font: {
          size: 10,
        }
     },
      title: {
        display: false,
        text: 'Propiedad de la Mipyme',
      },
      legend: {
        position: 'bottom',
        labels: {
          boxWidth: 5,
          boxHeight: 5,
          padding: 1,
          font: {
            size: 8
        }
        }
    }
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
        ticks: {
          font: {
            size: 8,
          }
        },
      },
      y: {
      stacked: true,
      display: false
    },
  },
};


function sumarDesconocido(PGraficoMic, PGraficoPeq, PGraficoMed1, PGraficoMed2, 
  PGraficoNueva, PGraficoConsolidacion,PGraficoConsolidada,
  PGraficoAgro,PGraficoIndus,PGraficoServi,PGraficoComer,PGraficoConstruc,
  PGraficoHombre,PGraficoMujer,
  PGraficoSiPertenece,PGraficoNoPertenece){

    var Tamaño = []
    var Años = []
    var Sector = []
    var Genero = []
    var Pertenencia = []  

  for (let i = 0; i < PGraficoMic.length; i++) {

  var sumaTamaño = 100 - (PGraficoMic[i] + PGraficoPeq[i] + PGraficoMed1[i] + PGraficoMed2[i])
  var sumaAños = 100 - (PGraficoNueva[i] + PGraficoConsolidacion[i] + PGraficoConsolidada[i])
  var sumaSector = 100 - (PGraficoAgro[i] + PGraficoIndus[i] + PGraficoServi[i] + PGraficoComer[i] + PGraficoConstruc[i])
  var sumaGenero = 100 - (PGraficoHombre[i] + PGraficoMujer[i])
  var sumaPertenencia = 100 - (PGraficoSiPertenece[i] + PGraficoNoPertenece[i])

  // SI UNO SALE NEGATIVO POR ALGUN ERROR PONER POSITIVO POR EL MOMENTO PARA QUE NO SE CAIGA
 

  if (sumaTamaño !== 100) {
  if (sumaTamaño >= 0) {
    Tamaño.push(sumaTamaño)
  } else {
    Tamaño.push(sumaTamaño*-1)
  }
} else {
  Tamaño.push(0)
}
    
if (sumaAños !== 100) {
  if (sumaAños >= 0) {
    Años.push(sumaAños)
  } else {
    Años.push(sumaAños*-1)
  }
} else {
  Años.push(0)
}

if (sumaSector !== 100) {
  if (sumaSector >= 0) {
    Sector.push(sumaSector)
  } else {
    Sector.push(sumaSector*-1)
  }
} else {
  Sector.push(0)
}

if (sumaGenero !== 100) {
  if (sumaGenero >= 0) {
    Genero.push(sumaGenero)
  } else {
    Genero.push(sumaGenero*-1)
  }
} else {
  Genero.push(0)
}

if (sumaPertenencia !== 100) {
  if (sumaPertenencia >= 0) {
    Pertenencia.push(sumaPertenencia)
  } else {
    Pertenencia.push(sumaPertenencia*-1)
  }
} else {
  Pertenencia.push(0)
}

  }

  var indefinidos = []
  indefinidos.push(Tamaño)
  indefinidos.push(Años)
  indefinidos.push(Sector)
  indefinidos.push(Genero)
  indefinidos.push(Pertenencia)
  return indefinidos

}
  



  
  function BarrasAgrupadas20({segregacion, arrayG =dataformat[0]}) {
    var prom =arrayG.PGraficoPorcentajeRespuesta;
    const labels = [['Sí', prom[0]+'%'],['No', prom[1]+'%']]
    
    var indefinidos = sumarDesconocido(
  arrayG.PGraficoMic, arrayG.PGraficoPeq, arrayG.PGraficoMed1, arrayG.PGraficoMed2, 
  arrayG.PGraficoNueva, arrayG.PGraficoConsolidacion, arrayG.PGraficoConsolidada,
  arrayG.PGraficoAgro, arrayG.PGraficoIndus, arrayG.PGraficoServi, arrayG.PGraficoComer, arrayG.PGraficoConstruc,
  arrayG.PGraficoHombre, arrayG.PGraficoMujer,
  arrayG.PGraficoSiPertenece, arrayG.PGraficoNoPertenece)

  var colorIndefinido = "rgb(118, 118, 118)"

    var azul  = "rgb(103, 218, 213)"
    var azul1 = "rgb(91, 155, 213)"
    var azul2 = "rgb(76, 90, 184)"
    var azul3 = "rgb(49, 85, 208)"
    var azul4 = "rgb(19, 16, 117)"
  
    const data = {
      labels,
      datasets: [
        {
          label: 'Micro',
          data: arrayG.PGraficoMic,
          backgroundColor: azul,
        },
        {
          label: 'Pequeña',
          data:  arrayG.PGraficoPeq,
          backgroundColor:  azul1,
        },
        {
          label: 'Mediana-1',
          data: arrayG.PGraficoMed1,
          backgroundColor: azul2,
        },
        {
          label: 'Mediana-2',
          data: arrayG.PGraficoMed2,
          backgroundColor: azul3,
        },
    /*  {
        label: 'No_precisa',
        data: indefinidos[0],
        backgroundColor: colorIndefinido,
      }, */
      ],
    };
    
     const data1 = {
      labels,
      datasets: [
        {
          label: 'Nuevas',
          data:  arrayG.PGraficoNueva,
          backgroundColor: azul,
        },
        {
          label: 'En Consolidación',
          data: arrayG.PGraficoConsolidacion,
          backgroundColor: azul2,
        },
        {
          label: 'Consolidadas',
          data: arrayG.PGraficoConsolidada,
          backgroundColor: azul3,
        },
    /*  {
        label: 'No_precisa',
        data: indefinidos[0],
        backgroundColor: colorIndefinido,
      }, */
      
      ],
    };
    
     const data2 = {
      labels,
      datasets: [
        {
          label: 'Agropecuario',
          data: arrayG.PGraficoAgro,
          backgroundColor: azul,
        },
        {
          label: 'Industrial',
          data: arrayG.PGraficoIndus,
          backgroundColor: azul1,
        },
        {
          label: 'Servicio',
          data: arrayG.PGraficoServi,
          backgroundColor: azul2,
        },
        {
          label: 'Comercio',
          data: arrayG.PGraficoComer,
          backgroundColor: azul3,
        },
        {
          label: 'Construcción',
          data: arrayG.PGraficoConstruc,
          backgroundColor: azul4,
        },
    /*  {
        label: 'No_precisa',
        data: indefinidos[0],
        backgroundColor: colorIndefinido,
      }, */
      ],
    };
    
     const data3 = {
      labels,
      datasets: [
        {
          label: 'Hombre',
          data: arrayG.PGraficoHombre,
          backgroundColor: azul,
        },
        {
          label: 'Mujer',
          data: arrayG.PGraficoMujer,
          backgroundColor: azul1,
        },
    /*  {
        label: 'No_precisa',
        data: indefinidos[0],
        backgroundColor: colorIndefinido,
      }, */
      ],
    };
  
     const data4 = {
      labels,
      datasets: [
        {
          label: 'Sí',
          data: arrayG.PGraficoSiPertenece,
          backgroundColor: azul,
        },
        {
          label: 'No',
          data: arrayG.PGraficoNoPertenece,
          backgroundColor: azul1,
        },
    /*  {
        label: 'No_precisa',
        data: indefinidos[0],
        backgroundColor: colorIndefinido,
      }, */
    
      ],
    };

 return( 
    <div>
    {segregacion === 0 &&
    <div>
    <img  alt="imagen" className='imagengraficos abajo'src={grafico}></img>
    <div className='titulograficos'>
    <div className='numpregraficos'>P.58</div>
    <div className='colornegro'>Promueve Cumplim. Normas/Reglam. Est.</div>
   <div>{arrayG.Noporcentaje}%
    <div>Sí</div>
    </div>
    </div>
    <div className='subtitulograficos'>
     <ul className='Gob'>
      <li>Mic. = {arrayG.MicPorcentaje}% </li>
      <li>Peq. = {arrayG.PeqPorcentaje}%</li>
      <li>Med1. = {arrayG.Med1Porcentaje}%</li>
      <li>Med2. = {arrayG.Med2Porcentaje}%</li>
    </ul>
  </div>
    <div className="socialfgrafic">
      
     <Bar data={data} plugins={[ChartDataLabels]} options={options}/>
    </div>
    </div>
    }
    {segregacion === 1 &&
    <div>
    <img  alt="imagen" className='imagengraficos abajo'src={grafico}></img>
    <div className='titulograficos'>
    <div className='numpregraficos'>P.58</div>
    <div className='colornegro'>Promueve Cumplim. Normas/Reglam. Est.</div>
   <div>{arrayG.Noporcentaje}%
    <div>Sí</div>
    </div>
    </div>
    <div className='subtitulograficos'>
     <ul className='Gob'>
      <li>Nueva = {arrayG.NuevaPorcentaje}%</li>
      <li>En Consolidación= {arrayG.ConsolidacionPorcentaje}%</li>
      <li>Consolidada = {arrayG.ConsolidadasPorcentaje}%</li>
    </ul>
  </div>
    <div className="socialfgrafic">
      
     <Bar data={data1} plugins={[ChartDataLabels]} options={options}/>
    </div>
    </div>
    }
    {segregacion === 2 &&
    <div>
    <img  alt="imagen" className='imagengraficos abajo'src={grafico}></img>
    <div className='titulograficos'>
    <div className='numpregraficos'>P.58</div>
    <div className='colornegro'>Promueve Cumplim. Normas/Reglam. Est.</div>
   <div>{arrayG.Noporcentaje}%
    <div>Sí</div>
    </div>
    </div>
    <div className='subtitulograficos'>
     <ul className='Gob'>
    <li>Agr. = {arrayG.AgropecuarioPorcentaje}%</li>
      <li>Ind. = {arrayG.IndustriaPorcentaje}%</li>
      <li>Ser. = {arrayG.ServiciosPorcentaje}%</li>
      <li>Com. = {arrayG.ComercioPorcentaje}%</li>
      <li>Con. = {arrayG.ConstrucciónPorcentaje}%</li>
    </ul>
  </div>
    <div className="socialfgrafic">
      
     <Bar data={data2} plugins={[ChartDataLabels]} options={options}/>
    </div>
    </div>
    }
    {segregacion === 3 &&
    <div>
    <img  alt="imagen" className='imagengraficos abajo'src={grafico}></img>
    <div className='titulograficos'>
    <div className='numpregraficos'>P.58</div>
    <div className='colornegro'>Promueve Cumplim. Normas/Reglam. Est.</div>
   <div>{arrayG.Noporcentaje}%
    <div>Sí</div>
    </div>
    </div>
    <div className='subtitulograficos'>
     <ul className='Gob'>
      <li>H. = {arrayG.MasculinoPorcentaje}%</li>
      <li>M. = {arrayG.FemeninoPorcentaje}% </li>
    </ul>
  </div>
    <div className="socialfgrafic">
      
     <Bar data={data3} plugins={[ChartDataLabels]} options={options}/>
    </div>
    </div>
    }
        {segregacion === 4 &&
    <div>
    <img  alt="imagen" className='imagengraficos'src={grafico}></img>
    <div className='titulograficos'>
    <div className='numpregraficos'>P.58</div>
    <div className='colornegro'>Promueve Cumplim. Normas/Reglam. Est.</div>
   <div>{arrayG.Noporcentaje}%
    <div>Sí</div>
    </div>
    </div>
    <div className='subtitulograficos'>
     <ul className='Gob'>
      <li>Sí. = {arrayG.PertenecePorcentaje}%  </li>
      <li>No. = {arrayG.NoPertenecePorcentaje}% </li>
    </ul>
  </div>
    <div className="socialfgrafic">
      
     <Bar data={data4} plugins={[ChartDataLabels]} options={options}/>
    </div>
    </div>
    }
    </div>
  );
  }

  export default BarrasAgrupadas20